import styled from '@emotion/styled';
// @mui
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// @ld
import { colors } from '@ld/utils';

export const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)`
  && {
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: ${colors.grey4};

    .MuiToggleButtonGroup-grouped {
      height: 40px;
      border-radius: 10px;
      text-transform: none;
      border: 1px solid ${colors.grey4};
    }

    .Mui-selected {
      background: #ffffff;

      &:hover {
        background: #ffffff;
      }
    }
  }
`;
