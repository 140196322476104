import { colors } from '@ld/utils';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MuiContainer from '@mui/material/Container';

import shapes from 'assets/shapes-bg.png';

export const Container = styled(MuiContainer)`
  && {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;

export const AppMain = styled.main`
  margin: 0;
  min-height: 100vh;
  padding: 100px 15px 20px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 40px 40px 40px 280px;
  }
`;

type MainProps = {
  $isCentered?: boolean;
};

export const Main = styled.main<MainProps>`
  flex: 1;
  width: 100%;
  position: relative;
  ${(props) =>
    props.$isCentered &&
    css`
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    `}
`;

export const MainWithAppBar = styled.main`
  flex: 1;
  position: relative;
  padding-top: 140px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 40px;
  }
`;

export const ShapesBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: url(${shapes});
  background-size: contain;
`;

export const AuthContainer = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.grey5};

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 40px 0px;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  width: 380px;
  max-width: 90%;
  /* padding: 30px; */
  z-index: 2;
  margin-top: 16px;

  ${(props) => props.theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

export const LinkCard = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  width: 250px;
  align-items: center;
  border: 1px solid ${colors.grey5};
  margin-bottom: 100px;
  z-index: 2;
`;

export const PoweredByLink = styled.a`
  &,
  &:link,
  &:hover,
  &:active,
  &:visited {
    position: relative;
    background: #ffffff;
    display: flex;
    padding: 5px;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    color: ${colors.black1};
    text-decoration: none;
  }
`;
