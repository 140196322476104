import { TNullableString } from './base';
import { IEventAtRead, IEventAtWrite } from './event';

export enum EProfileType {
  TRUST = 'trust',
  PERSONAL = 'personal',
  BUSINESS = 'business'
}

export enum EProfileRole {
  NONE = 'none',
  OWNER = 'owner',
  READER = 'reader',
  EDITOR = 'editor',
  WRITER = 'writer'
}

export type TProfileRole = Record<string, Omit<EProfileRole, EProfileRole.NONE>>;

export enum EProfileStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  NOT_INVITED = 'not-invited',
  INVITE_PENDING = 'invite-pending'
}

export interface IProfileWrite {
  name: string;
  email: TNullableString;
  photoURL: TNullableString;
  type: EProfileType;
  countryCode: string;
  roles: TProfileRole;
  isVaultSetup: boolean;
  status: EProfileStatus;
  created: IEventAtWrite;
  updated: IEventAtWrite;
  estateReadinessScore: number;
  estateReadinessGapCount: number;
  billingUserId: TNullableString;
  paymentMethodId: TNullableString;
  billingOrganisationId: TNullableString;
  suspendedReason: TNullableString;
  estateReadinessGap?: Record<string, unknown> | null;
  promoCode: TNullableString;
}
export interface IProfileRead extends IProfileWrite {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
}
