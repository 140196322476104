import { createContext } from 'react';
import { IUserRead } from '@ld/utils';

const UserContext = createContext<{
  user: IUserRead | null;
  updateUser: (data: Partial<IUserRead>) => void;
  setUser: (data: IUserRead | null) => void;
}>({ user: null, updateUser: () => null, setUser: () => null });

UserContext.displayName = 'UserContext';

export default UserContext;
