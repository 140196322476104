import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function CloudCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M23.4379 14.0705C22.917 8.82389 19.2777 5.63281 15.0003 5.63281C10.9572 5.63281 8.35327 8.45708 7.50012 11.2579C3.98443 11.6095 0.9375 13.8121 0.9375 17.8205C0.9375 21.6878 4.10162 24.3832 7.96888 24.3832H23.2035C26.4263 24.3832 29.063 22.7753 29.063 19.2268C29.063 15.7217 25.9575 14.2017 23.4379 14.0705Z'
        strokeLinejoin='round'
      />
      <path
        d='M18.5744 12.1953L12.2579 19.6955L9.55078 16.6954'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
CloudCheckedIcon.defaultProps = {
  viewBox: '0 0 30 30'
};
export default React.memo(CloudCheckedIcon);
