import { query, Timestamp, where } from 'firebase/firestore/lite';
// @ld
import { IUserDocumentRead, categories } from '@ld/utils';
// @custom
import { getCollectionRef } from './utils/refs';
import { itemQueryKey } from './utils/use-get-item';
import { itemsQueryFn } from './utils/use-get-items';

export const DOCUMENTS = 'documents';

export const documentQueryKey = (documentId?: string) =>
  itemQueryKey(DOCUMENTS, documentId);

type DocumentsQueryParams = {
  typeId?: string;
  entityId?: string;
  profileId?: string;
  categoryId?: string;
};

export const documentsQueryKey = ({
  typeId,
  entityId,
  profileId,
  categoryId
}: DocumentsQueryParams) => {
  const queryKey = ['profiles', profileId];

  if (typeId) {
    queryKey.push('type', typeId);
  }
  if (entityId) {
    queryKey.push('entity', entityId);
  }
  if (categoryId) {
    queryKey.push('category', categoryId);
  }

  queryKey.push(DOCUMENTS);

  return queryKey;
};

export const documentsQuery = ({
  typeId,
  entityId,
  profileId,
  categoryId
}: DocumentsQueryParams) => {
  const constraints = [where('profileId', '==', profileId)];

  if (typeId) {
    constraints.push(where('typeId', '==', typeId));
  }
  if (entityId) {
    constraints.push(where('entityId', '==', entityId));
  }
  if (categoryId) {
    constraints.push(where('categoryId', '==', categoryId));
  }

  return query(getCollectionRef(DOCUMENTS), ...constraints);
};

export const documentsQueryFn = (queryParams: DocumentsQueryParams) =>
  itemsQueryFn<IUserDocumentRead>(documentsQuery(queryParams));

export const expiringDocumentsQueryKey = (profileId?: string) => [
  'profiles',
  profileId,
  'expiring-documents'
];

export const expiringDocumentsQuery = (profileId?: string) => {
  return query(
    getCollectionRef(DOCUMENTS),
    where('profileId', '==', profileId),
    where('expiresAt', '>', Timestamp.fromDate(new Date()))
  );
};

export const ficaDocumentsQueryKey = (profileId = '') => ['fica-documents', profileId];

export const ficaDocumentsQuery = (profileId = '') => {
  const { personalDocuments } = categories;
  const idDocumentTypeId = personalDocuments.idDocument.typeId;
  const proofOfResidenceTypeId = personalDocuments.proofOfResidence.typeId;
  return query(
    getCollectionRef(DOCUMENTS),
    where('profileId', '==', profileId),
    where('typeId', 'in', [idDocumentTypeId, proofOfResidenceTypeId])
  );
};
