import * as React from 'react';
// @local
import {
  TDocumentUpload,
  TDocumentUploadOptions,
  TDocumentUploadProcessing
} from './types';

type TDocumentUploadServiceContext = {
  isLoading: boolean;
  uploadProcessingData: TDocumentUploadProcessing[];
  handleUploadFile: (data: TDocumentUpload, options?: TDocumentUploadOptions) => void;
};

export const DocumentUploadServiceContext = React.createContext(
  {} as TDocumentUploadServiceContext
);

DocumentUploadServiceContext.displayName = 'DocumentUploadServiceContext';

export const useDocumentUploadService = () => {
  return React.useContext(DocumentUploadServiceContext);
};
