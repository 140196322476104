import styled from '@emotion/styled';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
// @ld
import { colors } from '@ld/utils';

export const Dialog = styled(MuiDialog)`
  .MuiDialog-container {
    backdrop-filter: blur(10px);
  }

  .MuiDialog-paper {
    background: #ffffff;
    box-shadow: 0px 15px 30px rgba(38, 50, 56, 0.1);
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down('xs')} {
      border-radius: 0px;
    }
  }
`;

export const DialogContent = styled(MuiDialogContent)`
  && {
    margin-left: 20px;
    padding: 30px 30px 30px 0px;
    border-top: 1px solid ${colors.grey4};
    border-bottom: 1px solid ${colors.grey4};
  }
`;
