import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import { EProfileRole } from '@ld/utils';

export enum EAbilities {
  SHARE = 'share',
  WRITE = 'write',
  READ = 'read',
  EDIT = 'edit'
}
export enum ESubjects {
  DOCUMENT = 'Document',
  CONTACT = 'Contact',
  ENTITY = 'Entity',
  SHARE = 'Share',
  CONFIG = 'CONFIG',
  ACCESS = 'ACCESS'
}

export type TAccessAbility = Ability<[EAbilities, ESubjects]>;
export const AccessAbility = Ability as AbilityClass<TAccessAbility>;

export default function defineRulesFor(
  role: Omit<EProfileRole, EProfileRole.NONE> | string
) {
  const { can, rules, cannot } = new AbilityBuilder(AccessAbility);

  if (role === EProfileRole.OWNER) {
    can(
      [EAbilities.WRITE, EAbilities.READ, EAbilities.EDIT, EAbilities.SHARE],
      [
        ESubjects.DOCUMENT,
        ESubjects.CONTACT,
        ESubjects.ENTITY,
        ESubjects.CONFIG,
        ESubjects.ACCESS, 
        ESubjects.SHARE,
      ]
    );
  } else if (role === EProfileRole.WRITER) {
    can(
      [EAbilities.WRITE, EAbilities.READ, EAbilities.EDIT],
      [ESubjects.ENTITY, ESubjects.DOCUMENT, ESubjects.CONFIG, ESubjects.CONTACT]
    );
    cannot(
      [EAbilities.WRITE, EAbilities.EDIT, EAbilities.READ],
      [ESubjects.ACCESS, ESubjects.SHARE]
    );
  } else if (role === EProfileRole.EDITOR) {
    can([EAbilities.READ, EAbilities.EDIT], [ESubjects.ENTITY, ESubjects.DOCUMENT]);
    cannot(
      [EAbilities.WRITE],
      [
        ESubjects.DOCUMENT,
        ESubjects.CONTACT,
        ESubjects.ENTITY,
        ESubjects.CONFIG,
        ESubjects.ACCESS, 
        ESubjects.SHARE,
      ]
    );
  } else if (role === EProfileRole.READER) {
    can(EAbilities.READ, [ESubjects.DOCUMENT, ESubjects.ENTITY, ESubjects.CONTACT]);
    cannot(
      [EAbilities.WRITE, EAbilities.EDIT],
      [
        ESubjects.DOCUMENT,
        ESubjects.CONTACT,
        ESubjects.ENTITY,
        ESubjects.CONFIG,
        ESubjects.ACCESS, 
        ESubjects.SHARE,
      ]
    );
  }

  return rules;
}

export function buildAbilityFor(
  role: Omit<EProfileRole, EProfileRole.NONE> | string
): TAccessAbility {
  return new AccessAbility(defineRulesFor(role), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // ts detects never on object, any set manually for testing. TODO: Find appropriate implementation.
    detectSubjectType: (object: any) => object!.type
  });
}
