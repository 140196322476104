import { Category } from './types';

// Documents
const certificate = {
  typeId: 'bf14fa87',
  name: 'Certificate'
};

const transcripts = {
  typeId: 'e09871f0',
  name: 'Transcripts',
  multipleDocuments: true
};

// Category
const qualifications: Category = {
  id: 'Pv290XuFWy64PY099nlp',
  name: 'Education',
  shortDescription: 'Qualifications, certificates, diplomas and transcripts',
  description:
    'Qualifications and education. Store all your transcripts and certificates here ',
  entity: {
    type: 'qualification',
    displayName: 'Qualification',
    pluralDisplayName: 'Qualifications',
    documents: [certificate, transcripts]
  }
};

export default qualifications;
