import { Category } from './types';

// Documents
const medicalDocuments = {
  typeId: 'd0a5f3fb',
  multipleOwners: true,
  multipleDocuments: true,
  name: 'Medical documents'
};

// Category
const medicalInformation: Category = {
  id: 'ZSzgG0Mq4bzFP7Tb7XF2',
  name: 'Medical',
  shortDescription:
    'Basic medical information such as medical cover, organ donor status etc.',
  description:
    'If you have any form of medical cover (medical aid, hospital plan, medical insurance) or immunisation/vaccination certificates store them here. For your medical cover, your executor would need this information to get the relevant tax certificates for your estate and assist with ensuring your dependents have continued cover after you pass on.',
  entity: {
    multipleOwners: true,
    type: 'medical_information',
    displayName: 'Medical information',
    pluralDisplayName: 'Medical information',
    documents: [medicalDocuments]
  }
};

export default medicalInformation;
