// two factor
export const VERIFIED_KEY = 'ldvk-nbdsvbh98y3489';
export const VERIFIED_VALUE = 'LD:bfdw86ffbfr7frgur4f8';

export const setTwoFactorVerified = () => {
  window.sessionStorage.setItem(VERIFIED_KEY, VERIFIED_VALUE);
};

export const getIsTwoFactorVerified = () => {
  return window.sessionStorage.getItem(VERIFIED_KEY) === VERIFIED_VALUE;
};

export const removeTwoFactorVerified = () => {
  window.sessionStorage.removeItem(VERIFIED_KEY);
};

// signin email notification
export const SIGNIN_NOTIFICATION_EMAIL_KEY = 'ldsne-f71633070ac4';
export const SIGNIN_NOTIFICATION_EMAIL_VALUE = 'LD:e81fac4b6f87d77a8376f44e';

export const setSigninEmailNotificationSentFlag = () => {
  window.sessionStorage.setItem(
    SIGNIN_NOTIFICATION_EMAIL_KEY,
    SIGNIN_NOTIFICATION_EMAIL_VALUE
  );
};

export const getWasSigninEmailNotificationSent = () => {
  return (
    window.sessionStorage.getItem(SIGNIN_NOTIFICATION_EMAIL_KEY) ===
    SIGNIN_NOTIFICATION_EMAIL_VALUE
  );
};

export const removeSigninEmailNotificationSentFlag = () => {
  window.sessionStorage.removeItem(SIGNIN_NOTIFICATION_EMAIL_KEY);
};

// alert banner
export const ALERT_BANNER_KEY = 'closed_alert_banner';

export const setClosedAlertBanner = () => {
  window.sessionStorage.setItem(ALERT_BANNER_KEY, 'true');
};

export const getWasAlertBannerClosed = () => {
  return window.sessionStorage.getItem(ALERT_BANNER_KEY) === 'true';
};

// profile
const PROFILE_KEY = 'profile';

export const setProfile = (profileId: string) => {
  window.sessionStorage.setItem(PROFILE_KEY, profileId);
};

export const getProfile = () => {
  return window.sessionStorage.getItem(PROFILE_KEY);
};

export const removeProfile = () => {
  window.sessionStorage.removeItem(PROFILE_KEY);
};

const FROM_APP = 'fromApp';

export function setFromApp(fromApp: string) {
  window.sessionStorage.setItem(FROM_APP, fromApp);
}

export function getFromApp() {
  return window.sessionStorage.getItem(FROM_APP);
}

export function removeFromApp() {
  window.sessionStorage.removeItem(FROM_APP);
}
