import * as React from 'react';
import { categories } from '@ld/utils';

// @custom
import {
  IdentificationCardIcon,
  AgreementIcon,
  CarIcon,
  DebtIcon,
  AccessIcon,
  AccountingIcon,
  EmploymentIcon,
  HeartbeatIcon,
  PawprintIcon,
  PropertyIcon,
  ReportIcon,
  ShieldIcon,
  WalletIcon,
  PieChartIcon,
  TrustIcon,
  DiplomaIcon,
  FirearmIcon,
  DependentsIcon,
  CollectiblesIcon,
  TaxesIcon
} from '../icons';
import { SvgIconProps } from '@mui/material';

const icons = {
  [categories.personal.id]: IdentificationCardIcon,
  [categories.insurancePolicies.id]: ShieldIcon,
  [categories.deathAndEstate.id]: AgreementIcon,
  [categories.properties.id]: PropertyIcon,
  [categories.financialAssets.id]: PieChartIcon,
  [categories.medical.id]: HeartbeatIcon,
  [categories.bankAccounts.id]: WalletIcon,
  [categories.accessAndDigitalPlatforms.id]: AccessIcon,
  [categories.employment.id]: EmploymentIcon,
  [categories.vehicles.id]: CarIcon,
  [categories.pets.id]: PawprintIcon,
  [categories.payableAccounts.id]: AccountingIcon,
  [categories.debtAndLiabilities.id]: DebtIcon,
  [categories.taxes.id]: TaxesIcon,
  [categories.businesses.id]: ReportIcon,
  [categories.trusts.id]: TrustIcon,
  [categories.qualifications.id]: DiplomaIcon,
  [categories.firearms.id]: FirearmIcon,
  [categories.dependents.id]: DependentsIcon,
  [categories.collectibles.id]: CollectiblesIcon
};

const CategoryIcon = ({ categoryId, ...rest }: { categoryId: string } & SvgIconProps) => {
  const Icon = icons[categoryId];
  if (Icon) {
    return <Icon {...rest} />;
  }
  return null;
};

export default React.memo(CategoryIcon);
