import * as React from 'react';
import { IoChevronBackOutline } from 'react-icons/io5';
// @mui
import { Box, Stack, Skeleton, IconButton } from '@mui/material';
// @ld
import { colors } from '@ld/utils';
// @local
import { UnstyledLink } from '../links';
import { Heading3, BodySmallText } from '../typography';

export type TPageBreadcrumbProps = {
  title?: string;
  backUrl: string;
  breadcrumb?: string;
};

const PageBreadcrumb = ({
  title,
  backUrl,
  breadcrumb = 'Vault'
}: TPageBreadcrumbProps) => {
  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      <UnstyledLink to={backUrl}>
        <IconButton
          id={`action-${title}`}
          sx={{
            width: 50,
            height: 50,
            background: 'white',
            borderRadius: '10px',
            color: colors.black1,
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.02)',
            '&:hover': { background: 'white' }
          }}
        >
          <IoChevronBackOutline />
        </IconButton>
      </UnstyledLink>
      <Box>
        <BodySmallText color={colors.grey1}>{breadcrumb}</BodySmallText>
        {title ? (
          <Heading3>{title}</Heading3>
        ) : (
          <Skeleton variant='text' width={150} height={24} />
        )}
      </Box>
    </Stack>
  );
};

export default React.memo(PageBreadcrumb);
