import styled from '@emotion/styled';
import LinearProgress from '@mui/material/LinearProgress';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
`;

const PageLoader = () => {
  return (
    <Wrapper>
      <LinearProgress variant='indeterminate' color='primary' />
    </Wrapper>
  );
};

export default PageLoader;
