import styled from '@emotion/styled';
import { ReactComponent as LifedocsLogo } from 'assets/ld-logo.svg';

type LogoProps = {
  height?: number;
};

const Logo = styled(LifedocsLogo)<LogoProps>`
  && {
    height: ${(props) => props.height || 32}px;
    width: auto;
  }
`;

export default Logo;
