import * as React from 'react';

const ScrollIntoView = ({ children }: { children: React.ReactNode }) => {
  const elemRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (elemRef.current) {
      elemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }, []);
  return <div ref={elemRef}>{children}</div>;
};

export default ScrollIntoView;
