import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// @ld
import { Card, CardContent } from '../../card';

const DocumentCardSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Box display='flex' alignItems='center'>
          <Skeleton variant='circular' height={50} width={50} />
          <Box ml={1}>
            <Skeleton variant='text' height={20} width={180} />
            <Skeleton variant='text' height={16} width={110} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default React.memo(DocumentCardSkeleton);
