import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function UmbrellaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M15.0001 15.9375V25.3127C15.0001 25.81 14.8025 26.2869 14.4509 26.6385C14.0992 26.9902 13.6223 27.1877 13.125 27.1877C12.6277 27.1877 12.1508 26.9902 11.7992 26.6385C11.4475 26.2869 11.25 25.81 11.25 25.3127' />
      <path d='M27.188 15.9377C27.188 9.20635 21.7316 3.75 15.0002 3.75C8.26885 3.75 2.8125 9.20635 2.8125 15.9377C3.18184 15.5684 3.62032 15.2754 4.1029 15.0755C4.58548 14.8756 5.10271 14.7727 5.62505 14.7727C6.1474 14.7727 6.66463 14.8756 7.14721 15.0755C7.62979 15.2754 8.06827 15.5684 8.43761 15.9377C9.21831 15.1572 10.2543 14.6844 11.3555 14.6061C12.4567 14.5279 13.5492 14.8494 14.4324 15.5117L15.0002 15.9377L15.568 15.5117C16.4512 14.8494 17.5437 14.5279 18.6449 14.6061C19.7461 14.6844 20.7822 15.1572 21.5629 15.9377V15.9377C21.9322 15.5684 22.3707 15.2754 22.8533 15.0755C23.3358 14.8756 23.8531 14.7727 24.3754 14.7727C24.8978 14.7727 25.415 14.8756 25.8976 15.0755C26.3801 15.2754 26.8186 15.5684 27.188 15.9377V15.9377Z' />
      <path d='M15 3.75002V2.8125' />
    </SvgIcon>
  );
}
UmbrellaIcon.defaultProps = {
  viewBox: '0 0 30 30'
};
export default React.memo(UmbrellaIcon);
