import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { colors } from '@ld/utils';

// TODO: improve by setting this via the theme

const fontStyles = {
  fontWeight: 600,
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '22px'
};

const buttonBase = {
  height: 50,
  padding: '13px 25px',
  borderRadius: '10px',
  boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.02)'
};

export const defaultButtonSx = {
  ...fontStyles,
  ...buttonBase,
  background: 'white',
  padding: '13px 25px',
  borderRadius: '10px',
  color: colors.black1,
  boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.02)',
  '&:hover': { background: 'white', color: colors.black1 }
};

export const primaryAltButtonSx = {
  ...fontStyles,
  ...buttonBase,
  padding: '13px 25px',
  borderRadius: '10px',
  color: colors.orange1,
  background: colors.orange2,
  boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.02)',
  '&:hover': { background: colors.orange2, color: colors.orange1 },
  '&:disabled': { background: colors.orange2, color: colors.orange1, opacity: 0.5 }
};

export const secondaryAltButtonSx = {
  ...fontStyles,
  ...buttonBase,
  color: colors.green1,
  background: colors.green3,
  '&:hover': { background: colors.green3, color: colors.green1 },
  '&:disabled': { background: colors.green3, color: colors.green1, opacity: 0.5 }
};

export const defaultIconButtonSx = {
  width: 50,
  height: 50,
  background: 'white',
  borderRadius: '10px',
  color: colors.black1,
  boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.02)',
  '&:hover': { background: 'white' }
};

export const StyledButton = styled(Button)`
  color: ${colors.green1};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  svg {
    color: ${colors.green1};
  }
`;
