// @mui
import { Stack, Box } from '@mui/material';
// @ld
import { colors, snakeToNormalCase, IContactRead } from '@ld/utils';
// @local
import Avatar from '../avatar';
import { Card } from '../card';
import ContactCardSkeleton from './skeleton';
import { BodyLargeText, SubText } from '../typography';

const ContactCard = ({ contact }: { contact: IContactRead }) => (
  <Stack p='20px' direction='row' component={Card} alignItems='center' spacing={2}>
    <Avatar
      alt={contact?.name || ''}
      src={contact?.photoURL || ''}
      sx={{ width: '60px', height: '60px', fontSize: '24px' }}
    />
    <Box width={{ xs: '50%', md: '40%', lg: '60%' }}>
      <BodyLargeText>{contact?.name}</BodyLargeText>
      <SubText noWrap color={colors.grey1}>
        {contact?.relationships
          ?.map((relationship: string) => snakeToNormalCase(relationship))
          .join(', ')}
      </SubText>
    </Box>
  </Stack>
);

export default ContactCard;

export { ContactCardSkeleton };
