import * as React from 'react';
// @ld
import { IUserDocumentRead, IUserDocumentWrite, TUploadFileSuccess } from '@ld/utils';
// @custom
import Queue from 'utils/queue';
import { DOCUMENTS } from 'api/documents';
import useCreateItem from 'api/utils/use-create-item';
// @local
import {
  TDocumentUpload,
  TDocumentUploadOptions,
  TDocumentUploadProcessing
} from './types';
import UploadSnackbar from './upload-snackbar';
import { DocumentUploadServiceContext } from './context';

const queue = new Queue<TDocumentUploadProcessing>();

type TAction = {
  type: 'add' | 'delete';
  payload: TDocumentUploadProcessing;
};

const reducer = (
  processingData: TDocumentUploadProcessing[],
  { type, payload }: TAction
) => {
  switch (type) {
    case 'add':
      return [...processingData, payload];
    case 'delete':
      return processingData.filter(
        ({ data }) => data.file.name !== payload.data.file.name
      );
    default:
      throw new Error();
  }
};

const DocumentUploadServiceProvider = ({ children }: { children: React.ReactNode }) => {
  const [queueSize, setQueueSize] = React.useState<number>(0);
  const [uploadProcessingData, dispatch] = React.useReducer(reducer, []);
  const { handleCreate } = useCreateItem<IUserDocumentWrite, IUserDocumentRead>(
    DOCUMENTS
  );

  const isLoading = uploadProcessingData.length > 0;

  const handleUploadFile = (data: TDocumentUpload, options?: TDocumentUploadOptions) => {
    queue.enqueue({ data, options });
    setQueueSize(queue.size);
  };

  const handleFileUploadComplete = (
    processingData: TDocumentUploadProcessing,
    { fileName, downloadURL }: TUploadFileSuccess
  ) => {
    handleCreate(
      {
        fileName,
        url: downloadURL,
        ...processingData.data.documentData
      },
      {
        onSuccess: (doc) => {
          processingData.options?.onSuccess?.(doc);
          dispatch({ type: 'delete', payload: processingData });
        },
        onError: (error) => {
          processingData.options?.onError?.(error as Error);
        }
      }
    );
  };

  React.useEffect(() => {
    if (uploadProcessingData.length < 3 && queueSize > 0) {
      const processingData = queue.dequeue();
      setQueueSize(queue.size);
      if (processingData) {
        dispatch({ type: 'add', payload: processingData });
      }
    }
  }, [uploadProcessingData.length, queueSize]);

  return (
    <DocumentUploadServiceContext.Provider
      value={{ isLoading, uploadProcessingData, handleUploadFile }}
    >
      {children}
      <UploadSnackbar
        open={isLoading}
        nextUploadProcessingData={queue.toArray()}
        uploadProcessingData={uploadProcessingData}
        onFileUploadComplete={handleFileUploadComplete}
      />
    </DocumentUploadServiceContext.Provider>
  );
};

export default DocumentUploadServiceProvider;
