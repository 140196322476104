import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function ArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M27.5571 15.8881L2.52892 26.2102C2.45938 26.2429 2.38275 26.2576 2.30606 26.253C2.22937 26.2483 2.15507 26.2245 2.08998 26.1837C2.02489 26.1428 1.9711 26.0863 1.93354 26.0193C1.89598 25.9523 1.87585 25.8769 1.875 25.8001V19.1607C1.875 19.0515 1.91311 18.9457 1.98275 18.8617C2.05239 18.7776 2.14919 18.7204 2.25645 18.7001L16.7657 15.9462C17.7829 15.7534 17.7829 14.2967 16.7657 14.1039L2.25587 11.35C2.14881 11.3295 2.05222 11.2724 1.98271 11.1885C1.9132 11.1045 1.87511 10.999 1.875 10.89V4.25355C1.875 3.91722 2.21954 3.65237 2.52892 3.78479L27.5571 14.166C27.7254 14.2385 27.8687 14.3587 27.9694 14.5117C28.0701 14.6647 28.1238 14.8439 28.1238 15.0271C28.1238 15.2103 28.0701 15.3895 27.9694 15.5425C27.8687 15.6955 27.7254 15.8157 27.5571 15.8881Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
ArrowIcon.defaultProps = {
  viewBox: '0 0 30 30'
};
export default React.memo(ArrowIcon);
