import MuiAlert, { AlertColor } from '@mui/material/Alert';
import {
  IoWarningOutline,
  IoInformationCircleOutline,
  IoCheckmarkDoneCircleOutline
} from 'react-icons/io5';
// @ld
import { colors } from '@ld/utils';
// @local
import { SubText } from '../typography';

const AlertIcon = ({ severity }: { severity: AlertColor }) => {
  switch (severity) {
    case 'error':
      return <IoWarningOutline />;
    case 'warning':
      return <IoWarningOutline />;
    case 'info':
      return <IoInformationCircleOutline />;
    case 'success':
      return <IoCheckmarkDoneCircleOutline />;
    default:
      return null;
  }
};

const Alert = ({
  title,
  message,
  severity
}: {
  title?: string;
  message: string;
  severity: AlertColor;
}) => {
  return (
    <MuiAlert
      variant='filled'
      severity={severity}
      icon={<AlertIcon severity={severity} />}
      sx={{
        background: colors.grey5,
        border: `1px solid ${colors.grey2}`,
        '.MuiAlert-icon': { color: colors.black1 }
      }}
    >
      {title && <SubText weight='bold'>{title}</SubText>}
      <SubText>{message}</SubText>
    </MuiAlert>
  );
};

export default Alert;
