import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
// @mui
import MuiLinearProgress from '@mui/material/LinearProgress';
// @ld
import { colors } from '@ld/utils';

const BaseFolderStyle = css`
  display: flex;
  padding: 15px;
  background: white;
  position: relative;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px 20px 20px 20px;
  font-family: 'Montserrat', sans-serif;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);

  min-height: 150px;

  &:before {
    content: '';
    width: 33%;
    height: 25px;
    border-radius: 20px 20px 0 0;
    background-color: #ffffff;
    position: absolute;
    top: -15px;
    left: 0px;
  }

  &:after {
    content: '';
    width: 33%;
    height: 25px;
    position: absolute;
    top: -15px;
    left: 10%;
    border-radius: 5px;
    background-color: #ffffff;
    transform: skew(45deg, 0deg);
  }
`;

export const SkeletonFolder = styled.div`
  ${BaseFolderStyle}

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 180px;
  }
`;

export const StyledFolder: any = styled(Link)`
  &,
  &:link,
  &:active {
    flex: 1;
    cursor: pointer;
    text-decoration: none;
    ${BaseFolderStyle}

    ${(props) => props.theme.breakpoints.up('sm')} {
      min-height: 180px;
    }
  }
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.1;
  color: ${colors.black1};
  margin: 8px 0px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin: 8px 0px 16px;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const LinearProgress = styled(MuiLinearProgress)`
  && {
    border-radius: 3px;
    background-color: ${colors.grey4};

    .MuiLinearProgress-root {
      border-radius: 3px;
    }

    .MuiLinearProgress-bar {
      border-radius: 3px;
      background: ${colors.orange1};
    }
  }
`;

export const IconWrapper = styled.div`
  z-index: 5;
  display: inline;
  width: fit-content;
  color: ${colors.black1};

  svg {
    width: 40px;
    height: 40px;
  }
`;

type TextProps = { noMargin?: boolean };

export const Text = styled.p<TextProps>`
  font-style: normal;
  font-size: 8px;
  font-weight: normal;
  color: ${colors.grey1};
  ${(props) =>
    !props.noMargin &&
    css`
      margin-bottom: 4px;
    `}

  span {
    font-style: normal;
    font-weight: 600;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 10px;
    line-height: 1.1;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 12px;
    line-height: 16px;
  }
`;
