const plans = [
  {
    name: 'individual',
    monthlyPricing: 'R39',
    annualPricing: 'R348',
    amountSavedMonthly: 'R29',
    amountSavedYearly: 'R120',
    monthlyPlanCode: 'PLN_8mwaldo34jlj7ab',
    annualPlanCode: 'PLN_czda6wozteefd30',
    users: '1 User',
    features: [
      { name: 'Lifedocs secure vault' },
      { name: 'Phone and email support (9am-5pm)' },
      { name: '2 Factor Authentication' },
      {
        name: 'Bank-level security with AES 256-bit encryption',
        included: true
      },
      { name: '5GB vault storage space' }
    ]
  },
  {
    name: 'duo',
    monthlyPricing: 'R59',
    annualPricing: 'R588',
    amountSavedMonthly: 'R49',
    amountSavedYearly: 'R120',
    monthlyPlanCode: 'PLN_mkx1943uvnqou8d',
    annualPlanCode: 'PLN_8uecuz1hx1l7xk1',
    users: '2 Family users',
    features: [
      {
        name: 'Collaborate and keep each other’s documents up to date',
        included: true
      },
      { name: 'Lifedocs secure vault' },
      { name: 'Phone and email support (9am-5pm)' },
      { name: '2 Factor Authentication' },
      {
        name: 'Bank-level security with AES 256-bit encryption',
        included: true
      },
      { name: '10GB vault storage space' }
    ]
  },
  {
    name: 'family',
    monthlyPricing: 'R149',
    annualPricing: 'R1188',
    amountSavedMonthly: 'R99',
    amountSavedYearly: 'R600',
    monthlyPlanCode: 'PLN_xc3ge4pfc5a0twl',
    annualPlanCode: 'PLN_ji63uw844wb6r5j',
    users: '6 Family users',
    features: [
      {
        name: 'Collaborate and keep each other’s documents up to date',
        included: true
      },
      { name: 'Lifedocs secure vault' },
      { name: 'Phone and email support (9am-5pm)' },
      { name: '2 Factor Authentication' },
      {
        name: 'Bank-level security with AES 256-bit encryption',
        included: true
      },
      { name: '30GB vault storage space' }
    ]
  }
];
export default plans;
