import * as React from 'react';

// @custom
import VehicleForm from './vehicle';
import BankAccountDetails from './bank-account';
import PropertyDetails from './property';
import AssetDetails from './asset';
import PayableAccountDetails from './payable-account';
import DebtAccountDetails from './debt-account';
import AccessDetails from './access';
import EmployerDetails from './employer';
import PetDetails from './pet';
import BusinessDetails from './business';
import TrustDetails from './trust';
import FirearmDetails from './firearm';
import QualificationDetails from './qualification';
import InsurancePolicyDetails from './insurance-policy';
import MedicalInformationDetails from './medical-information';
import DependentDetails from './dependent';
import CollectibleDetails from './collectible';
import TaxDetails from './taxes';
import { EntityDetailsProps } from './types';

const EntityDetails = (props: EntityDetailsProps) => {
  switch (props.entity.type) {
    case 'bank_account':
      return <BankAccountDetails {...props} />;
    case 'debt':
      return <DebtAccountDetails {...props} />;
    case 'taxes':
      return <TaxDetails {...props} />;
    case 'employer':
      return <EmployerDetails {...props} />;
    case 'qualification':
      return <QualificationDetails {...props} />;
    case 'medical_information':
      return <MedicalInformationDetails {...props} />;
    case 'insurance_policy':
      return <InsurancePolicyDetails {...props} />;
    case 'financial_asset':
      return <AssetDetails {...props} />;
    case 'payable_account':
      return <PayableAccountDetails {...props} />;
    case 'pet':
      return <PetDetails {...props} />;
    case 'property':
      return <PropertyDetails {...props} />;
    case 'collectible':
      return <CollectibleDetails {...props} />;
    case 'vehicle':
      return <VehicleForm {...props} />;
    case 'firearm':
      return <FirearmDetails {...props} />;
    case 'dependent':
      return <DependentDetails {...props} />;
    case 'access':
      return <AccessDetails {...props} />;
    case 'business':
      return <BusinessDetails {...props} />;
    case 'trust':
      return <TrustDetails {...props} />;
    default:
      return null;
  }
};

export default React.memo(EntityDetails);
