import * as React from 'react';
import { snakeToNormalCase } from '@ld/utils';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const AccessDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue
        value={
          entity.accessType === 'other'
            ? entity.otherAccessTypeName
            : snakeToNormalCase(entity.accessType)
        }
        label='Access type'
      />
      <FieldValue label='Platform / institution / item name' value={entity.accessName} />
      <FieldValue label='Access instructions or token' value={entity.hta} />
    </>
  );
};

export default React.memo(AccessDetails);
