import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function PieChartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M59.0905 33.2024H46.0892C45.6719 33.2024 45.3108 32.9152 45.214 32.5092C44.3019 28.6861 41.2799 25.6736 37.4516 24.775C37.0441 24.6793 36.7551 24.3176 36.7551 23.8991V10.9026C36.7551 10.3659 37.2214 9.95065 37.7551 10.0071C43.4341 10.609 48.6965 13.111 52.7923 17.2057C56.8765 21.291 59.3769 26.5383 59.9859 32.2011C60.0433 32.7353 59.6277 33.2024 59.0905 33.2024Z'
        fill='#EE6338'
      />
      <path
        opacity='0.05'
        d='M36.7551 23.8982V21.2227C43.0143 21.996 47.9786 26.9481 48.7712 33.2016H46.0893C45.672 33.2016 45.3109 32.9144 45.2141 32.5085C44.302 28.6853 41.28 25.6728 37.4517 24.7743C37.0441 24.6786 36.7551 24.3168 36.7551 23.8982Z'
        fill='#313842'
      />
      <path
        d='M59.995 37.579C59.4942 42.3765 57.6372 46.8779 54.6107 50.6306C54.2734 51.0486 53.6494 51.0853 53.2696 50.7055L45.9908 43.4267L44.0755 41.5114C43.7792 41.215 43.729 40.7549 43.9492 40.3982C44.5355 39.449 44.9737 38.3992 45.2318 37.281C45.3263 36.8714 45.6886 36.5801 46.1089 36.5801H59.0995C59.6358 36.58 60.0507 37.0456 59.995 37.579Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M48.7882 36.5791C48.4899 39.1401 47.4924 41.4873 45.9908 43.4258L44.0755 41.5105C43.7792 41.2142 43.729 40.754 43.9492 40.3974C44.5355 39.4481 44.9737 38.3983 45.2318 37.2801C45.3263 36.8705 45.6886 36.5792 46.1089 36.5792H48.7882V36.5791Z'
        fill='#313842'
      />
      <path
        d='M50.8853 53.0986C51.2646 53.4779 51.2288 54.1006 50.8121 54.4382C46.3669 58.04 40.8678 59.9991 35.0663 59.9991C28.3716 59.9991 22.0766 57.3908 17.3416 52.6571C12.6334 47.949 10.0291 41.6982 9.99975 35.0459C9.97036 28.3651 12.6176 21.9307 17.3417 17.2067C21.4368 13.1116 26.6999 10.6095 32.3797 10.008C32.9126 9.9517 33.3776 10.368 33.3776 10.9038V23.9001C33.3776 24.3194 33.0875 24.6808 32.6792 24.7767C28.0734 25.8587 24.634 30.0005 24.634 34.9325C24.634 40.6839 29.3137 45.3646 35.0661 45.3646C37.0887 45.3646 38.978 44.7854 40.5785 43.7852C40.9354 43.5622 41.398 43.6116 41.6956 43.9092L50.8853 53.0986Z'
        fill='#4BABB3'
      />
      <path
        opacity='0.05'
        d='M41.6955 43.9083L43.6147 45.8275C41.2582 47.6888 38.2795 48.8009 35.042 48.8016C27.3743 48.8033 21.2174 42.6649 21.1971 34.9973C21.1781 27.8984 26.5039 22.0389 33.3775 21.2168V23.8991C33.3775 24.3185 33.0874 24.6798 32.6792 24.7757C28.0733 25.8577 24.634 29.9995 24.634 34.9315C24.634 40.6829 29.3137 45.3636 35.0661 45.3636C37.0886 45.3636 38.978 44.7844 40.5785 43.7842C40.9353 43.5613 41.3978 43.6107 41.6955 43.9083Z'
        fill='#313842'
      />
    </SvgIcon>
  );
}

PieChartIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default React.memo(PieChartIcon);
