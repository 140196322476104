import * as React from 'react';
import Box from '@mui/material/Box';
import { IoLockOpenOutline, IoWarningOutline } from 'react-icons/io5';
// @ld
import { colors } from '@ld/utils';
// custom
import { BodySmallText } from '../typography';
// @local
import {
  Img,
  IFrame,
  LinearProgress,
  DocViewerContainer,
  ImageViewerContainer
} from './styles';

type DocumentViewerProps = {
  documentUrl?: string;
  alt?: string;
};

const DocumentViewer = ({ documentUrl, alt }: DocumentViewerProps) => {
  const [isLoading, setLoading] = React.useState<boolean>(true);

  if (documentUrl) {
    const isImage = /\.jpg|jpeg|png|tiff\?/.test(documentUrl);
    if (isImage) {
      return (
        <ImageViewerContainer>
          <Img src={documentUrl} alt={alt} />
        </ImageViewerContainer>
      );
    }
    return (
      <DocViewerContainer>
        {isLoading && (
          <>
            <IoLockOpenOutline color={colors.green1} size={30} />
            <Box py={1}>
              <BodySmallText color={colors.green1}>Decrypting...</BodySmallText>
            </Box>
            <LinearProgress variant='indeterminate' color='secondary' />
          </>
        )}
        <IFrame
          frameBorder='0'
          src={documentUrl}
          hidden={isLoading}
          id='document-viewer'
          title='document-viewer-iframe'
          onLoad={() => setLoading(false)}
        />
      </DocViewerContainer>
    );
  }

  return (
    <DocViewerContainer>
      <IoWarningOutline color={colors.orange1} size={30} />
      <BodySmallText color={colors.orange1}>
        We can&apos;t find this document
      </BodySmallText>
    </DocViewerContainer>
  );
};

export default React.memo(DocumentViewer);
