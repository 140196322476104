import { EProfileRole } from '../types/profiles';

const getProfileAccessPermissions = (role: EProfileRole) => {
  switch (role) {
    case EProfileRole.OWNER:
      return ['view', 'edit', 'create', 'delete', 'share'];
    case EProfileRole.WRITER:
      return ['view', 'edit', 'create', 'delete'];
    case EProfileRole.EDITOR:
      return ['view', 'edit'];
    case EProfileRole.READER:
      return ['view'];
    default:
      return [];
  }
};

export default getProfileAccessPermissions;
