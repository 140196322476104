import styled from '@emotion/styled';
// @ld
import { colors } from '@ld/utils';

type StylesProps = {
  warning?: boolean;
};

export const Styles = styled.div<StylesProps>`
  .PhoneInput {
    width: 100%;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    height: 50px;
    color: ${colors.black1};
    background-color: transparent;
    border: 1px solid
      ${(props) => (props.warning ? colors.orange1 : 'rgba(0, 0, 0, 0.24)')};

    &Country {
      margin: 0;
      padding: 10px 16px;
      background-color: transparent;
      border-right: none;
      border-radius: 8px 0px 0px 8px;

      &Icon {
        position: relative;

        &--border {
          box-shadow: none;
          background: transparent;
          width: 20px;
          height: 20px;
          overflow: hidden;
          border-radius: 50%;
        }

        svg {
          position: absolute;
          height: 20px;
        }
      }
    }

    &Input {
      outline: 0;
      border: none;
      padding: 12px;
      font-size: 15px;
      line-height: 19px;
      color: ${colors.black1};
      border-radius: 0px 8px 8px 0px;
      background-color: transparent;
      font-family: 'Montserrat', sans-serif;

      &::placeholder {
        font-family: 'Montserrat', sans-serif;
        color: ${colors.grey1};
      }
    }
  }
`;
