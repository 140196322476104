import React, { Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
// @ld
import { PageLoader } from '@ld/core';
// @custom
import useAuthRedirect from './use-auth-redirect';
export interface BlankLayoutRouteProps extends RouteProps {
  redirectIfNotOnboarded?: boolean;
  children?: React.ReactNode;
}

const BlankLayoutRoute = ({
  component: Component,
  redirectIfNotOnboarded,
  location,
  children,
  ...rest
}: BlankLayoutRouteProps) => {
  useAuthRedirect({ redirectIfNotOnboarded, location });

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<PageLoader />}>
          {Component ? <Component {...props} /> : children}
        </Suspense>
      )}
    />
  );
};

export default React.memo(BlankLayoutRoute);
