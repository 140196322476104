import { formatDate } from './format';

export const getEntityName = (entity: any) => {
  switch (entity?.type) {
    case 'debt':
    case 'bank_account':
      return entity?.accountName;
    case 'business':
    case 'employer':
      return entity?.companyName;
    case 'qualification':
      return entity?.qualificationName;
    case 'medical_information':
      return entity?.infoType === 'medical_cover'
        ? entity?.institutionName
        : entity?.immunisationName;
    case 'payable_account':
    case 'insurance_policy':
      return entity?.institutionName;
    case 'financial_asset':
      return entity?.assetName;
    case 'dependent':
      return entity?.dependentName;
    case 'collectible':
      return entity?.name;
    case 'pet':
      return entity?.petName;
    case 'property':
      return entity?.propertyAddress;
    case 'vehicle':
      return entity?.makeOrModel;
    case 'firearm':
      return entity?.firearmName;
    case 'access':
      return entity?.accessName;
    case 'trust':
      return entity?.trustName;
    case 'taxes': {
      const dateYear = formatDate(entity?.fiscalYear, 'yyyy');
      return dateYear;
    }
    default:
      return 'No such type';
  }
};

export const getEntityDocumentVaultConfigKey = (
  entityId: string,
  documentTypeId: string
) => `${entityId}_${documentTypeId}`;
