import * as React from 'react';

// @custom
import useUser from 'hooks/use-user';

const DelightedSurvey = () => {
  const { user } = useUser();

  React.useEffect(() => {
    if (user && user.isEmailVerified) {
      const delighted = (window as any).delighted;
      if (window && delighted) {
        delighted.survey({
          name: user.name,
          email: user.email,
          properties: {
            company: 'Lifedocs'
          }
        });
      }
    }
  }, [user]);

  return null;
};

export default DelightedSurvey;
