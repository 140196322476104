import styled from '@emotion/styled';
// @ld
import { colors } from '@ld/utils';

export const ListWrapper = styled.ul`
  padding: 10px;
  margin: 10px 0px;
  border-radius: 10px;
  background-color: ${colors.grey4};
  list-style: none;

  li {
    list-style: none;

    &:not(:last-item) {
      margin-bottom: 10px;
    }
  }
`;
