// @mui
import { ToggleButton, ToggleButtonGroupProps } from '@mui/material';
// @ld
import { toFormField } from '@ld/core';
// @custom
import { StyledToggleButtonGroup } from './styles';

type Option = { value: string; label: string };

type CustomToggleButtonGroupProps = {
  options: Option[];
} & ToggleButtonGroupProps;

export const ToggleButtonGroup = ({ options, ...rest }: CustomToggleButtonGroupProps) => {
  return (
    <StyledToggleButtonGroup color='secondary' {...rest}>
      {options.map((option) => {
        return (
          <ToggleButton key={`${option.value}-toggle-id`} value={option.value}>
            {option.label}
          </ToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
};

export default toFormField(ToggleButtonGroup);
