import React, { useEffect } from 'react';
// @custom
import Loader from '../loader';
import { BodyLargeText, BodySmallText } from '../typography';
// @local
import { Main, ErrorText } from './styles';

// Borrowed from https://mitchgavan.com/code-splitting-react-safely/

const failedToLoadChunk = (error: Error) => {
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  return Boolean(error?.message && chunkFailedMessage.test(error.message));
};

export function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

const ErrorFallback = ({ error }: { error: Error }) => {
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    if (failedToLoadChunk(error)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);

  if (failedToLoadChunk(error)) {
    return (
      <Main>
        <Loader />
        <BodySmallText align='center'>
          It&apos;s taking longer than expected - hang on.
        </BodySmallText>
      </Main>
    );
  }

  return (
    <Main>
      <BodyLargeText align='center'>Something went wrong.</BodyLargeText>
      <ErrorText>
        {error?.message ||
          "Try refreshing your page. If it doesn't work please log an error or contact support."}
      </ErrorText>
    </Main>
  );
};

export default React.memo(ErrorFallback);
