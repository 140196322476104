import * as React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

// @custom
import { clientPortalUrl } from 'config';

type SeoProps = {
  title: string;
  description?: string;
  image?: {
    url: string;
    type: string;
    height: string;
    width: string;
    alt: string;
  };
};

const Seo = ({
  title,
  description = 'Lifedocs offers you and your family convenience, security and peace of mind - no matter what the future holds',
  image = {
    url: `${clientPortalUrl}/cover.png`,
    alt: 'Lifedocs logo with circulating documents',
    type: 'image/png',
    height: '630',
    width: '1200'
  }
}: SeoProps) => {
  const location = useLocation();
  return (
    <Helmet defaultTitle='Lifedocs' titleTemplate='%s - Lifedocs'>
      {/* General tags */}
      <title>{title}</title>
      <meta name='description' content={description} />
      {/* OpenGraph tags */}
      <meta name='og:url' content={`${clientPortalUrl}${location.pathname}`} />

      <meta name='og:title' content={title} />
      <meta name='og:description' content={description} />
      <meta name='og:image' content={image.url} />
      <meta property='og:image:alt' content={image.alt} />
      <meta property='og:image:type' content={image.type} />
      <meta property='og:image:width' content={image.width} />
      <meta property='og:image:height' content={image.height} />
      <meta name='og:type' content='website' />
      {/* Twitter Card tags */}
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image.url} />
      <meta name='twitter:card' content='summary' />
    </Helmet>
  );
};

export default React.memo(Seo);
