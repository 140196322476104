import styled from '@emotion/styled';
// @ld
import { colors } from '@ld/utils';
// @local
import Avatar from '../../avatar';

export const SubContent = styled.div`
  color: ${colors.grey1};
  display: flex;
  align-items: center;
  margin-top: 2px;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

export const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 50%;
  background-color: ${colors.grey4};
  color: ${colors.grey1};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 26px;
    height: 26px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${colors.grey4};
    color: ${colors.grey1};
    width: 60px;
    height: 60px;
  }
`;
