import { Category } from './types';

// Documents
const policyDocument = {
  typeId: '4b493174',
  name: 'Policy documents',
  multipleDocuments: true
};

// Category
const insurancePolicies: Category = {
  id: 'UbOZzD5axd8mYMKAbB2n',
  name: 'Insurance',
  shortDescription: 'Your  long term insurance policies (Life cover, funeral cover, etc)',
  description:
    "It's super important to list any insurance policies you may have. This will allow the executor to quickly process and action those policies that payout on death or close other insurances where necessary.",
  entity: {
    multipleOwners: true,
    type: 'insurance_policy',
    displayName: 'Insurance policy',
    pluralDisplayName: 'Insurance policies',
    documents: [policyDocument]
  }
};

export default insurancePolicies;

export const insurancePoliciesDocuments = {
  policyDocument
};
