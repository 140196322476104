import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function UnreadMailIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M18.75 5.625H5.15625C4.53465 5.625 3.93851 5.87193 3.49897 6.31147C3.05943 6.75101 2.8125 7.34715 2.8125 7.96875V22.0312C2.8125 22.6529 3.05943 23.249 3.49897 23.6885C3.93851 24.1281 4.53465 24.375 5.15625 24.375H24.7693C25.3909 24.375 25.9871 24.1281 26.4266 23.6885C26.8662 23.249 27.1131 22.6529 27.1131 22.0312V14.0039'
        stroke='#4BABB3'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='transparent'
      />
      <path
        d='M6.5625 9.375L15 15.9375L20.0977 12.0896'
        stroke='#4BABB3'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='transparent'
      />
      <path
        d='M25.3096 10.3125C26.8613 10.3125 28.1191 9.05461 28.1191 7.50293C28.1191 5.95125 26.8613 4.69336 25.3096 4.69336C23.7579 4.69336 22.5 5.95125 22.5 7.50293C22.5 9.05461 23.7579 10.3125 25.3096 10.3125Z'
        fill='#EE6338'
      />
      <path
        d='M25.3125 11.25C24.5714 11.25 23.8469 11.0302 23.2307 10.6185C22.6145 10.2068 22.1343 9.62156 21.8507 8.93687C21.5671 8.25219 21.4928 7.49877 21.6374 6.77191C21.782 6.04505 22.1389 5.37739 22.6629 4.85335C23.187 4.32932 23.8546 3.97244 24.5815 3.82786C25.3083 3.68328 26.0618 3.75748 26.7464 4.04109C27.4311 4.3247 28.0163 4.80497 28.4281 5.42117C28.8398 6.03737 29.0596 6.76183 29.0596 7.50293C29.0588 8.49648 28.6638 9.44911 27.9612 10.1517C27.2587 10.8542 26.306 11.2492 25.3125 11.25ZM25.3125 5.63086C24.9415 5.63028 24.5787 5.73976 24.27 5.94543C23.9613 6.15111 23.7205 6.44374 23.5781 6.7863C23.4358 7.12886 23.3982 7.50595 23.4702 7.86986C23.5423 8.23376 23.7206 8.56813 23.9827 8.83065C24.2448 9.09316 24.5789 9.27203 24.9427 9.34462C25.3065 9.4172 25.6836 9.38024 26.0264 9.23841C26.3692 9.09659 26.6622 8.85627 26.8684 8.54786C27.0745 8.23945 27.1846 7.87683 27.1846 7.50586C27.1846 7.00909 26.9874 6.53262 26.6364 6.18107C26.2854 5.82952 25.8093 5.63164 25.3125 5.63086Z'
        fill='#EE6338'
      />
    </SvgIcon>
  );
}

UnreadMailIcon.defaultProps = {
  viewBox: '0 0 30 30'
};

export default React.memo(UnreadMailIcon);
