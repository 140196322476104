import styled from '@emotion/styled';

export const IllustrationWrapper = styled.div`
  && {
    svg {
      width: 300px;
      height: auto;
    }
  }
`;
