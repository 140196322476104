import config from '../config';
import { BannerWrapper } from './styles';

const StagingEnvBanner = () => {
  if (config.isStagingEnv) {
    return (
      <BannerWrapper>
        You are on the staging environment. Do not use with production credentials.
      </BannerWrapper>
    );
  }

  return null;
};

export default StagingEnvBanner;
