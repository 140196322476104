import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
// @ld
import { colors } from '@ld/utils';

export const UnstyledLink: any = styled(Link)`
  &,
  &:link,
  &:active {
    cursor: pointer;
    text-decoration: none;
  }
`;

export const UnstyledExternalLink = styled.a`
  &,
  &:link,
  &:active {
    cursor: pointer;
    text-decoration: none;
  }
`;

export const ExternalLink = styled.a<{ color: 'primary' | 'secondary' }>`
  &,
  &:active,
  &:hover,
  &:link {
    text-decoration: none;
    color: ${(props) => (props.color === 'secondary' ? colors.green1 : colors.orange1)};
  }
`;
