import dateFnsFormat from 'date-fns/format';

export const snakeToNormalCase = (str = '') => {
  const str2 = str.replace(/_/g, ' ');
  return str2.charAt(0).toUpperCase() + str2.slice(1);
};

export const kebabToNormalCase = (str = '') => {
  const str2 = str.replace(/-/g, ' ');
  return str2.charAt(0).toUpperCase() + str2.slice(1);
};

export const toKebabCase = (str = '') =>
  str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

export const formatDate = (date?: any, format = 'dd MMM yyyy') => {
  if (!date) {
    return '';
  }

  if (date.toDate) {
    return dateFnsFormat(date.toDate(), format);
  }

  return dateFnsFormat(new Date(date), format);
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const strArrayToHumanReadableString = (
  arr: string[],
  lastSeparator: '&' | 'and' = 'and'
) => {
  // Format string array to comma separated list with the last item separated with an 'and' or '&'
  return arr.join(', ').replace(/, ([^,]*)$/, ` ${lastSeparator} $1`);
};
