import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function CollectiblesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M35.0001 18.5205L27.3151 29.276L19.7549 18.6952C19.9926 18.5815 20.2523 18.5205 20.5195 18.5205H35.0001Z'
        fill='#FFD68B'
      />
      <path
        d='M50.2452 18.6952L42.6849 29.276L35 18.5205H49.4807C49.7479 18.5205 50.0075 18.5814 50.2452 18.6952Z'
        fill='#FFD68B'
      />
      <path
        d='M57.9986 29.2761H42.6848L50.2452 18.6953H50.2462C50.5125 18.8225 50.7502 19.0157 50.9404 19.2639L57.5583 27.8785C57.8692 28.2827 58.0157 28.7819 57.9986 29.2761Z'
        fill='#FFCA69'
      />
      <path
        d='M34.9999 18.5205L27.3147 29.2757H42.6852L34.9999 18.5205Z'
        fill='#FFCD71'
      />
      <path
        d='M27.315 29.2761H12.0013C11.9842 28.7819 12.1307 28.2827 12.4417 27.8785L19.0596 19.2639C19.2498 19.0158 19.4875 18.8225 19.7538 18.6953H19.7548L27.315 29.2761Z'
        fill='#FFDEA0'
      />
      <path
        d='M42.6848 29.2764L35.2757 55.9546C35.0932 55.9835 34.9067 55.9835 34.7242 55.9546L27.3149 29.2764H42.6848Z'
        fill='#FFC559'
      />
      <path
        d='M34.7244 55.9546C34.3126 55.8895 33.917 55.6775 33.6099 55.3188C33.6089 55.3178 33.607 55.3157 33.6061 55.3147L12.5075 30.6108C12.5046 30.6077 12.5027 30.6046 12.4998 30.6016C12.1831 30.2264 12.0158 29.7539 12.0015 29.2764H27.3152L34.7244 55.9546Z'
        fill='#FFD68B'
      />
      <path
        d='M57.9987 29.2764C57.9844 29.7539 57.8171 30.2264 57.5004 30.6016C57.4975 30.6047 57.4956 30.6078 57.4927 30.6108L36.3942 55.3147C36.3932 55.3158 36.3913 55.3179 36.3904 55.3188C36.0832 55.6775 35.6876 55.8895 35.2759 55.9546L42.685 29.2764H57.9987Z'
        fill='#F5B53F'
      />
      <path
        d='M51.905 40.0632L52.6381 39.6967C52.9429 39.5443 52.9429 39.1094 52.6381 38.957L51.905 38.5905C51.5504 38.4133 51.2733 38.112 51.1261 37.744L50.5246 36.2404C50.3861 35.8938 49.8954 35.8938 49.7569 36.2404L49.1554 37.744C49.0081 38.1121 48.731 38.4133 48.3765 38.5905L47.6434 38.957C47.3386 39.1094 47.3386 39.5443 47.6434 39.6967L48.3765 40.0632C48.7311 40.2404 49.0082 40.5417 49.1554 40.9096L49.7569 42.4133C49.8954 42.7598 50.3861 42.7598 50.5246 42.4133L51.1261 40.9096C51.2733 40.5417 51.5504 40.2404 51.905 40.0632Z'
        fill='#EE6338'
      />
      <path
        d='M27.1577 48.6774L27.8908 48.3109C28.1956 48.1586 28.1956 47.7236 27.8908 47.5713L27.1577 47.2048C26.8031 47.0275 26.526 46.7262 26.3788 46.3583L25.7773 44.8547C25.6387 44.5081 25.1481 44.5081 25.0095 44.8547L24.4081 46.3583C24.2609 46.7263 23.9837 47.0275 23.6292 47.2048L22.8961 47.5713C22.5913 47.7236 22.5913 48.1586 22.8961 48.3109L23.6292 48.6774C23.9838 48.8547 24.2609 49.1559 24.4081 49.5239L25.0095 51.0275C25.1481 51.3741 25.6387 51.3741 25.7773 51.0275L26.3788 49.5239C26.526 49.1559 26.8032 48.8547 27.1577 48.6774Z'
        fill='#EE6338'
      />
      <path
        d='M21.8225 19.4714L22.9832 18.891C23.288 18.7386 23.288 18.3037 22.9832 18.1513L21.8225 17.571C21.365 17.3423 21.0074 16.9536 20.8175 16.4787L19.93 14.2599C19.7914 13.9134 19.3008 13.9134 19.1622 14.2599L18.2747 16.4787C18.0847 16.9536 17.7271 17.3423 17.2696 17.571L16.109 18.1513C15.8042 18.3037 15.8042 18.7386 16.109 18.891L17.2696 19.4714C17.7271 19.7001 18.0847 20.0887 18.2747 20.5636L19.1622 22.7824C19.3008 23.129 19.7914 23.129 19.93 22.7824L20.8175 20.5636C21.0075 20.0887 21.3651 19.7001 21.8225 19.4714Z'
        fill='#EE6338'
      />
    </SvgIcon>
  );
}

CollectiblesIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default React.memo(CollectiblesIcon);
