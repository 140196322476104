import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function ShieldIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M18.7451 4.22199C14.5881 3.95191 11.6792 1.41277 10.5371 0.228516C10.2433 -0.0761719 9.75627 -0.0761719 9.46244 0.228516C8.32033 1.41273 5.41147 3.95195 1.25439 4.22199C0.844863 4.24859 0.52998 4.59516 0.551855 5.00496C0.690137 7.59203 1.75303 16.1104 9.67826 19.9267C9.88111 20.0244 10.1183 20.0244 10.3212 19.9267C18.2465 16.1104 19.3094 7.59203 19.4477 5.00496C19.4696 4.59519 19.1547 4.24859 18.7451 4.22199Z'
        fill='#313842'
      />
      <path
        d='M9.99984 10.1401L8.25488 11.8484V15.9869L9.99984 18.2897C10.1305 18.2897 10.2613 18.2569 10.3795 18.1915C13.0741 16.7011 15.1023 14.42 16.4148 11.4006C16.5998 10.9752 16.7623 10.5536 16.9056 10.1402H9.99984V10.1401Z'
        fill='#4BABB3'
      />
      <path
        d='M6.54713 8.38379L3.09424 10.1402C3.2376 10.5537 3.40014 10.9752 3.58506 11.4007C4.89756 14.4201 6.92584 16.7011 9.62033 18.1916C9.73857 18.257 9.86928 18.2897 9.99998 18.2897V10.1402L6.54713 8.38379Z'
        fill='white'
      />
      <path
        d='M9.9999 2.08936C9.82017 2.08936 9.64045 2.15158 9.49502 2.276C8.89252 2.7915 8.14357 3.35381 7.25693 3.87482C5.84115 4.70678 4.36111 5.26729 2.84068 5.54896C2.43209 5.62467 2.15264 6.00506 2.20885 6.41682C2.35021 7.45217 2.61205 8.7499 3.09408 10.1404H9.99986L12.0565 8.384V5.01557L9.9999 2.08936Z'
        fill='#4BABB3'
      />
      <path
        d='M16.9058 10.1404C17.3878 8.7499 17.6497 7.45217 17.7911 6.41682C17.8473 6.00506 17.5679 5.62463 17.1592 5.54896C15.6388 5.26736 14.1587 4.70682 12.743 3.87482C11.8563 3.35381 11.1074 2.79146 10.5049 2.276C10.3595 2.15158 10.1797 2.08936 10 2.08936V10.1404H16.9058V10.1404H16.9058Z'
        fill='white'
      />
      <g opacity='0.1'>
        <path
          d='M3.82592 3.74805C3.03361 3.9909 2.17471 4.16207 1.25439 4.2218C0.844863 4.2484 0.52998 4.59496 0.551855 5.00477C0.689785 7.58582 1.74834 16.0704 9.62338 19.8996C4.78736 14.5734 3.96307 6.65289 3.82592 3.74805Z'
          fill='#313842'
        />
      </g>
    </SvgIcon>
  );
}

ShieldIcon.defaultProps = {
  viewBox: '0 0 20 20'
};

export default React.memo(ShieldIcon);
