import styled from '@emotion/styled';

export const Card = styled.div`
  border: none;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);
`;

export const CardContent = styled.div`
  padding: 30px 20px 20px;
`;

export const CardFooter = styled.div`
  padding: 10px 20px;
`;
