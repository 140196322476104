import { Category } from './types';

const metadata = [
  {
    type: 'date',
    name: 'expiresAt',
    label: 'Expiry date',
    disablePast: true
  }
];

// Documents
const idDocument = {
  typeId: 'df228096',
  name: 'ID document'
};

const birthCertificate = {
  typeId: '51e79343',
  name: 'Birth certificate'
};

const passport = {
  typeId: '0627d4a3',
  name: 'Passports',
  multipleDocuments: true,
  metadata
};

const driversLicence = {
  typeId: 'a72739ad',
  name: 'Drivers licence',
  metadata
};

const proofOfResidence = {
  typeId: 'cb6d4509',
  name: 'Proof of residence'
};

const visa = {
  typeId: '63893d69',
  name: 'Visas',
  multipleDocuments: true,
  metadata
};

const marriageCertificate = {
  typeId: 'a4a59b49',
  name: 'Marriage certificate',
  multipleOwners: true
};

const antenuptialContract = {
  typeId: '1cbfdd8c',
  name: 'Antenuptial contract',
  multipleOwners: true
};

const cohabitationAgreement = {
  typeId: '099b40b4',
  name: 'Cohabitation agreement',
  multipleOwners: true
};

const decreeOfDivorce = {
  typeId: '30258f6c',
  name: 'Decree of divorce'
};

const divorceSettlementAgreement = {
  typeId: '2d6adefe',
  name: 'Divorce settlement agreement'
};

const firearmCompetency = {
  typeId: 'bd886a7c',
  name: 'Firearm competency'
};

const tvLicense = {
  typeId: 'f3aef86c',
  name: 'TV licence'
};

// Category

const personal: Category = {
  id: 'syW0Tl84be49x4BJK5D9',
  name: 'Personal',
  shortDescription: 'Your personal information and documents that relate directly to you',
  description:
    'All about you! Store your personal information and documents. After your Will, this information is the most important to ensure your estate can be reported,  and the process of winding up can begin.',
  isRequired: true,
  documents: [
    idDocument,
    driversLicence,
    birthCertificate,
    passport,
    visa,
    proofOfResidence,
    marriageCertificate,
    antenuptialContract,
    cohabitationAgreement,
    decreeOfDivorce,
    divorceSettlementAgreement,
    firearmCompetency,
    tvLicense
  ]
};

export default personal;

export const personalDocuments = {
  idDocument,
  driversLicence,
  birthCertificate,
  passport,
  visa,
  proofOfResidence,
  marriageCertificate,
  antenuptialContract,
  cohabitationAgreement,
  decreeOfDivorce,
  divorceSettlementAgreement,
  firearmCompetency,
  tvLicense
};
