import qs from 'query-string';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// @custom
import {
  onboardUrl,
  myProfilesUrl,
  verifyEmailUrl,
  twoFactorAuthenticationUrl
} from 'constants/urls';
import {
  getIsTwoFactorVerified,
  getWasSigninEmailNotificationSent,
  setSigninEmailNotificationSentFlag
} from 'services/session-storage';
import useUser from 'hooks/use-user';
import { getDashboardUrl } from 'utils/urls';
import { useSendSignInNotificationEmail } from 'api/auth';

/**
 * Used to redirect away from auth screens if the user is successfully logged in
 */
const useRedirectToDashboard = () => {
  const { user } = useUser();
  const history = useHistory();
  const { search } = useLocation();
  const params = qs.parse(search);
  const nextUrl = params.nextUrl as string | null;

  const { handleSendSignInNotificationEmail, isLoading, isSuccess, isError } =
    useSendSignInNotificationEmail({
      onSuccess: () => {
        setSigninEmailNotificationSentFlag();
      }
    });

  useEffect(() => {
    if (user) {
      if (!getWasSigninEmailNotificationSent() && !isLoading && !isSuccess && !isError) {
        // Don't send emails on staging or debug env
        handleSendSignInNotificationEmail();
      }
      if (!user.isEmailVerified) {
        history.replace({
          pathname: verifyEmailUrl,
          state: { email: user.email }
        });
      } else if (!user.isOnboarded) {
        history.replace(onboardUrl);
      } else if (user.isTwoFactorEnabled && !getIsTwoFactorVerified()) {
        history.replace(twoFactorAuthenticationUrl);
      } else if (user.primaryProfileId) {
        history.replace(nextUrl || getDashboardUrl(user.primaryProfileId));
      } else {
        history.replace(myProfilesUrl);
      }
    }
  }, [history, user]);

  return null;
};

export default useRedirectToDashboard;
