export type TUploadFileData = {
  file: File;
  documentName?: string;
};

export type TUploadFileSuccess = {
  fileName: string;
  downloadURL: string;
};

export type TUploadFileOptions = {
  onError?: (error: Error) => void;
  onSuccess?: (data: TUploadFileSuccess) => void;
};

export type TUploadFileProcessing = {
  data: TUploadFileData;
  options?: TUploadFileOptions;
};

export enum EUploadFileStatus {
  IDLE = 'idle',
  UPLOADING = 'uploading',
  PAUSED = 'paused',
  ERROR = 'error',
  SUCCESS = 'success'
}

export type TUploadFileState = {
  file: File;
  progress: number;
  status: EUploadFileStatus;
};
