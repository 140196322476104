import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
// @mui
import MuiButton from '@mui/material/Button';
import MuiLinearProgress from '@mui/material/LinearProgress';
import MuiCircularProgress from '@mui/material/CircularProgress';
// @ld
import { colors, EUploadFileStatus } from '@ld/utils';

type ContainerProps = {
  $minimized: boolean;
};

export const pulse = keyframes`
  from {
    stroke: ${colors.grey1}
  }
  to {
    stroke: ${colors.grey4}
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 360px;
  padding: 20px;
  color: #ffffff;
  border-radius: 20px;
  background-color: ${colors.black1};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);

  ${(props) =>
    props.$minimized &&
    css`
      width: 50px;
      height: 50px;
      padding: 0px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        animation: ${pulse} 1s ease-in-out infinite;
      }
    `}
`;

export const Title = styled.p`
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

type TextProps = {
  $noMargin?: boolean;
};

export const Text = styled.p<TextProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.$noMargin ? 0 : 5)}px;
`;

type StatusIconWrapperProps = {
  $variant?: EUploadFileStatus;
};

const getStyle = (variant?: EUploadFileStatus) => {
  switch (variant) {
    case EUploadFileStatus.ERROR:
      return css`
        color: ${colors.orange1};
        background-color: ${colors.orange2};
      `;
    case EUploadFileStatus.SUCCESS:
      return css`
        color: ${colors.green1};
        background-color: ${colors.green3};
      `;
    case EUploadFileStatus.IDLE:
      return css`
        color: ${colors.green1};
        background-color: ${colors.grey4};
      `;
    default:
      return css`
        color: ${colors.black1};
        background-color: ${colors.grey4};
      `;
  }
};

export const StatusIconWrapper = styled.div<StatusIconWrapperProps>`
  padding: 10px;
  border-radius: 10px;
  ${(props) => getStyle(props.$variant)};
  ${(props) =>
    props.$variant !== 'idle' &&
    css`
      svg {
        width: 24px;
        height: 24px;
      }
    `}
`;

export const LinearProgress = styled(MuiLinearProgress)`
  && {
    border-radius: 3px;
    background-color: ${colors.grey4};

    .MuiLinearProgress-root {
      border-radius: 3px;
    }

    .MuiLinearProgress-bar {
      border-radius: 3px;
      background: linear-gradient(90deg, #35dbe9 0%, #4babb3 118.99%);
    }
  }
`;

export const CircularProgress = styled(MuiCircularProgress)`
  && {
    color: ${colors.green1};
  }
`;

export const Button = styled(MuiButton)`
  && {
    border-radius: 10px;
    background: transparent;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    padding: 0px;
    width: fit-content;
    color: ${colors.orange1};

    &:hover {
      background: transparent;
      color: ${colors.orange1};
    }

    &:disabled {
      color: ${colors.orange1};
      opacity: 0.5;
    }
  }
`;
