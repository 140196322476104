import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function DiplomaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M43.4751 22.1241C43.2819 22.1241 43.0888 22.0505 42.9415 21.9031L34.6968 13.6584L26.4521 21.9031C26.1573 22.1979 25.6796 22.1977 25.385 21.9031C25.0904 21.6085 25.0904 21.1308 25.385 20.8361L34.1635 12.0579C34.305 11.9165 34.4968 11.8369 34.697 11.8369C34.8972 11.8369 35.089 11.9164 35.2306 12.0579L44.0089 20.8361C44.3035 21.1308 44.3035 21.6086 44.0089 21.9031C43.8612 22.0505 43.668 22.1241 43.4751 22.1241Z'
        fill='#868686'
      />
      <path d='M56.1827 21.6406H13.7119V55.3036H56.1827V21.6406Z' fill='#EFEFEF' />
      <path
        d='M60 18.3193H10L12.2539 26.0024L15.6081 24.2839H54.3919L57.1395 26.0024L59.1355 20.0939L60 18.3193Z'
        fill='#313842'
      />
      <path
        d='M58.9972 57.467L56.5894 52.085L53.7853 53.3991H15.6081L12.8041 52.085L10 59.3638H60L58.9972 57.467Z'
        fill='#313842'
      />
      <path
        d='M48.0256 39.5732L51.3334 49.5911L48.4638 48.4408L46.8434 51.0736L43.5356 41.0557L48.0256 39.5732Z'
        fill='#EE6338'
      />
      <path
        opacity='0.05'
        d='M48.0256 39.5732L51.3334 49.5911L48.4638 48.4408L46.8434 51.0736L43.5356 41.0557L48.0256 39.5732Z'
        fill='#313842'
      />
      <path
        d='M40.9919 39.5732L37.6841 49.5911L40.5537 48.4408L42.1742 51.0736L45.4818 41.0557L40.9919 39.5732Z'
        fill='#EE6338'
      />
      <path
        d='M50.2175 34.8224C49.8738 34.4671 49.7132 33.973 49.7824 33.4833C49.9035 32.6271 49.3238 31.8293 48.4722 31.6797C47.9851 31.5942 47.5648 31.2889 47.3329 30.8521C46.9277 30.0882 45.9897 29.7834 45.2128 30.1632C44.7686 30.3803 44.249 30.3803 43.8047 30.1632C43.0278 29.7834 42.0898 30.0883 41.6846 30.8521C41.4528 31.2889 41.0324 31.5942 40.5454 31.6797C39.6937 31.8291 39.1139 32.627 39.2351 33.4833C39.3043 33.9728 39.1437 34.467 38.8 34.8224C38.1988 35.4439 38.1988 36.4302 38.8 37.0517C39.1437 37.4071 39.3044 37.9012 39.2351 38.3909C39.114 39.2471 39.6937 40.0449 40.5454 40.1944C41.0324 40.2799 41.4528 40.5853 41.6846 41.0221C42.0898 41.7859 43.0278 42.0907 43.8047 41.7109C44.249 41.4938 44.7686 41.4938 45.2128 41.7109C45.9896 42.0907 46.9277 41.7859 47.3329 41.0221C47.5648 40.5853 47.9851 40.2799 48.4722 40.1944C49.3238 40.0451 49.9036 39.2471 49.7824 38.3909C49.7133 37.9013 49.8738 37.4071 50.2175 37.0517C50.8188 36.4302 50.8188 35.4439 50.2175 34.8224Z'
        fill='#F5B53F'
      />
      <path
        d='M46.9665 33.464C45.6017 32.0992 43.3886 32.0992 42.0238 33.464C40.6587 34.8291 40.6587 37.0419 42.0238 38.407C43.3886 39.7718 45.6017 39.7718 46.9665 38.407C48.3316 37.0419 48.3316 34.8291 46.9665 33.464Z'
        fill='#FFE285'
      />
      <path
        d='M34.6978 32.3967H19.7865C19.3697 32.3967 19.032 32.0589 19.032 31.6422C19.032 31.2255 19.3697 30.8877 19.7865 30.8877H34.6978C35.1146 30.8877 35.4523 31.2255 35.4523 31.6422C35.4523 32.0589 35.1145 32.3967 34.6978 32.3967Z'
        fill='#313842'
      />
      <path
        d='M30.653 36.2883H19.7855C19.3687 36.2883 19.031 35.9505 19.031 35.5338C19.031 35.1171 19.3687 34.7793 19.7855 34.7793H30.653C31.0698 34.7793 31.4075 35.1171 31.4075 35.5338C31.4075 35.9505 31.0698 36.2883 30.653 36.2883Z'
        fill='#313842'
      />
      <path
        d='M32.622 36.2883H21.7545C21.3377 36.2883 21 35.9505 21 35.5338C21 35.1171 21.3377 34.7793 21.7545 34.7793H32.622C33.0388 34.7793 33.3765 35.1171 33.3765 35.5338C33.3765 35.9505 33.0388 36.2883 32.622 36.2883Z'
        fill='#313842'
      />
      <path
        d='M30.653 40.1809H19.7855C19.3687 40.1809 19.031 39.8431 19.031 39.4264C19.031 39.0097 19.3687 38.6719 19.7855 38.6719H30.653C31.0698 38.6719 31.4075 39.0097 31.4075 39.4264C31.4075 39.843 31.0698 40.1809 30.653 40.1809Z'
        fill='#313842'
      />
      <path
        d='M30.653 48.2482H19.7855C19.3687 48.2482 19.031 47.9104 19.031 47.4937C19.031 47.077 19.3687 46.7393 19.7855 46.7393H30.653C31.0698 46.7393 31.4075 47.077 31.4075 47.4937C31.4075 47.9104 31.0698 48.2482 30.653 48.2482Z'
        fill='#313842'
      />
      <path
        d='M15.6081 53.3987L10 59.3634V18.3193L15.6081 24.2839V53.3987Z'
        fill='#272B31'
      />
      <path
        d='M54.3918 53.3987L60 59.3634V18.3193L54.3918 24.2839V53.3987Z'
        fill='#272B31'
      />
      <path
        d='M34.6971 14.5455C35.7765 14.5455 36.6515 13.6705 36.6515 12.5911C36.6515 11.5117 35.7765 10.6367 34.6971 10.6367C33.6177 10.6367 32.7427 11.5117 32.7427 12.5911C32.7427 13.6705 33.6177 14.5455 34.6971 14.5455Z'
        fill='#313842'
      />
      <path
        opacity='0.05'
        d='M35.7026 13.539C34.6232 13.539 33.7482 12.6639 33.7482 11.5846C33.7482 11.3056 33.8076 11.0408 33.9129 10.8008C33.2239 11.103 32.7422 11.7901 32.7422 12.5906C32.7422 13.67 33.6173 14.545 34.6966 14.545C35.4971 14.545 36.1842 14.0632 36.4864 13.3743C36.2464 13.4796 35.9815 13.539 35.7026 13.539Z'
        fill='#313842'
      />
      <path d='M54.3918 24.2832H15.6082V27.2269H54.3918V24.2832Z' fill='#E5E6E6' />
    </SvgIcon>
  );
}

DiplomaIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default React.memo(DiplomaIcon);
