import * as React from 'react';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';

// @local
import { TextInput } from '../text-input';
import toFormField from '../to-form-field';

export const PasswordInput = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              edge='end'
              // tabIndex='-1'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              aria-label='toggle password visibility'
            >
              {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default toFormField(PasswordInput);
