import * as React from 'react';
// @custom
import { EntityDetailsProps } from './types';
import FieldValue from '../../field-value';

const DependentDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={entity.dependentName} label='Dependent name' />
      <FieldValue value={entity.dependentEmail} label='Dependent Email' />
      <FieldValue value={entity.dependentPhoneNumber} label='Dependent Phone number' />
    </>
  );
};

export default React.memo(DependentDetails);
