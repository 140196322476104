import * as React from 'react';
// @mui
import { Box, Chip } from '@mui/material';
import { snakeToNormalCase } from '@ld/utils';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const InsurancePolicyDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={entity.policyNumber} label='Policy number' />
      <FieldValue
        label='This policy includes'
        ValueComponent={
          <Box mt='5px' display='flex' flexWrap='wrap'>
            {entity.policyCoverage?.map((item: string) => (
              <Box key={item} mr='4px' mb='4px'>
                <Chip label={snakeToNormalCase(item)} />
              </Box>
            ))}
          </Box>
        }
      />
    </>
  );
};

export default React.memo(InsurancePolicyDetails);
