// @mui
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
// @local
import toFormField from '../to-form-field';

type CustomRadioGroupProps = RadioGroupProps & {
  disabled?: boolean;
  fullWidth?: boolean;
  options: {
    value: number | string;
    label: React.ReactNode;
    disabled?: boolean;
  }[];
};

export const RadioGroup = ({
  name,
  options,
  disabled,
  fullWidth = true,
  ...rest
}: CustomRadioGroupProps) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <MuiRadioGroup name={name} aria-label={name} {...rest}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={<Radio />}
            value={option.value}
            label={option.label}
            disabled={option.disabled || disabled}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default toFormField(RadioGroup);
