import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';
// @ld
import { IUserRead } from '@ld/utils';
// @custom
import { unauthorizedUrl } from 'constants/urls';

export default function useAuth() {
  const history = useHistory();
  const [user, setUser] = useState<IUserRead | null>(null);
  const [isInitialising, setInitialising] = useState(true); // Helpful, to update the UI accordingly.

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();
    // Listen authenticated user
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user && user.uid && user.email) {
          // User is signed in.
          // You could also look for the user doc in your Firestore (if you have one):
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userRef);
          const userData = userDoc.exists() ? userDoc.data() : {};
          if (userData?.advisor) {
            auth.signOut();
            history.replace(unauthorizedUrl);
          } else {
            const profileInvitesCount = Object.keys(
              userData?.profileInvites || {}
            ).length;
            setUser({
              uid: user.uid,
              email: user.email,
              profileInvitesCount,
              photoURL: userData.photoURL,
              isOnboarded: userData.isOnboarded,
              isEmailVerified: user.emailVerified,
              name: userData.name || user.displayName,
              primaryProfileId: userData.primaryProfileId,
              isTwoFactorEnabled: userData.isTwoFactorEnabled,
              created: userData.created,
              updated: userData.updated
            });
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        // Most probably a connection error. Handle appropriately.
      } finally {
        setInitialising(false);
      }
    });

    // Unsubscribe auth listener on unmount
    return () => unsubscribe();
  }, []);

  return { user, isInitialising, setUser };
}
