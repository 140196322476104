import { getEntityDocumentVaultConfigKey } from './entity';
import type { IUserDocumentRead } from '../types/documents';

const getDocsStatus = (arr?: IUserDocumentRead[]) => {
  const docsStatus: Record<
    string,
    { count: number; hasMultiple?: boolean; uploadedAt?: any }
  > = {};
  arr?.forEach((doc) => {
    const configKey = doc.entityId
      ? getEntityDocumentVaultConfigKey(doc.entityId, doc.typeId)
      : doc.typeId;
    if (docsStatus[configKey]) {
      docsStatus[configKey] = {
        hasMultiple: true,
        count: (docsStatus[configKey].count || 0) + 1
      };
    } else {
      docsStatus[configKey] = {
        count: 1,
        uploadedAt: doc.createdAt?.toDate()
      };
    }
  });
  return docsStatus;
};

export default getDocsStatus;
