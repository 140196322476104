export const isRequired = (
  value: string | number | null | undefined
): string | undefined => {
  if (!value) return 'Required';
  return undefined;
};

export const isEmail = (value: string | null | undefined): string | undefined => {
  if (!value) return;
  const emailParts = value.split('@');
  if (emailParts.length !== 2) return 'Invalid email address';
  try {
    const domain = emailParts[1];
    if (!domain) return 'Invalid email address';
    const domainParts = domain.split('.');
    if (domainParts.length <= 0) return 'Invalid email address';
  } catch (error) {
    return 'Invalid email address';
  }
  return undefined;
};

export const validateAll =
  (...functions: ((v: any) => string | undefined)[]) =>
  (value: string | number | null | undefined) => {
    for (const func of functions) {
      const error = func(value);
      if (error) return error;
    }
    return undefined;
  };

export const isRequiredEmail = (value: string | null | undefined) => {
  return validateAll(isRequired, isEmail)(value);
};

export const isNonEmptyArray = (value?: unknown[]) => {
  if (!value) return;
  if (value.length === 0) {
    return 'Select at least one option';
  }
  return;
};

export const isRequiredArray = (value: any) => {
  return validateAll(isRequired, isNonEmptyArray)(value);
};

export const notIn =
  (value?: string | null) =>
  (notAllowedList: string[], error = 'Not allowed') => {
    if (value && notAllowedList.includes(value)) {
      return error;
    }
    return undefined;
  };
