import Button, { ButtonProps } from '@mui/material/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
// @local
import {
  defaultButtonSx,
  defaultIconButtonSx,
  primaryAltButtonSx,
  secondaryAltButtonSx,
  StyledButton
} from './sx';

export const DefaultOutlinedButton = (props: ButtonProps) => (
  <Button {...props} color='inherit' variant='outlined' />
);

export const SecondaryTextButton = (props: ButtonProps) => (
  <StyledButton {...props} color='secondary' variant='text' />
);

export const DefaultButton = (props: ButtonProps) => (
  <Button {...props} sx={defaultButtonSx} />
);

export const DefaultIconButton = (props: IconButtonProps) => (
  <IconButton {...props} sx={defaultIconButtonSx} />
);

export const SecondaryAltButton = (props: ButtonProps) => (
  <Button {...props} sx={secondaryAltButtonSx} />
);
export const PrimaryAltButton = (props: ButtonProps) => (
  <Button {...props} sx={primaryAltButtonSx} />
);
