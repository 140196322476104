import { TNullableString } from './base';
import { IEventAtRead } from './event';

interface IBaseUser {
  uid: string;
  name: string;
  email: string;
  isEmailVerified: boolean;
  photoURL: TNullableString;
  isTwoFactorEnabled?: boolean;
  created: IEventAtRead;
  updated: IEventAtRead;
}

export interface IUserRead extends IBaseUser {
  isOnboarded: boolean;
  primaryProfileId?: string;
  profileInvitesCount: number;
}

export enum EAdvisorRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
  ASSISTANT = 'assistant'
}

export interface IAdvisorRead extends IBaseUser {
  orgId: string;
  advisor: boolean;
  averageERS?: number;
  orgRole: EAdvisorRole;
  numberOfProfiles?: number;
}
