import { IconType } from 'react-icons';
import { NavLinkProps } from 'react-router-dom';
// @custom
import { StyledLink, IconWrapper, ActiveBorder } from './styles';

const StyledNavLink = ({ children, ...rest }: NavLinkProps) => (
  <StyledLink {...rest} activeClassName='active'>
    <ActiveBorder />
    {children}
  </StyledLink>
);

type NavItemProps = {
  to: string;
  label: string;
  activeIcon: IconType;
  inactiveIcon: IconType;
};

const NavItem = ({
  to,
  label,
  activeIcon: ActiveIcon,
  inactiveIcon: InactiveIcon
}: NavItemProps) => (
  <StyledNavLink to={to}>
    <IconWrapper>
      <InactiveIcon size={24} />
      <ActiveIcon size={24} />
    </IconWrapper>
    {label}
  </StyledNavLink>
);

export default NavItem;
