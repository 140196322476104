// @ld
import { colors } from '@ld/utils';
import styled from '@emotion/styled';

export const AlertBannerWrapper = styled.div`
  margin: 20px 0px;
  padding: 15px 20px;
  position: relative;
  background: #ffffff;
  border-radius: 0px 10px 10px 0px;
  border-left: 3px solid ${colors.orange1};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);
`;

export const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  margin-right: 15px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  color: ${colors.orange1};
  background: ${colors.orange2};

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.down('md')} {
    display: block;
  }
`;

export const ActionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: 5px;
  }
`;
