import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function PropertyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M34.9986 13.6865L17.6055 30.6053V55.4588H52.3917V30.6053L34.9986 13.6865Z'
        fill='#EFEFEF'
      />
      <path
        opacity='0.05'
        d='M21.7305 33.1429C21.7305 32.5496 21.9703 31.9815 22.3956 31.5679L37.8885 16.4976L34.9986 13.6865L17.6055 30.6053V55.4587H21.7305V33.1429Z'
        fill='#313842'
      />
      <path d='M45.6604 14.5381V20.846L51.2299 26.2637V14.5381H45.6604Z' fill='#313842' />
      <path
        d='M48.8859 21.2746C48.6042 21.0006 48.4452 20.6242 48.4452 20.2312V14.5381H45.6604V20.846L51.2299 26.2637V23.5548L48.8859 21.2746Z'
        fill='#272B31'
      />
      <path
        d='M57.0073 31.5835L36.4525 11.5891C35.6437 10.8023 34.3558 10.8023 33.5471 11.5891L12.9923 31.5835C12.1675 32.3857 12.1492 33.7048 12.9516 34.5296C13.7539 35.3544 15.073 35.3726 15.8977 34.5703L34.7139 16.2672C34.8731 16.1124 35.1265 16.1124 35.2857 16.2672L54.1019 34.5702C54.9271 35.3728 56.246 35.3541 57.048 34.5295C57.8503 33.7048 57.8321 32.3857 57.0073 31.5835Z'
        fill='#EE6338'
      />
      <path
        opacity='0.05'
        d='M34.7139 16.2672C34.7854 16.1976 34.876 16.1611 34.9685 16.154L36.7999 14.3724C37.3408 13.8463 38.0955 13.6739 38.7783 13.8515L36.4525 11.5891C35.6437 10.8023 34.3558 10.8023 33.5471 11.5891L12.9923 31.5835C12.1675 32.3857 12.1492 33.7048 12.9516 34.5296C13.7539 35.3544 15.073 35.3726 15.8977 34.5703L34.7139 16.2672Z'
        fill='#313842'
      />
      <path
        d='M52.8441 14.1138V11.7275C52.8441 11.3258 52.5183 11 52.1166 11H44.7707C44.3689 11 44.0432 11.3257 44.0432 11.7275V14.1139C44.0432 14.5156 44.3689 14.8414 44.7707 14.8414H52.1166C52.5183 14.8413 52.8441 14.5156 52.8441 14.1138Z'
        fill='#313842'
      />
      <path
        d='M46.828 14.1138V11.7275C46.828 11.3258 47.1537 11 47.5554 11H44.7707C44.3689 11 44.0432 11.3257 44.0432 11.7275V14.1139C44.0432 14.5156 44.3689 14.8414 44.7707 14.8414H47.5554C47.1537 14.8413 46.828 14.5156 46.828 14.1138Z'
        fill='#272B31'
      />
      <path
        d='M21.6331 36.1201C21.6331 35.844 21.8569 35.6201 22.1331 35.6201H32.8031C33.0792 35.6201 33.3031 35.844 33.3031 36.1201V54.9601C33.3031 55.2363 33.0792 55.4601 32.8031 55.4601H22.1331C21.8569 55.4601 21.6331 55.2363 21.6331 54.9601V36.1201Z'
        fill='#313842'
      />
      <path d='M21.6331 35.6201H25.4116V55.4601H21.6331V35.6201Z' fill='#272B31' />
      <path
        d='M31.0002 47C31.5525 47 32.0002 46.5523 32.0002 46C32.0002 45.4477 31.5525 45 31.0002 45C30.448 45 30.0002 45.4477 30.0002 46C30.0002 46.5523 30.448 47 31.0002 47Z'
        fill='#EFEFEF'
      />
      <path
        d='M55.0501 55.1582H14.9494C14.505 55.1582 14.1448 55.4839 14.1448 55.8857V58.2721C14.1448 58.6738 14.505 58.9996 14.9494 58.9996H55.0501C55.4944 58.9996 55.8548 58.6739 55.8548 58.2721V55.8857C55.8548 55.4839 55.4944 55.1582 55.0501 55.1582Z'
        fill='#4BABB3'
      />
      <path
        opacity='0.05'
        d='M18.7473 58.2711V55.8848C18.7473 55.483 19.073 55.1572 19.4748 55.1572H14.8722C14.4705 55.1572 14.1448 55.4829 14.1448 55.8848V58.2711C14.1448 58.6729 14.4705 58.9986 14.8722 58.9986H19.4749C19.073 58.9986 18.7473 58.6729 18.7473 58.2711Z'
        fill='#313842'
      />
      <rect
        x='36.6995'
        y='35.6201'
        width='11.67'
        height='14.65'
        rx='0.5'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M43.2876 35.7207C43.2876 35.6865 43.2845 35.6531 43.2801 35.6201H41.786C41.7816 35.6531 41.7786 35.6865 41.7786 35.7207V42.2409H36.6995V43.75H41.7786V50.27H43.2876V43.75H48.3667V42.2409H43.2876V35.7207H43.2876Z'
        fill='#313842'
      />
    </SvgIcon>
  );
}

PropertyIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default React.memo(PropertyIcon);
