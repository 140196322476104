import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps
} from '@mui/x-date-pickers/DateTimePicker';

// @local
import toFormField from '../to-form-field';

export const DateTimePicker = (props: DateTimePickerProps<TextFieldProps, Date>) => {
  // React.useEffect(() => {
  //   if (!value) {
  //     onChange(new Date());
  //   }
  // }, [value]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDateTimePicker
        {...props}
        renderInput={(params) => <TextField {...params} />}
        // autoOk
        // margin='none'
        // format={dateFormat}
        // invalidDateMessage=''
        // inputVariant='outlined'
        // InputLabelProps={{
        //   shrink: value ? true : undefined // Solution from https://github.com/mui-org/material-ui-pickers/issues/890
        // }}
      />
    </LocalizationProvider>
  );
};

export default toFormField(DateTimePicker);
