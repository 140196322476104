// @mui
import { Stack, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
// @ld
import { Card } from '../card';

const ContactCardSkeleton = () => {
  return (
    <Stack p='20px' direction='row' component={Card} alignItems='center'>
      <Skeleton variant='circular' width={60} height={60} />
      <Box ml={2}>
        <Skeleton variant='text' width={120} height={25} />
        <Skeleton variant='text' width={120} height={16} />
      </Box>
    </Stack>
  );
};

export default ContactCardSkeleton;
