import styled from '@emotion/styled';
import MuiIconButton from '@mui/material/IconButton';
import { CustomIconButtonProps } from './types';

export const StyledIconButton = styled(MuiIconButton)<CustomIconButtonProps>`
  && {
    border-radius: ${({ variant }) => (variant === 'rounded' ? '10px' : '50%')};
    color: ${({ $color }) => $color || 'inherit'};
    background-color: ${({ $bgColor }) => $bgColor || 'transparent'};

    &:hover {
      opacity: 0.8;
      background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
    }
  }
`;
