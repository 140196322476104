import { Timestamp } from 'firebase/firestore/lite';
import { IEventAtRead, IEventAtWrite } from './event';
import { TNullableString } from './base';

type TMainContactDetails = {
  name: string;
  email: string;
  phoneNumber: string;
};

enum EPlanType {
  ADVISORY = 'advisory',
  VALUE_ADD = 'value-add',
  AFFILIATE = 'affiliate'
}

type TPlanDetails = {
  type: EPlanType;
  advisorSeats: number;
  profileSeats: number;
};

export enum EOrganisationStatus {
  PREP = 'prep',
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  DISABLED = 'disabled'
}

export interface IOrganisationWrite {
  name: string;
  logoURL: TNullableString;
  websiteURL: TNullableString;
  physicalAddress: TNullableString;
  registrationNumber: TNullableString;
  vatNumber: TNullableString;
  organisationType: string;
  billingAddress: TNullableString;
  mainContact: TMainContactDetails | null;
  plan: TPlanDetails | null;
  status: EOrganisationStatus;
  advisorSeatsUsed: number;
  profileSeatsUsed: number;
  averageERS: number;
}

export interface IOrganisationRead extends IOrganisationWrite {
  id: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
}

export interface IPublicOrganisationWrite {
  name: string;
  orgId: string;
  logoURL: string;
  created: IEventAtWrite;
  updated: IEventAtWrite;
}

export interface IPublicOrganisationRead extends IPublicOrganisationWrite {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
}
