import * as React from 'react';
import { Box, Button, ButtonProps, Stack } from '@mui/material';
import { IoAlertCircleOutline, IoClose } from 'react-icons/io5';
// @ld
import { colors } from '@ld/utils';
// @custom
import IconButton from '../icon-button';
import { Heading4, BodySmallText } from '../typography';
// @local
import { IconWrapper, ButtonWrapper, ActionWrapper, AlertBannerWrapper } from './styles';

type AlertBannerProps = {
  title: string;
  message: string | React.ReactNode;
  actionText: string;
  onClose?: () => void;
  persistent?: boolean;
  actionOptions: ButtonProps;
};

const AlertBanner = ({
  title,
  message,
  onClose,
  persistent,
  actionText,
  actionOptions
}: AlertBannerProps) => (
  <AlertBannerWrapper>
    <Box display={{ md: 'flex', sm: 'block' }} alignItems='center'>
      <Stack flex={1} direction='row'>
        <IconWrapper>
          <IoAlertCircleOutline />
        </IconWrapper>
        <Box flex={1}>
          <Heading4>{title}</Heading4>
          {typeof message === 'string' ? (
            <BodySmallText>{message}</BodySmallText>
          ) : (
            message
          )}
        </Box>
      </Stack>
      <ActionWrapper>
        <Button variant='text' id={`btn-${actionText}`} {...actionOptions}>
          {actionText}
        </Button>
      </ActionWrapper>
    </Box>
    {!persistent && (
      <ButtonWrapper>
        <IconButton
          size='small'
          onClick={onClose}
          aria-label='close'
          $bgColor={colors.grey3}
        >
          <IoClose size={20} color={colors.grey1} />
        </IconButton>
      </ButtonWrapper>
    )}
  </AlertBannerWrapper>
);

export default React.memo(AlertBanner);
