import { Category } from './types';

// Documents
const trustDeed = {
  typeId: '6546cda7',
  name: 'Trust deed'
};

const letterOfAuthority = {
  typeId: '02ea334d',
  name: 'Letter of authority'
};

const financialStatements = {
  typeId: '280064a2',
  name: 'Financial statements',
  multipleDocuments: true
};

const resolutions = {
  typeId: 'e2d53d16',
  name: 'Resolutions',
  multipleDocuments: true
};

// Category
const trusts: Category = {
  id: 'xLbiaqNUriZi3ErYMGwc',
  name: 'Trusts',
  shortDescription: 'Trust details including trustees and beneficiaries',
  description:
    'If you are either a trustee or beneficiary of a trust, your executor would need to amend the trust details to reflect your passing.',
  entity: {
    type: 'trust',
    multipleOwners: true,
    displayName: 'Trust',
    pluralDisplayName: 'Trusts',
    documents: [trustDeed, letterOfAuthority, financialStatements, resolutions]
  }
};

export default trusts;
