import { TNullableString } from './base';
import { EProfileRole } from './profiles';
import { IEventAtRead, IEventAtWrite } from './event';

export enum EContactType {
  PERSON = 'person',
  ORGANISATION = 'organisation'
}

export enum EContactRelationships {
  CHILD = 'child',
  SPOUSE = 'spouse',
  PARENT = 'parent',
  FRIEND = 'friend',
  ADVISOR = 'advisor',
  SIBLING = 'sibling',
  EXECUTOR = 'executor',
  EX_SPOUSE = 'ex-spouse',
  PET_GUARDIAN = 'pet_guardian',
  OTHER_FAMILY = 'other_family',
  GUARDIAN_OF_DEPENDENT = 'guardian_of_dependent'
}

export interface IContactWrite {
  name: string;
  profileId: string;
  type: EContactType;
  uid: TNullableString;
  email: TNullableString;
  photoURL: TNullableString;
  phoneNumber: TNullableString;
  isNotificationEmailSent: boolean;
  relationships: EContactRelationships[];
  notify?: boolean;
  role: Omit<EProfileRole, EProfileRole.OWNER>;
  created: IEventAtWrite;
  updated: IEventAtWrite;
}
export interface IContactRead extends IContactWrite {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
}
