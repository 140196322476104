import { TNullableString } from './base';
import { EProfileRole } from './profiles';
import { EContactRelationships } from './contacts';
import { IEventAtRead, IEventAtWrite } from './event';

export enum EProfileInviteStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected'
}

interface IAcceptedWithContactData {
  relationships: EContactRelationships[];
  role: Omit<EProfileRole, EProfileRole.OWNER>;
}
export type TAcceptedWrite = IAcceptedWithContactData & IEventAtWrite;

export type TAcceptedRead = IAcceptedWithContactData & IEventAtRead;

interface IRejectedWithReason {
  reason?: TNullableString;
}

export type TRejectedWrite = IRejectedWithReason & IEventAtWrite;

export type IRejectedRead = IRejectedWithReason & IEventAtRead;

export const profileInviteChannels = {
  internal: 'internal',
  third_party: 'third_party'
};

export type TProfileInviteChannel = keyof typeof profileInviteChannels;

export interface IProfileInviteWrite {
  profile: {
    id: string;
    name: string;
    email?: TNullableString;
    photoURL?: TNullableString;
    estateReadinessScore: number;
  };
  organisation?: {
    id: string;
    name: string;
    logoURL: TNullableString;
    websiteURL: TNullableString;
  };
  channel: TProfileInviteChannel;
  status: EProfileInviteStatus;
  created: IEventAtWrite;
  updated: IEventAtWrite;
  resent?: IEventAtWrite;
  rejected?: TRejectedWrite;
  accepted?: TAcceptedWrite;
}

export interface IProfileInviteRead extends IProfileInviteWrite {
  id: string;
  created: IEventAtRead;
  updated: IEventAtRead;
  resent?: IEventAtRead;
  rejected?: IRejectedRead;
  accepted?: TAcceptedRead;
}
