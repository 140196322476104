import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function DebtIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M23.1085 36.8201C22.828 36.8201 22.5586 36.663 22.4284 36.3936L18.5756 28.4154L14.723 36.3936C14.5418 36.7687 14.0909 36.9261 13.7154 36.7449C13.3402 36.5637 13.1829 36.1126 13.364 35.7373L17.8961 26.3521C18.022 26.0914 18.286 25.9258 18.5756 25.9258C18.8651 25.9258 19.1291 26.0914 19.255 26.3521L23.7872 35.7373C23.9683 36.1126 23.811 36.5637 23.4358 36.7449C23.3301 36.7959 23.2184 36.8201 23.1085 36.8201Z'
        fill='#EFEFEF'
      />
      <path
        d='M46.8945 26.789C46.7844 26.789 46.6727 26.7648 46.567 26.7137C46.1918 26.5324 46.0344 26.0813 46.2156 25.7061L50.7477 16.3209C50.8737 16.0602 51.1377 15.8945 51.4272 15.8945C51.7168 15.8945 51.9807 16.0602 52.1067 16.3209L56.6387 25.7061C56.8199 26.0813 56.6626 26.5324 56.2874 26.7137C55.912 26.8949 55.4609 26.7374 55.2798 26.3623L51.4271 18.3842L47.5744 26.3623C47.4444 26.6319 47.1751 26.789 46.8945 26.789Z'
        fill='#EFEFEF'
      />
      <path
        d='M36.8535 54.835H33.1458V11.8538C33.1458 10.83 33.9758 10 34.9997 10C36.0235 10 36.8535 10.83 36.8535 11.8538V54.835Z'
        fill='#313842'
      />
      <path
        d='M57.734 15.7953L13.3043 29.2073C12.3552 29.4938 11.3536 28.9567 11.0671 28.0075C10.7805 27.0584 11.3177 26.0569 12.2668 25.7703L56.6965 12.3583C57.6456 12.0718 58.6471 12.6089 58.9337 13.558C59.2202 14.5072 58.6831 15.5088 57.734 15.7953Z'
        fill='#313842'
      />
      <path
        d='M46.1339 56.4095H43.767C44.7584 56.4095 45.5621 55.6058 45.5621 54.6144C45.5621 53.623 44.7584 52.8193 43.767 52.8193H26.2359C25.2445 52.8193 24.4409 53.623 24.4409 54.6144C24.4409 55.6058 25.2446 56.4095 26.2359 56.4095H23.869C22.8776 56.4095 22.074 57.2132 22.074 58.2045C22.074 59.1959 22.8777 59.9995 23.869 59.9995H46.134C47.1254 59.9995 47.9291 59.1958 47.9291 58.2045C47.929 57.2132 47.1253 56.4095 46.1339 56.4095Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M46.1315 56.4095H43.7646C44.756 56.4095 45.5596 55.6058 45.5596 54.6144C45.5596 53.623 44.7559 52.8193 43.7646 52.8193H39.7405C40.7319 52.8193 41.5355 53.623 41.5355 54.6144C41.5355 55.6058 40.7318 56.4095 39.7405 56.4095H42.1074C43.0988 56.4095 43.9024 57.2132 43.9024 58.2045C43.9024 59.1959 43.0987 59.9995 42.1074 59.9995H46.1315C47.1229 59.9995 47.9265 59.1958 47.9265 58.2045C47.9265 57.2132 47.1229 56.4095 46.1315 56.4095Z'
        fill='#313842'
      />
      <path
        d='M43.7634 55.6553H39.8375C39.4207 55.6553 39.083 55.9931 39.083 56.4098C39.083 56.8265 39.4208 57.1643 39.8375 57.1643H43.7634C44.1802 57.1643 44.5179 56.8265 44.5179 56.4098C44.5179 55.9931 44.1801 55.6553 43.7634 55.6553Z'
        fill='#FEF3EF'
      />
      <path
        d='M36.0233 55.6553H26.233C25.8162 55.6553 25.4785 55.9931 25.4785 56.4098C25.4785 56.8265 25.8163 57.1643 26.233 57.1643H36.0233C36.4401 57.1643 36.7778 56.8265 36.7778 56.4098C36.7778 55.9931 36.4401 55.6553 36.0233 55.6553Z'
        fill='#FEF3EF'
      />
      <path
        d='M34.9998 27.15C38.6243 27.15 41.5626 24.2117 41.5626 20.5872C41.5626 16.9627 38.6243 14.0244 34.9998 14.0244C31.3753 14.0244 28.437 16.9627 28.437 20.5872C28.437 24.2117 31.3753 27.15 34.9998 27.15Z'
        fill='#F5B53F'
      />
      <path
        opacity='0.05'
        d='M35.0011 14.0244C34.2991 14.0244 33.6233 14.1358 32.989 14.3399C35.629 15.1896 37.5398 17.6647 37.5398 20.5872C37.5398 23.5097 35.6291 25.9849 32.989 26.8345C33.6232 27.0386 34.299 27.15 35.0011 27.15C38.6256 27.15 41.5639 24.2117 41.5639 20.5872C41.5639 16.9627 38.6256 14.0244 35.0011 14.0244Z'
        fill='#313842'
      />
      <path
        d='M34.9996 24.1324C36.9573 24.1324 38.5443 22.5454 38.5443 20.5877C38.5443 18.63 36.9573 17.043 34.9996 17.043C33.0419 17.043 31.4548 18.63 31.4548 20.5877C31.4548 22.5454 33.0419 24.1324 34.9996 24.1324Z'
        fill='#FEF3EF'
      />
      <path
        d='M22.9511 48.0974H14.1969C10.8563 48.0974 8.14819 45.3893 8.14819 42.0487C8.14819 38.7081 10.8563 36 14.1969 36H22.9511C26.2917 36 28.9998 38.7081 28.9998 42.0487C28.9997 45.3893 26.2916 48.0974 22.9511 48.0974Z'
        fill='#EE6338'
      />
      <path
        opacity='0.05'
        d='M22.9519 36H19.3269C22.6675 36 25.3756 38.7081 25.3756 42.0487C25.3756 45.3893 22.6675 48.0974 19.3269 48.0974H22.9519C26.2925 48.0974 29.0006 45.3893 29.0006 42.0487C29.0006 38.7081 26.2925 36 22.9519 36Z'
        fill='#313842'
      />
      <path
        d='M14.228 39.4219H13.0338C12.8533 39.4219 12.6802 39.4937 12.5526 39.6214C12.4252 39.7492 12.3537 39.9224 12.354 40.1029V40.1059V40.1061V44.2499C12.354 44.2905 12.3582 44.33 12.3651 44.3687C12.3771 44.5315 12.4469 44.6851 12.5634 44.8007C12.6908 44.9272 12.8628 44.9981 13.0423 44.9981H13.0448C13.0809 44.9979 13.9336 44.9947 14.2748 44.9887C15.6283 44.965 16.6106 43.7965 16.6106 42.21C16.6106 40.5424 15.6532 39.4219 14.228 39.4219ZM14.2509 43.6293C14.1256 43.6315 13.9233 43.6334 13.7197 43.6348C13.7182 43.1511 13.7165 42.5317 13.7165 42.2052C13.7165 41.9309 13.7155 41.2855 13.7146 40.7812H14.228C15.1775 40.7812 15.2513 41.8748 15.2513 42.2098C15.2512 42.9078 14.942 43.6173 14.2509 43.6293Z'
        fill='white'
      />
      <path
        d='M20.5477 41.7836C20.681 41.5429 20.7575 41.2666 20.7575 40.9725C20.7575 40.0464 20.004 39.293 19.0778 39.293H17.8156C17.4402 39.293 17.136 39.5973 17.136 39.9726V41.9734V41.9767V44.3178C17.136 44.4985 17.208 44.6718 17.3361 44.7995C17.4636 44.9263 17.6359 44.9974 17.8157 44.9974H17.8187C17.8187 44.9974 18.9786 44.9923 19.2984 44.9923C20.3181 44.9923 21.1477 44.1627 21.1477 43.143C21.1477 42.6052 20.9155 42.1218 20.5477 41.7836ZM19.0779 40.6524C19.2545 40.6524 19.3981 40.796 19.3981 40.9725C19.3981 41.1491 19.2545 41.2927 19.0779 41.2927C18.985 41.2927 18.8094 41.2932 18.6183 41.2939H18.4955V40.6524H19.0779ZM19.2984 43.6331C19.1475 43.6331 18.8105 43.6342 18.4954 43.6354V42.6538C18.5406 42.6536 18.5857 42.6534 18.63 42.6533H19.2984C19.5685 42.6533 19.7883 42.873 19.7883 43.1431C19.7883 43.4133 19.5685 43.6331 19.2984 43.6331Z'
        fill='white'
      />
      <path
        d='M24.1072 39.3125H21.7075C21.332 39.3125 21.0278 39.6168 21.0278 39.9922C21.0278 40.3676 21.3321 40.6718 21.7075 40.6718H22.2228V44.3373C22.2228 44.7127 22.5271 45.017 22.9025 45.017C23.2779 45.017 23.5821 44.7127 23.5821 44.3373V40.6718H24.1072C24.4826 40.6718 24.7868 40.3675 24.7868 39.9922C24.787 39.6168 24.4827 39.3125 24.1072 39.3125Z'
        fill='white'
      />
      <path
        d='M55.8003 38.0964H47.0475C43.7074 38.0964 40.9998 35.3887 40.9998 32.0487C40.9998 28.7086 43.7074 26.001 47.0475 26.001H55.8003C59.1403 26.001 61.848 28.7086 61.848 32.0487C61.848 35.3887 59.1403 38.0964 55.8003 38.0964Z'
        fill='#4BABB3'
      />
      <path
        opacity='0.05'
        d='M55.8019 26H52.1775C55.5176 26 58.2252 28.7077 58.2252 32.0477C58.2252 35.3878 55.5176 38.0954 52.1775 38.0954H55.8019C59.142 38.0954 61.8496 35.3878 61.8496 32.0477C61.8496 28.7077 59.142 26 55.8019 26Z'
        fill='#313842'
      />
      <path
        d='M53.2815 32.5456C53.1052 31.9415 52.6278 31.4863 52.0411 31.2789C51.6559 31.1421 50.5527 30.9361 50.7294 30.343C50.8721 29.8568 51.5334 29.9436 51.8577 30.1586C52.1333 30.4034 52.555 30.3845 52.8069 30.1129C53.062 29.8377 53.0458 29.4077 52.7706 29.1525C52.5252 28.9416 52.2371 28.7917 51.924 28.7055V28.5995C51.924 28.2242 51.6197 27.9199 51.2444 27.9199C50.8691 27.9199 50.5648 28.2242 50.5648 28.5995V28.7497C49.8347 28.9976 49.3482 29.7377 49.3472 30.4977C49.3481 31.7486 50.5921 32.206 51.5859 32.5596C51.9396 32.6857 52.0849 33.0196 51.9472 33.371C51.82 33.6979 51.4996 33.8581 51.1716 33.8585C50.8051 33.8586 50.563 33.8067 50.2478 33.5994C49.9342 33.3932 49.5128 33.4803 49.3066 33.7938C49.1005 34.1074 49.1875 34.5288 49.5011 34.7351C49.8288 34.9505 50.1749 35.1182 50.5648 35.1791V35.2992C50.5648 35.6745 50.8691 35.9788 51.2444 35.9788C51.6197 35.9788 51.924 35.6745 51.924 35.2992V35.1064C52.385 34.9516 52.7811 34.6321 53.0368 34.2187C53.332 33.7401 53.4411 33.0895 53.2815 32.5456Z'
        fill='white'
      />
    </SvgIcon>
  );
}

DebtIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default React.memo(DebtIcon);
