import * as R from 'rambda';

import type { IUserDocumentRead, IUserDocumentWrite } from '../types/documents';
import type { TGenericObject } from '../types/base';

export const prepareDocumentData = ({
  typeId,
  entityId,
  fileSize,
  profileId,
  categoryId,
  formValues
}: {
  typeId: string;
  fileSize: number;
  profileId: string;
  entityId?: string;
  categoryId: string;
  formValues: TGenericObject;
}) => {
  const formData = R.pick(
    [
      'entityId',
      'expiresAt',
      'categoryId',
      'displayName',
      'policyNumber',
      'institutionName',
      'lastUpdatedAt',
      'physicalStorageLocation'
    ],
    formValues
  );
  const documentData: Omit<IUserDocumentWrite, 'url' | 'fileName'> = {
    typeId,
    fileSize,
    profileId,
    categoryId,
    ...formData
  };

  if (entityId) {
    documentData.entityId = entityId;
  }

  return documentData;
};

export const toEditableDocument = (values?: IUserDocumentRead) =>
  R.pick(
    [
      'displayName',
      'policyNumber',
      'institutionName',
      'physicalStorageLocation',
      'expiresAt',
      'lastUpdatedAt'
    ],
    values
  );
