import * as React from 'react';
import Box from '@mui/material/Box';
import { TCategory, TGenericObject } from '@ld/utils';
// @local
import { ListWrapper } from '../styles';
import { documentOccurrences } from '../utils';
import { Heading5, BodySmallText } from '../../typography';

type EntityCategoryGapProps = {
  category: TCategory;
  estateReadinessGap: TGenericObject;
};

const EntityCategoryGap = ({ category, estateReadinessGap }: EntityCategoryGapProps) => {
  const categoryGap = estateReadinessGap[category.id] as TGenericObject;
  if (categoryGap) {
    const docOccurrences = documentOccurrences(categoryGap);
    const hasBusinesses = Object.keys(docOccurrences).length > 0;
    return (
      <Box mb='20px'>
        <Heading5>{category.name}</Heading5>
        <ListWrapper>
          {hasBusinesses ? (
            category.entity?.documents
              ?.filter((d) => Boolean(docOccurrences[d.typeId]))
              .map((d) => (
                <li key={d.name}>
                  <BodySmallText>
                    {docOccurrences[d.typeId]} x {d.name}
                  </BodySmallText>
                </li>
              ))
          ) : (
            <li>
              <BodySmallText>
                No {category.entity?.pluralDisplayName?.toLocaleLowerCase()} added
              </BodySmallText>
            </li>
          )}
        </ListWrapper>
      </Box>
    );
  }
  return null;
};

export default React.memo(EntityCategoryGap);
