import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function TaxesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M56.7016 14.3842V18.7245H48.021V14.3842C48.021 13.2331 48.4783 12.1291 49.2922 11.3152C50.1062 10.5012 51.2102 10.0439 52.3613 10.0439C53.5124 10.0439 54.6164 10.5012 55.4303 11.3152C56.2443 12.1291 56.7016 13.2331 56.7016 14.3842V14.3842Z'
        fill='#E5E6E6'
      />
      <path
        d='M52.3612 10.0439C51.2101 10.0439 50.1061 10.5012 49.2922 11.3152C48.4782 12.1291 48.0209 13.2331 48.0209 14.3842V59.9571H12.2136V14.3842C12.2136 13.2331 12.6709 12.1291 13.4849 11.3152C14.2988 10.5012 15.4028 10.0439 16.5539 10.0439H52.3612Z'
        fill='#E5E6E6'
      />
      <path
        d='M52.3612 10.0437C51.2101 10.0437 50.1061 10.501 49.2922 11.315C48.4782 12.1289 48.0209 13.2329 48.0209 14.384V57.7868C39.0998 57.7868 30.5441 54.2429 24.2359 47.9347C17.9277 41.6265 14.3838 33.0708 14.3838 24.1497C14.3838 10.9444 14.2536 11.5737 14.7635 10.4344C15.6859 9.87014 11.3673 10.0437 52.3612 10.0437V10.0437Z'
        fill='#EFEFEF'
      />
      <path
        d='M32.83 49.1057H17.639C17.3512 49.1057 17.0753 48.9914 16.8718 48.7879C16.6683 48.5844 16.554 48.3084 16.554 48.0206C16.554 47.7328 16.6683 47.4568 16.8718 47.2534C17.0753 47.0499 17.3512 46.9355 17.639 46.9355H32.83C33.1178 46.9355 33.3938 47.0499 33.5973 47.2534C33.8007 47.4568 33.9151 47.7328 33.9151 48.0206C33.9151 48.3084 33.8007 48.5844 33.5973 48.7879C33.3938 48.9914 33.1178 49.1057 32.83 49.1057Z'
        fill='#313842'
      />
      <path
        d='M32.83 54.5315H17.639C17.3512 54.5315 17.0753 54.4171 16.8718 54.2137C16.6683 54.0102 16.554 53.7342 16.554 53.4464C16.554 53.1586 16.6683 52.8826 16.8718 52.6791C17.0753 52.4756 17.3512 52.3613 17.639 52.3613H32.83C33.1178 52.3613 33.3938 52.4756 33.5973 52.6791C33.8007 52.8826 33.9151 53.1586 33.9151 53.4464C33.9151 53.7342 33.8007 54.0102 33.5973 54.2137C33.3938 54.4171 33.1178 54.5315 32.83 54.5315Z'
        fill='#313842'
      />
      <path
        d='M32.83 43.6809H17.639C17.3512 43.6809 17.0753 43.5666 16.8718 43.3631C16.6683 43.1596 16.554 42.8836 16.554 42.5958C16.554 42.308 16.6683 42.032 16.8718 41.8286C17.0753 41.6251 17.3512 41.5107 17.639 41.5107H32.83C33.1178 41.5107 33.3938 41.6251 33.5973 41.8286C33.8007 42.032 33.9151 42.308 33.9151 42.5958C33.9151 42.8836 33.8007 43.1596 33.5973 43.3631C33.3938 43.5666 33.1178 43.6809 32.83 43.6809Z'
        fill='#313842'
      />
      <path
        d='M26.3196 38.2551H17.639C17.3512 38.2551 17.0753 38.1408 16.8718 37.9373C16.6683 37.7338 16.554 37.4578 16.554 37.17C16.554 36.8823 16.6683 36.6063 16.8718 36.4028C17.0753 36.1993 17.3512 36.085 17.639 36.085H26.3196C26.6074 36.085 26.8834 36.1993 27.0868 36.4028C27.2903 36.6063 27.4046 36.8823 27.4046 37.17C27.4046 37.4578 27.2903 37.7338 27.0868 37.9373C26.8834 38.1408 26.6074 38.2551 26.3196 38.2551Z'
        fill='#313842'
      />
      <path
        d='M48.021 55.6162C53.4144 55.6162 57.7866 51.244 57.7866 45.8506C57.7866 40.4572 53.4144 36.085 48.021 36.085C42.6276 36.085 38.2554 40.4572 38.2554 45.8506C38.2554 51.244 42.6276 55.6162 48.021 55.6162Z'
        fill='#ECA82B'
      />
      <path
        d='M57.7867 45.8516C57.7779 47.6477 57.2789 49.4073 56.3435 50.9406C54.473 52.0689 52.2781 52.5374 50.1099 52.2713C47.9416 52.0051 45.9252 51.0197 44.3831 49.4725C42.8409 47.9254 41.862 45.9058 41.6029 43.7367C41.3438 41.5676 41.8194 39.3743 42.9538 37.5074C44.4344 36.6087 46.1275 36.1199 47.8593 36.0912C49.5911 36.0625 51.2994 36.4949 52.809 37.3442C54.3186 38.1934 55.5752 39.4288 56.4499 40.9238C57.3246 42.4188 57.786 44.1195 57.7867 45.8516V45.8516Z'
        fill='#F5B53F'
      />
      <path
        d='M25.2344 20.8938H18.724C18.4362 20.8938 18.1602 20.7795 17.9567 20.576C17.7532 20.3725 17.6389 20.0965 17.6389 19.8087C17.6389 19.5209 17.7532 19.2449 17.9567 19.0414C18.1602 18.838 18.4362 18.7236 18.724 18.7236H25.2344C25.5222 18.7236 25.7982 18.838 26.0017 19.0414C26.2052 19.2449 26.3195 19.5209 26.3195 19.8087C26.3195 20.0965 26.2052 20.3725 26.0017 20.576C25.7982 20.7795 25.5222 20.8938 25.2344 20.8938Z'
        fill='#EE6338'
      />
      <path
        d='M20.894 28.4893V19.8087C20.894 19.5209 21.0084 19.2449 21.2119 19.0414C21.4153 18.838 21.6913 18.7236 21.9791 18.7236C22.2669 18.7236 22.5429 18.838 22.7464 19.0414C22.9499 19.2449 23.0642 19.5209 23.0642 19.8087V28.4893C23.0642 28.777 22.9499 29.053 22.7464 29.2565C22.5429 29.46 22.2669 29.5743 21.9791 29.5743C21.6913 29.5743 21.4153 29.46 21.2119 29.2565C21.0084 29.053 20.894 28.777 20.894 28.4893Z'
        fill='#EE6338'
      />
      <path
        d='M32.895 28.8698L30.6597 22.9019L28.4245 28.8698C28.3238 29.1403 28.1197 29.3598 27.8572 29.4798C27.5947 29.5999 27.2953 29.6107 27.0248 29.51C26.7543 29.4093 26.5348 29.2052 26.4148 28.9427C26.2947 28.6802 26.2838 28.3808 26.3846 28.1103L29.6398 19.4297C29.7158 19.2202 29.8545 19.0393 30.037 18.9114C30.2195 18.7835 30.4369 18.7148 30.6597 18.7148C30.8826 18.7148 31.1 18.7835 31.2825 18.9114C31.465 19.0393 31.6037 19.2202 31.6797 19.4297L34.9349 28.1103C35.0356 28.3808 35.0248 28.6802 34.9047 28.9427C34.7847 29.2052 34.5652 29.4093 34.2947 29.51C34.0242 29.6107 33.7248 29.5999 33.4623 29.4798C33.1998 29.3598 32.9957 29.1403 32.895 28.8698V28.8698Z'
        fill='#EE6338'
      />
      <path
        d='M32.8299 27.4045H28.4896C28.2018 27.4045 27.9258 27.2902 27.7223 27.0867C27.5189 26.8832 27.4045 26.6072 27.4045 26.3194C27.4045 26.0317 27.5189 25.7557 27.7223 25.5522C27.9258 25.3487 28.2018 25.2344 28.4896 25.2344H32.8299C33.1177 25.2344 33.3937 25.3487 33.5971 25.5522C33.8006 25.7557 33.915 26.0317 33.915 26.3194C33.915 26.6072 33.8006 26.8832 33.5971 27.0867C33.3937 27.2902 33.1177 27.4045 32.8299 27.4045Z'
        fill='#EE6338'
      />
      <path
        d='M41.5105 29.5745C40.5773 29.5745 40.7726 29.4334 36.2045 20.2971C36.0992 20.0442 36.0934 19.7608 36.1884 19.5038C36.2834 19.2468 36.4721 19.0353 36.7166 18.9117C36.9611 18.788 37.2433 18.7615 37.5065 18.8373C37.7698 18.9132 37.9946 19.0858 38.1359 19.3206L42.4762 28.0011C42.5594 28.1663 42.599 28.35 42.5913 28.5348C42.5836 28.7196 42.5287 28.8994 42.4319 29.057C42.3352 29.2146 42.1997 29.3449 42.0385 29.4355C41.8772 29.5261 41.6954 29.5739 41.5105 29.5745Z'
        fill='#EE6338'
      />
      <path
        d='M37.1702 29.5745C36.9852 29.5739 36.8034 29.5261 36.6422 29.4355C36.4809 29.3449 36.3454 29.2146 36.2487 29.057C36.1519 28.8994 36.0971 28.7196 36.0893 28.5348C36.0816 28.35 36.1212 28.1663 36.2045 28.0011L40.5447 19.3206C40.686 19.0858 40.9108 18.9132 41.1741 18.8373C41.4374 18.7615 41.7196 18.788 41.9641 18.9117C42.2086 19.0353 42.3973 19.2468 42.4922 19.5038C42.5872 19.7608 42.5815 20.0442 42.4762 20.2971C37.7344 29.7915 38.0816 29.5745 37.1702 29.5745Z'
        fill='#EE6338'
      />
      <path
        d='M45.0336 44.8544C44.6397 44.8544 44.2547 44.7376 43.9271 44.5187C43.5996 44.2999 43.3443 43.9888 43.1936 43.6249C43.0429 43.261 43.0034 42.8605 43.0803 42.4742C43.1571 42.0878 43.3468 41.733 43.6253 41.4544C43.9039 41.1759 44.2587 40.9862 44.6451 40.9094C45.0314 40.8325 45.4319 40.872 45.7958 41.0227C46.1597 41.1734 46.4708 41.4287 46.6896 41.7562C46.9085 42.0838 47.0253 42.4688 47.0253 42.8627C47.0253 43.391 46.8154 43.8975 46.4419 44.271C46.0684 44.6445 45.5619 44.8544 45.0336 44.8544Z'
        fill='white'
      />
      <path
        d='M51.0085 50.83C50.6146 50.83 50.2295 50.7132 49.902 50.4943C49.5745 50.2755 49.3192 49.9644 49.1685 49.6005C49.0177 49.2366 48.9783 48.8361 49.0551 48.4498C49.132 48.0634 49.3216 47.7086 49.6002 47.43C49.8787 47.1515 50.2336 46.9618 50.6199 46.8849C51.0063 46.8081 51.4067 46.8475 51.7707 46.9983C52.1346 47.149 52.4456 47.4043 52.6645 47.7318C52.8833 48.0593 53.0001 48.4444 53.0001 48.8383C53.0001 49.3665 52.7903 49.8731 52.4168 50.2466C52.0433 50.6201 51.5367 50.83 51.0085 50.83Z'
        fill='white'
      />
      <path
        d='M44.8342 50.8307C44.6459 50.8336 44.4605 50.783 44.2998 50.6848C44.139 50.5866 44.0094 50.4448 43.9259 50.2759C43.8425 50.107 43.8087 49.9179 43.8285 49.7305C43.8483 49.5431 43.9208 49.3652 44.0376 49.2174L50.4108 41.2509C50.5759 41.0396 50.8182 40.9025 51.0843 40.8698C51.3504 40.8372 51.6186 40.9115 51.8299 41.0766C52.0412 41.2417 52.1782 41.4839 52.2109 41.75C52.2436 42.0162 52.1692 42.2844 52.0042 42.4956C45.1529 51.0398 45.5014 50.8307 44.8342 50.8307Z'
        fill='white'
      />
    </SvgIcon>
  );
}

TaxesIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default React.memo(TaxesIcon);
