import styled from '@emotion/styled';
import MuiCircularProgress, {
  CircularProgressProps
} from '@mui/material/CircularProgress';
import MuiChip from '@mui/material/Chip';
// @ld
import { colors } from '@ld/utils';

export const RingBackground = styled(MuiCircularProgress)`
  && {
    color: ${colors.grey4};
  }
`;

type ValueRingProps = {
  stroke: string;
} & CircularProgressProps;

export const ValueRing = styled(MuiCircularProgress)<ValueRingProps>`
  && {
    position: absolute;
    left: 0;

    .MuiLinearProgress-root {
      border-radius: 30px;
    }

    .MuiCircularProgress-circle {
      stroke-linecap: round;
      stroke: ${(props) => props.stroke};
    }
  }
`;

export const Chip = styled(MuiChip)`
  && {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`;
