import * as React from 'react';

// @ld
import { Snackbar, TSnackbarOptions } from '@ld/core';

const SnackbarContext = React.createContext<{
  closeSnackbar: () => void;
  showSnackbar: (options: TSnackbarOptions) => void;
}>({ showSnackbar: () => null, closeSnackbar: () => null });

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [snackbarOptions, setSnackbarOptions] = React.useState<TSnackbarOptions>();

  const showSnackbar = React.useCallback(
    (options: TSnackbarOptions) => setSnackbarOptions({ ...options, open: true }),
    []
  );

  const closeSnackbar = React.useCallback(
    () => setSnackbarOptions({ ...snackbarOptions, open: false }),
    []
  );

  return (
    <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar }}>
      {children}
      <Snackbar {...snackbarOptions} onClose={closeSnackbar} />
    </SnackbarContext.Provider>
  );
};

SnackbarContext.displayName = 'SnackbarContext';

export default SnackbarContext;
