import { Box, Stack, Skeleton } from '@mui/material';

const FieldValueSkeleton = () => (
  <Box my='15px'>
    <Skeleton variant='text' width={100} height={16} />
    <Stack direction='row' alignItems='center' spacing={2}>
      <Skeleton variant='text' width={50} height={24} />
      <Skeleton variant='text' width={30} height={24} />
      <Skeleton variant='text' width={80} height={24} />
    </Stack>
  </Box>
);

export default FieldValueSkeleton;
