// @mui
import { Box, CircularProgressProps } from '@mui/material';
// @ld
import { colors } from '@ld/utils';
//@local
import { SubText } from '../typography';
import { RingBackground, ValueRing } from './styles';

export const ringProps: CircularProgressProps = {
  variant: 'determinate',
  thickness: 5,
  size: 50
};

const ProgressRing = ({ value }: { value?: number }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <RingBackground {...ringProps} value={100} />
      <ValueRing {...ringProps} value={value} stroke={colors.green1} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <SubText weight='bold' color={colors.green1}>
          {value}%
        </SubText>
      </Box>
    </Box>
  );
};

export default ProgressRing;
