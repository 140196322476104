import { colors } from '@ld/utils';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type Variant = 'info' | 'success' | 'warning';
type ContainerProps = {
  $variant?: Variant;
};

export const getStyle = (variant?: Variant) => {
  switch (variant) {
    case 'warning':
      return css`
        background-color: ${colors.orange1};
      `;
    case 'success':
      return css`
        background-color: ${colors.green1};
      `;
    default:
      return css`
        background-color: ${colors.black1};
      `;
  }
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 12px 16px;
  width: 100%;
  min-width: 300px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 15px 30px rgba(38, 50, 56, 0.1);
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
  ${(props) => getStyle(props.$variant)};
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 170%;
`;
