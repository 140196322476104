import { TGenericObject } from '@ld/utils';

export const documentOccurrences = (categoryGap: TGenericObject = {}) => {
  const occurences: Record<string, number> = {};
  // flatten
  const flatDocTypes: string[] = [];
  Object.keys(categoryGap).forEach((entityId) => {
    const curr = categoryGap[entityId] as TGenericObject;
    flatDocTypes.push(...Object.keys(curr || {}));
  });

  flatDocTypes.forEach((docTypeId) => {
    occurences[docTypeId] = (occurences[docTypeId] || 0) + 1;
  });

  return occurences;
};
