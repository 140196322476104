import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function PeopleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M23.5548 9.83592C23.3831 12.2189 21.6154 14.0547 19.6876 14.0547C17.7599 14.0547 15.9892 12.2195 15.8205 9.83592C15.6447 7.35683 17.3656 5.61719 19.6876 5.61719C22.0097 5.61719 23.7306 7.40195 23.5548 9.83592Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.6872 17.8125C15.8687 17.8125 12.1966 19.7092 11.2767 23.4029C11.1548 23.8916 11.4613 24.375 11.9634 24.375H27.4116C27.9138 24.375 28.2185 23.8916 28.0983 23.4029C27.1784 19.65 23.5064 17.8125 19.6872 17.8125Z'
        strokeMiterlimit='10'
      />
      <path
        d='M11.7197 10.8949C11.5825 12.798 10.154 14.2969 8.6142 14.2969C7.07436 14.2969 5.6435 12.7986 5.50874 10.8949C5.3687 8.91503 6.75912 7.5 8.6142 7.5C10.4693 7.5 11.8597 8.95136 11.7197 10.8949Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0704 17.9287C11.0128 17.4441 9.84791 17.2578 8.61334 17.2578C5.56648 17.2578 2.63094 18.7725 1.89559 21.7226C1.79891 22.1129 2.04383 22.499 2.44461 22.499H9.0235'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </SvgIcon>
  );
}
PeopleIcon.defaultProps = {
  viewBox: '0 0 30 30'
};
export default React.memo(PeopleIcon);
