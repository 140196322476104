import * as React from 'react';
// @ld
import { formatDate } from '@ld/utils';
// @custom
import FieldValue from '../../field-value';
import { Heading4 } from '../../typography';
import { EntityDetailsProps } from './types';

const MONTH_YEAR_FORMAT = 'MMMM yyyy';

const EmployerDetails = ({ entity }: EntityDetailsProps) => {
  const startDate = formatDate(entity.startDate, MONTH_YEAR_FORMAT);
  const endDate = formatDate(entity.endDate, MONTH_YEAR_FORMAT);
  return (
    <>
      <FieldValue value={entity.jobTitle} label='Job title' />
      <FieldValue
        value={entity.currentlyWorkingHere ? 'Yes' : 'No'}
        label="I'm currently working for this employer"
      />
      <FieldValue value={startDate} label='Start date' />
      {!entity.currentlyWorkingHere && <FieldValue value={endDate} label='End date' />}
      <Heading4>Contact person</Heading4>
      <FieldValue value={entity.contactPersonName} label='Full name' />
      <FieldValue label='Email address' value={entity.contactPersonEmail} />
      <FieldValue value={entity.contactPersonPhoneNumber} label='Phone number' />
    </>
  );
};

export default React.memo(EmployerDetails);
