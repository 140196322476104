//@local
import { Chip } from './styles';
import { getErsConfig, TErsConfig } from './utils';

type ERSChipProps = {
  value?: number;
  ersConfig?: TErsConfig;
};

const ERSChip = ({ value, ersConfig }: ERSChipProps) => {
  let config = ersConfig;
  if (!config) {
    config = getErsConfig(value);
  }
  return (
    <Chip
      style={{
        color: config.color,
        background: config.backgroundColor
      }}
      label={config.category}
    />
  );
};

export default ERSChip;
