import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps
} from '@mui/x-date-pickers/DatePicker';

// @local
import toFormField from '../to-form-field';

export const DatePicker = ({
  fullWidth,
  inputFormat = 'dd-MM-yyyy',
  ...rest
}: DatePickerProps<TextFieldProps, Date> & {
  fullWidth?: boolean;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        inputFormat={inputFormat}
        {...rest}
        renderInput={(params) => <TextField fullWidth={fullWidth} {...params} />}
      />
    </LocalizationProvider>
  );
};

export default toFormField(DatePicker);
