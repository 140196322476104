type Node = {
  data: any;
  next: Node | null;
};

class Queue<T> {
  private head: Node | null;
  private tail: Node | null;
  public size: number;

  constructor() {
    this.head = null;
    this.tail = null;
    this.size = 0;
  }

  enqueue(data: T) {
    if (!data) return;

    const newNode = { data, next: null };
    if (this.head === null) {
      this.head = newNode;
      this.tail = newNode;
      this.size = ++this.size;
      return;
    }

    if (this.tail) {
      this.tail.next = newNode;
      this.tail = this.tail.next;
      this.size = ++this.size;
    }
  }

  dequeue(): T | null {
    if (this.head === null) {
      return null;
    }
    const currData = this.head.data;
    this.head = this.head.next;
    this.size = --this.size;
    return currData;
  }

  toArray(): T[] {
    const arr: T[] = [];
    if (this.head === null) {
      return arr;
    }

    // traverse the list
    let curr = this.head;
    while (curr.next) {
      arr.push(curr.data);
      curr = curr.next;
    }
    arr.push(curr.data);

    return arr;
  }
}

export default Queue;
