import * as React from 'react';
// @ld
import { formatDate } from '@ld/utils';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const TaxDetails = ({ entity }: EntityDetailsProps) => {
  const dateYear = formatDate(entity?.fiscalYear, 'yyyy');
  return (
    <>
      <FieldValue value={dateYear} label='Fiscal Year' />
    </>
  );
};

export default React.memo(TaxDetails);
