import * as React from 'react';
import { formatDate } from '@ld/utils';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const MONTH_YEAR_FORMAT = 'MMMM yyyy';

const QualificationDetails = ({ entity }: EntityDetailsProps) => {
  const startDate = formatDate(entity.startDate, MONTH_YEAR_FORMAT);
  const endDate = formatDate(entity.endDate, MONTH_YEAR_FORMAT);
  return (
    <>
      <FieldValue value={entity.institutionName} label='Institution name' />
      <FieldValue
        value={entity.currentlyAttendingHere ? 'Yes' : 'No'}
        label="I'm currently attending here"
      />
      <FieldValue value={startDate} label='Start date' />
      {!entity.currentlyAttendingHere && <FieldValue value={endDate} label='End date' />}
    </>
  );
};

export default React.memo(QualificationDetails);
