import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// @custom
import useUser from 'hooks/use-user';
import * as segment from 'utils/segment';

const Segment = () => {
  const { user } = useUser();
  const location = useLocation();

  useEffect(() => {
    segment.page();
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      segment.identify(user.uid, {
        name: user.name,
        email: user.email
      });
    }
  }, [user]);

  return null;
};

export default Segment;
