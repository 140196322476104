import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function ShieldIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d='M55.3113 17.8125C55.3504 17.8125 35.5067 10.1953 34.9988 10C34.6083 10.1562 14.6473 17.8125 14.6863 17.8125V32.1874C14.6863 42.1484 17.5379 55.1562 34.9988 59.9999C52.4207 55.1561 55.2723 41.9139 55.2723 32.1874C55.3113 27.2656 55.3113 17.8125 55.3113 17.8125Z'
        fill='#EFEFEF'
      />
      <path
        d='M51.3674 20.5078C51.4065 20.5078 35.3909 14.0625 34.9612 13.9062C34.6096 14.0235 18.5159 20.2344 18.555 20.1953V32.2656C18.555 40.6249 20.1957 52.0312 34.9612 56.0937C49.6878 52.0312 51.3674 40.4296 51.3674 32.2656C51.4065 28.125 51.4065 20.5078 51.3674 20.5078Z'
        fill='#4BABB3'
      />
      <path
        opacity='0.05'
        d='M14.6875 32.1875C14.6875 42.1485 17.5391 55.1563 35 60V10C34.2578 10.2734 14.6875 17.8125 14.6875 17.8125C14.6875 17.8125 14.6876 32.1875 14.6875 32.1875Z'
        fill='#313842'
      />
      <path
        d='M42.7345 31.1729C42.6172 30.7824 42.2657 30.5089 41.8361 30.4308L37.7736 29.8448L36.0158 26.251C35.8205 25.8604 35.4298 25.626 35.0001 25.626C34.5705 25.626 34.1798 25.8603 33.9845 26.251L32.2267 29.8448L28.1642 30.4308C27.7345 30.5089 27.3829 30.7824 27.2658 31.1729C27.1487 31.5635 27.2267 31.9933 27.5393 32.2667L30.508 35.1964L29.8049 39.2589C29.7267 39.6494 29.9221 40.0792 30.2736 40.3136C30.4689 40.4308 30.7033 40.5089 30.9376 40.5089C31.1329 40.5089 31.3282 40.4698 31.4845 40.3916L35.0392 38.5166L38.5939 40.3916C38.7501 40.4698 38.9455 40.5089 39.1408 40.5089C39.3752 40.5089 39.6095 40.4307 39.8048 40.3136C40.1564 40.0792 40.3517 39.6495 40.2736 39.2589L39.5705 35.1964L42.5392 32.2667C42.7735 31.9542 42.8907 31.5635 42.7345 31.1729Z'
        fill='#F5B53F'
      />
    </SvgIcon>
  );
}

ShieldIcon.defaultProps = {
  viewBox: '0 0 70 70'
};

export default React.memo(ShieldIcon);
