// @mui
import Box from '@mui/material/Box';
// @ld
import { config, ShieldAltIcon, SubText, ExternalLink } from '@ld/core';
// @custom
import { Card, IconWrapper, Heading } from './styles';

const DataSecurity = () => (
  <Card>
    <IconWrapper>
      <ShieldAltIcon />
    </IconWrapper>
    <Box>
      <Heading>Data security</Heading>
      <SubText>
        Keeping your data safe from day 1.{' '}
        <ExternalLink
          target='_blank'
          color='secondary'
          href={`${config.websiteUrl}/security`}
        >
          Here&apos;s how
        </ExternalLink>
      </SubText>
    </Box>
  </Card>
);

export default DataSecurity;
