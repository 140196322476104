import { Category } from './types';
// Category
const backAccounts: Category = {
  id: 'yiDrYsMKVRrS2Cb6RtUU',
  name: 'Banking',
  shortDescription: 'Bank accounts and credit card account details',
  description:
    'Your bank accounts and credit card accounts get listed here. These accounts will need to be closed, and all monies transferred into your estate bank account which will be set up by your executor. Also, this forms part of the assets & liabilities of your estate and is used to calculate the various costs associated with winding up your estate.',
  entity: {
    type: 'bank_account',
    multipleOwners: true,
    displayName: 'Bank account',
    pluralDisplayName: 'Bank accounts'
  }
};

export default backAccounts;
