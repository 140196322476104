import { Category } from './types';

// Documents
const ownersManual = {
  typeId: 'de1580ae',
  name: 'Owners manual'
};

const firearmLicence = {
  typeId: '553968b8',
  name: 'Firearm licence',
  metadata: [
    {
      type: 'date',
      disablePast: true,
      name: 'expiresAt',
      label: 'Expiry date'
    }
  ]
};

// Category
const firearms: Category = {
  id: '4YU0CmCtpDyjHjTFbGKz',
  name: 'Firearms',
  shortDescription: 'Firearm details and licences',
  description:
    'If you are an owner of a firearm, licence documents are imperative to ensure that the firearm is kept legally safe should you be incapacitated or pass away.  ',
  entity: {
    type: 'firearm',
    displayName: 'Firearm',
    pluralDisplayName: 'Firearms',
    documents: [ownersManual, firearmLicence]
  }
};

export default firearms;

export const firearmsDocuments = {
  ownersManual,
  firearmLicence
};
