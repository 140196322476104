import { Category } from './types';

// Documents
const registrationCertificate = {
  typeId: '9a683b5b',
  name: 'Registration certificate'
};

const financeDocuments = {
  typeId: 'e3270ffc',
  name: 'Finance documents'
};

const licenceDisk = {
  typeId: '70e40206',
  name: 'Licence disk',
  metadata: [
    {
      type: 'date',
      disablePast: true,
      name: 'expiresAt',
      label: 'Expiry date'
    }
  ]
};

const carInsurance = {
  typeId: '4685a31e',
  name: 'Car insurance'
};

// Category
const vehicles: Category = {
  id: 'gbsL6HdrsSTWnWATcUSL',
  name: 'Vehicles',
  shortDescription: 'Details and documents relating to vehicles you own',
  description:
    "Boats, planes, and your Ferrari's details go here - This information is needed when transferring to an heir or when processed as a sale within your estate. Also, this forms part of the assets & liabilities of your estate and is used to calculate the various costs associated with winding up your estate.",
  entity: {
    type: 'vehicle',
    multipleOwners: true,
    displayName: 'Vehicle',
    pluralDisplayName: 'Vehicles',
    documents: [registrationCertificate, financeDocuments, licenceDisk, carInsurance]
  }
};

export default vehicles;

export const vehiclesDocuments = {
  registrationCertificate,
  financeDocuments,
  licenceDisk,
  carInsurance
};
