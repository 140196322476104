import * as React from 'react';
import { useHistory } from 'react-router-dom';
// @custom
import IdleTimer from './idle-timer';
import useUser from 'hooks/use-user';
import { useSignOut } from 'api/auth';
import Snackbar from 'components/snackbar';
import { isDebugEnv, isLocalEnv } from 'config';

const SECONDS_OF_INACTIVITY = isLocalEnv || isDebugEnv ? 30 * 60 : 5 * 60; // 5 minutes * 60 seconds;

const AppIdleTimer = () => {
  const history = useHistory();
  const { user } = useUser();
  const [secondsLeft, setSecondsLeft] = React.useState(SECONDS_OF_INACTIVITY);

  const { handleSignOut } = useSignOut({
    onSuccess: () => {
      history.replace('/?auto=true');
    }
  });

  const handleTimeoutSignOut = () => {
    if (user) {
      handleSignOut();
    }
  };

  React.useEffect(() => {
    if (user) {
      const timer = new IdleTimer({
        timeout: SECONDS_OF_INACTIVITY,
        onTimeout: handleTimeoutSignOut,
        onTimerUpdate: (seconds: number) => setSecondsLeft(seconds)
      });

      return () => {
        timer.cleanUp();
      };
    }
    return () => null;
  }, [user]);

  return (
    <Snackbar
      variant='warning'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={secondsLeft > 0 && secondsLeft <= 10}
      message={`${secondsLeft.toFixed()} seconds left before auto logout`}
    />
  );
};

export default AppIdleTimer;
