import * as React from 'react';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const TrustDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={entity.trustRegNumber} label='Registration number' />
      <FieldValue value={entity.isTrustee ? 'Yes' : 'No'} label='Is a trustee' />
      <FieldValue value={entity.isBeneficiary ? 'Yes' : 'No'} label='Is a beneficiary' />
    </>
  );
};

export default React.memo(TrustDetails);
