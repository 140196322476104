import { getAuth } from 'firebase/auth';
import { UseMutationOptions } from '@tanstack/react-query';
import { query, where, FirestoreError } from 'firebase/firestore/lite';
// @ld
import {
  EProfileRole,
  EProfileType,
  IProfileRead,
  IProfileWrite,
  EProfileStatus,
  TNullableString
} from '@ld/utils';
// @custom
import getEventAt from 'utils/get-event-at';
// @local
import { getCollectionRef } from './utils/refs';
import useCreateItem from './utils/use-create-item';
import { itemQueryKey } from './utils/use-get-item';

export const PROFILES = 'profiles';

export const profileQueryKey = (id?: string) => itemQueryKey(PROFILES, id);

export const useCreateProfile = (
  options?: UseMutationOptions<IProfileRead, FirestoreError, IProfileWrite>
) => {
  const { handleCreate, ...rest } = useCreateItem<IProfileWrite, IProfileRead>(
    PROFILES,
    options
  );
  const handleCreateProfile = ({
    email,
    ...rest
  }: {
    name: string;
    email: TNullableString;
    status: EProfileStatus;
    paymentMethodId: string;
    promoCode: string | null;
  }) => {
    const user = getAuth().currentUser;
    if (user) {
      const eventAt = getEventAt({ uid: user.uid, name: user.displayName || '' });
      const profile: IProfileWrite = {
        ...rest,
        photoURL: null,
        countryCode: 'za',
        isVaultSetup: false,
        created: eventAt.write,
        updated: eventAt.write,
        billingUserId: user.uid,
        estateReadinessScore: 0,
        estateReadinessGap: null,
        type: EProfileType.PERSONAL,
        estateReadinessGapCount: 0,
        billingOrganisationId: null,
        suspendedReason: null,
        roles: { [user.uid]: EProfileRole.OWNER },
        email: email?.trim()?.toLowerCase() || null
      };
      handleCreate(profile);
    }
  };
  return { handleCreateProfile, ...rest };
};

export const myProfilesQueryKey = () => ['my-profiles'];

export const myProfilesQuery = () => {
  const user = getAuth().currentUser;
  return query(
    getCollectionRef(PROFILES),
    where(`roles.${user?.uid}`, '==', EProfileRole.OWNER)
  );
};

export const sharedProfilesQueryKey = () => ['shared-profiles'];

export const sharedProfilesQuery = () => {
  const user = getAuth().currentUser;
  return query(
    getCollectionRef(PROFILES),
    where(`roles.${user?.uid}`, 'in', [
      EProfileRole.WRITER,
      EProfileRole.EDITOR,
      EProfileRole.READER
    ])
  );
};
