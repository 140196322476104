export enum EBasicAccessLevel {
  READ = 'read',
  NONE = 'none'
}

export enum EAccessLevel {
  WRITE = 'write',
  EDIT = 'edit',
  READ = 'read',
  NONE = 'none'
}

export type TBasicAccessLevel = Record<string, EBasicAccessLevel>;

export type TAccessLevel = Record<string, EAccessLevel>;
