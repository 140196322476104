import { colors } from '@ld/utils';
import styled from '@emotion/styled';

export const Card = styled.div`
  padding: 15px;
  display: flex;
  border-radius: 10px;
  margin: 0px auto 20px;
  background: ${colors.green3};
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
`;

export const Heading = styled.h6`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;
