import flags from 'react-phone-number-input/flags';
import PhoneInput, { Props, DefaultInputComponentProps } from 'react-phone-number-input';

// @local
import { Styles } from './styles';
import toFormField from '../to-form-field';

export const PhoneNumberInput = (props: Props<DefaultInputComponentProps>) => {
  return (
    <Styles>
      <PhoneInput
        {...props}
        flags={flags}
        // inputtabindex={-1}
        defaultCountry='ZA'
        // countryselecttabindex={-1}
      />
    </Styles>
  );
};

export default toFormField(PhoneNumberInput);
