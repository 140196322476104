// auth
export const signinUrl = '/';
export const twoFactorAuthenticationUrl = '/two-factor';
export const createAccountUrl = '/create-an-account';
export const createAccountSuccessUrl = '/create-account-success';
export const forgotPasswordUrl = '/forgot-password';
export const verifyEmailUrl = '/verify-email';
export const authActionUrl = '/auth-action';
export const lockedAccountUrl = '/locked-account';
export const lockAccountUrl = '/lock-account/:token';
export const unauthorizedUrl = '/unauthorized';

// public
export const shareUrl = '/share/:shareId';

// post login
export const onboardUrl = '/onboard';
export const paymentsUrl = '/payments';
export const profileUrl = '/profile/:profileId';

// account and settings
export const accountUrl = '/account';
export const myAccountUrl = '/account/my-account';
export const myProfilesUrl = '/account/my-profiles';
export const tellAFriendUrl = '/account/tell-a-friend';
export const paymentMethodsUrl = '/account/payment-methods';
export const sharedProfilesUrl = '/account/shared-profiles';
export const profileInvitesUrl = '/account/profile-invites';
export const helpAndSupportUrl = '/account/help-and-support';
export const emergencySettingsUrl = '/account/emergency-settings';
