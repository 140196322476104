import * as React from 'react';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const CollectibleDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={entity.name} label='Name' />
    </>
  );
};

export default React.memo(CollectibleDetails);
