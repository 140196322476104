import { TVaultConfig } from '../types/base';
import * as appCategories from '../constants/categories';
import { TDocument, TProfileCategory, TVaultCategory } from '../types/category';

export const getCategoryData = (categoryId?: string) => {
  if (!categoryId) {
    return {
      category: undefined,
      categoryDocuments: undefined
    };
  }
  const category = appCategories.categoriesMap[categoryId];
  const categoryDocuments: Record<string, TDocument> = {};
  category.documents?.forEach((d: TDocument) => {
    categoryDocuments[d.typeId] = d;
  });
  category.entity?.documents?.forEach((d: TDocument) => {
    categoryDocuments[d.typeId] = d;
  });
  return {
    category,
    categoryDocuments
  };
};

export const getCategoryDocument = (categoryId?: string, typeId?: string) => {
  if (!categoryId || !typeId) {
    return;
  }
  const { categoryDocuments } = getCategoryData(categoryId);
  return categoryDocuments?.[typeId];
};

export const getProfileCategories = (
  config: TVaultConfig = {},
  categories: TVaultCategory[] = []
) => {
  const profileCategories: TProfileCategory[] = [];

  const vaultCategories = new Map<string, Omit<TVaultCategory, 'id'>>();
  categories?.forEach(({ id: _, ...category }) => {
    vaultCategories.set(category.categoryId, category);
  });

  appCategories.orderedCategories.forEach((category) => {
    const categoryData = vaultCategories.get(category.id) || {};
    if (config?.[category.id] !== false) {
      profileCategories.push({ ...categoryData, ...category });
    }
  });

  vaultCategories.clear();
  return profileCategories;
};

export const getSharedCategories = (categories: TVaultCategory[] = []) => {
  const profileCategories: TProfileCategory[] = [];

  const vaultCategories = new Map<string, TVaultCategory>();
  categories?.forEach((category) => {
    vaultCategories.set(category.categoryId, category);
  });
  appCategories.orderedCategories.forEach((category) => {
    const categoryData = vaultCategories.get(category.id);
    if (categoryData) {
      profileCategories.push({ ...categoryData, ...category });
    }
  });

  vaultCategories.clear();
  return profileCategories;
};
