import {
  Query,
  getDocs,
  DocumentData,
  QuerySnapshot,
  FirestoreError
} from 'firebase/firestore/lite';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

const snapshotToArray = <TData>(snapshot: QuerySnapshot): TData[] => {
  const arr: TData[] = [];
  snapshot.forEach((doc) => {
    const data = { id: doc.id, ...doc.data() } as TData;
    arr.push(data);
  });
  return arr;
};

export const itemsQueryFn =
  <TData>(itemsQuery: Query<DocumentData>) =>
  (): Promise<TData[]> =>
    getDocs(itemsQuery).then((snapshot) => snapshotToArray<TData>(snapshot));

type UseGetItems<T> = {
  query: Query<DocumentData>;
  queryKey: (string | undefined)[];
} & UseQueryOptions<T[], FirestoreError, T[], (string | undefined)[]>;

const useGetItems = <TData>({ query, ...rest }: UseGetItems<TData>) =>
  useQuery({
    ...rest,
    queryFn: itemsQueryFn<TData>(query)
  });

export default useGetItems;
