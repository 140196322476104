import * as React from 'react';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const VehicleDetails = ({ entity }: EntityDetailsProps) => {
  return <FieldValue value={entity.licenceNumber} label='Licence number' />;
};

export default React.memo(VehicleDetails);
