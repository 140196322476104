import styled from '@emotion/styled';
import MuiChip, { ChipProps } from '@mui/material/Chip';
// @ld
import { colors } from '@ld/utils';

type TTagProps = {
  type: 'beta' | 'new';
} & ChipProps;

const getBackgroundColor = ({ type }: TTagProps) => {
  switch (type) {
    case 'beta':
      return colors.orange1;
    case 'new':
      return colors.yellow1;
    default:
      return null;
  }
};

const FeatureTag = styled(MuiChip)<TTagProps>`
  && {
    padding: 0;
    height: 20px;
    border-radius: 2px;
    background: ${getBackgroundColor};

    .MuiChip-label {
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      padding-left: 4px;
      padding-right: 4px;
      color: #ffffff;
    }
  }
`;

export default FeatureTag;
