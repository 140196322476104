import { Category } from './types';

// Documents
const statements = {
  multipleDocuments: true,
  typeId: 'c1282f57',
  name: 'Statements'
};

// Category
const financialAssets: Category = {
  id: 'mbr2kvz0qvwJwrlwcUTe',
  name: 'Investments',
  shortDescription:
    'Add all your investments, savings and other financial assets (equities, crypto, gold etc.)',
  description:
    "Put all your eggs in one basket. Having all your investments and other assets listed in 1 place makes the administration of your estate much quicker. Now your family doesn't have to go in search of all those investments that they had no idea existed. Also, this forms part of the assets & liabilities of your estate and is used to calculate the various costs associated with winding up your estate.",
  entity: {
    multipleOwners: true,
    type: 'financial_asset',
    displayName: 'Financial asset',
    pluralDisplayName: 'Financial assets',
    documents: [statements]
  }
};

export default financialAssets;
