// @mui
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
//@local
import toFormField from '../to-form-field';

export type SelectOption = {
  label: string;
  value: string | number | readonly string[];
};

type CustomSelectProps = {
  label: string;
  options: SelectOption[];
  formControlProps?: FormControlProps;
} & SelectProps;

const Select = ({
  name,
  label,
  options,
  formControlProps,
  ...rest
}: CustomSelectProps) => {
  const labelId = `${name}-select-label`;
  return (
    <FormControl fullWidth variant='outlined' {...formControlProps}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect {...rest} id={name} label={label} labelId={labelId}>
        {options.map(({ value, label }) => (
          <MenuItem key={value.toString()} value={value}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default toFormField(Select);

export { Select };
