import styled from '@emotion/styled';
import MuiDialog from '@mui/material/Dialog';
import MuiLinearProgress from '@mui/material/LinearProgress';

export const Dialog = styled(MuiDialog)`
  .MuiDialog-container {
    backdrop-filter: blur(10px);
  }

  .MuiDialog-paper {
    background: #ffffff;
    box-shadow: 0px 15px 30px rgba(38, 50, 56, 0.1);
    border-radius: 20px 20px 0px 0px;
    min-height: 98%;
    width: 100%;
    min-width: 100vw;
    margin-bottom: 0px;

    ${(props) => props.theme.breakpoints.down('xs')} {
      border-radius: 0px;
    }
  }

  .MuiDialog-scrollPaper {
    align-items: flex-end;
  }
`;

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 10px;
`;

export const DocViewerContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ImageViewerContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  background-color: black;
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  flex: 1;
`;

export const Img = styled.img`
  max-width: 95%;
  max-height: 95%;
`;

export const LinearProgress = styled(MuiLinearProgress)`
  && {
    width: 200px;
    border-radius: 3px;

    .MuiLinearProgress-root {
      border-radius: 3px;
    }

    .MuiLinearProgress-bar {
      border-radius: 3px;
    }
  }
`;
