import * as React from 'react';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const FamilyMemberDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={entity.serialNumber} label='Serial number' />
      <FieldValue value={entity.licenceNumber} label='Licence number' />
      <FieldValue
        value={entity.physicalStorageLocation}
        label='Physical storage location'
      />
    </>
  );
};

export default React.memo(FamilyMemberDetails);
