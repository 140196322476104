import * as React from 'react';
// @mui
import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
// @ld
import { Avatar, UnstyledLink, BodySmallText } from '@ld/core';
import { colors, IProfileRead } from '@ld/utils';
// @custom
import { getDashboardUrl } from 'utils/urls';

const ProfileNavItem = ({
  profile,
  isPrimaryProfile
}: {
  profile: IProfileRead;
  isPrimaryProfile?: boolean;
}) => (
  <ListItem
    component={UnstyledLink}
    to={getDashboardUrl(profile.id)}
    sx={{
      '&:hover': { background: colors.grey5, borderRadius: '10px' }
    }}
  >
    <ListItemAvatar>
      <Avatar
        alt={profile.name}
        src={profile.photoURL || ''}
        sx={{ width: '50px', height: '50px', fontSize: '18px' }}
      />
    </ListItemAvatar>
    <Box>
      <BodySmallText>{profile.name}</BodySmallText>
      {isPrimaryProfile && (
        <Chip
          label='Primary'
          sx={{
            background: colors.green1,
            color: '#ffffff',
            borderRadius: '5px',
            height: '20px',
            fontSize: '11px'
          }}
        />
      )}
      {profile.suspendedReason && (
        <Chip
          label='Suspended'
          sx={{
            background: colors.red1,
            color: '#ffffff',
            borderRadius: '5px',
            height: '20px',
            fontSize: '11px'
          }}
        />
      )}
    </Box>
  </ListItem>
);

export default React.memo(ProfileNavItem);

export const ProfileNavItemSkeleton = () => (
  <ListItem disableGutters>
    <ListItemAvatar>
      <Skeleton variant='circular' height='40px' width='40px' />
    </ListItemAvatar>
    <Skeleton variant='text' height='25px' width='120px' />
  </ListItem>
);
