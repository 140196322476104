import * as React from 'react';
import { GiPistolGun, GiDiploma, GiMedicalPack } from 'react-icons/gi';
import {
  IoCarOutline,
  IoWalletOutline,
  IoKeyOutline,
  IoCashOutline,
  IoPawOutline,
  IoBusinessOutline,
  IoPieChartOutline,
  IoHomeOutline,
  IoUmbrellaOutline,
  IoPersonOutline,
  IoDiamondOutline
} from 'react-icons/io5';
import { HiOutlineReceiptTax } from 'react-icons/hi';
// @ld
import { formatDate } from '@ld/utils';

type GetEntityDataProps = {
  entity: any;
  documentsGoalCount: number;
};

const getEntityData = ({
  entity,
  documentsGoalCount
}: GetEntityDataProps): {
  name: string;
  copy: React.ReactNode;
  Icon: React.ComponentType;
} => {
  const documentsCount = entity.uploadedDocumentsCount || 0;
  const documentsProgress = (
    <>
      <b>
        {documentsCount} / {documentsGoalCount}
      </b>{' '}
      documents
    </>
  );
  switch (entity?.type) {
    case 'bank_account':
      return {
        name: entity?.accountName,
        Icon: IoWalletOutline,
        copy: entity?.institutionName || entity?.accountNumber
      };
    case 'qualification':
      return {
        name: entity?.qualificationName,
        Icon: GiDiploma,
        copy: documentsProgress
      };
    case 'medical_information':
      return {
        name:
          entity?.infoType === 'medical_cover'
            ? entity?.institutionName
            : entity?.immunisationName,
        Icon: GiMedicalPack,
        copy: documentsProgress
      };
    case 'insurance_policy':
      return {
        name: entity?.institutionName,
        Icon: IoUmbrellaOutline,
        copy: documentsProgress
      };
    case 'debt':
      return {
        name: entity?.institutionName,
        Icon: IoPieChartOutline,
        copy: documentsProgress
      };
    case 'taxes': {
      const dateYear = formatDate(entity?.fiscalYear, 'yyyy');
      return {
        name: dateYear,
        Icon: HiOutlineReceiptTax,
        copy: documentsProgress
      };
    }
    case 'financial_asset':
      return {
        name: entity?.assetName,
        Icon: IoPieChartOutline,
        copy: entity?.institutionName
      };
    case 'payable_account':
      return {
        name: entity?.institutionName,
        Icon: IoCashOutline,
        copy: documentsProgress
      };
    case 'pet':
      return {
        name: entity?.petName,
        Icon: IoPawOutline,
        copy: documentsProgress
      };
    case 'property':
      return {
        name: entity?.propertyAddress,
        Icon: IoHomeOutline,
        copy: documentsProgress
      };
    case 'vehicle':
      return {
        name: entity?.makeOrModel,
        Icon: IoCarOutline,
        copy: documentsProgress
      };
    case 'firearm':
      return {
        name: entity?.firearmName,
        Icon: GiPistolGun,
        copy: documentsProgress
      };
    case 'access':
      return {
        name: entity?.accessName,
        Icon: IoKeyOutline,
        copy: ''
      };
    case 'employer':
      return {
        name: entity?.companyName,
        Icon: IoBusinessOutline,
        copy: documentsProgress
      };
    case 'business':
      return {
        name: entity?.companyName,
        Icon: IoBusinessOutline,
        copy: documentsProgress
      };
    case 'trust':
      return {
        name: entity?.trustName,
        Icon: IoBusinessOutline,
        copy: documentsProgress
      };
    case 'dependent':
      return {
        name: entity?.dependentName,
        Icon: IoPersonOutline,
        copy: documentsProgress
      };
    case 'collectible':
      return {
        name: entity?.name,
        Icon: IoDiamondOutline,
        copy: ''
      };
    default:
      return {
        name: 'Invalid entity',
        Icon: IoBusinessOutline,
        copy: ''
      };
  }
};

export default getEntityData;
