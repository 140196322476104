import { colors } from '@ld/utils';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

export const LogoLink = styled(Link)`
  &,
  &:link {
    text-decoration: none;
    color: ${colors.black1};
    backface-visibility: hidden;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const DrawerContent = styled.div`
  width: 300px;
  padding: 20px 5px;
`;
