import * as React from 'react';
import { categories } from '@ld/utils';
import Box from '@mui/material/Box';

// @components
import Modal from '../modal';
import { BodySmallText } from '../typography';
// @local
import PersonalGap from './personal';
import DeathAndEstateGap from './death-and-estate';
import EntityCategoryGap from './entity-category-gap';

type EstateReadinessGapModalProps = {
  open: boolean;
  description?: string;
  onClose: (props?: object) => void;
  estateReadinessGap: Record<string, unknown>;
};

const {
  vehicles,
  properties,
  businesses,
  employment,
  bankAccounts,
  financialAssets,
  payableAccounts,
  insurancePolicies,
  debtAndLiabilities
} = categories;

const EstateReadinessGapModal = ({
  estateReadinessGap,
  description = "The estate readiness gap shows important information and documents that are missing based on the user's vault",
  ...rest
}: EstateReadinessGapModalProps) => {
  return (
    <Modal {...rest} maxWidth='sm' id='estate-readiness-gap' title='Estate readiness gap'>
      <Box pb={2}>
        <BodySmallText>{description}</BodySmallText>
        <Box py={2}>
          <PersonalGap estateReadinessGap={estateReadinessGap} />
          <DeathAndEstateGap estateReadinessGap={estateReadinessGap} />
          <EntityCategoryGap
            category={insurancePolicies}
            estateReadinessGap={estateReadinessGap}
          />
          <EntityCategoryGap
            category={properties}
            estateReadinessGap={estateReadinessGap}
          />
          <EntityCategoryGap
            category={vehicles}
            estateReadinessGap={estateReadinessGap}
          />
          <EntityCategoryGap
            category={businesses}
            estateReadinessGap={estateReadinessGap}
          />
          <EntityCategoryGap
            category={employment}
            estateReadinessGap={estateReadinessGap}
          />
          <EntityCategoryGap
            category={financialAssets}
            estateReadinessGap={estateReadinessGap}
          />
          <EntityCategoryGap
            category={bankAccounts}
            estateReadinessGap={estateReadinessGap}
          />
          <EntityCategoryGap
            category={payableAccounts}
            estateReadinessGap={estateReadinessGap}
          />
          <EntityCategoryGap
            category={debtAndLiabilities}
            estateReadinessGap={estateReadinessGap}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default React.memo(EstateReadinessGapModal);
