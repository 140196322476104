import * as React from 'react';
import { categories } from '@ld/utils';
import Box from '@mui/material/Box';

// @local
import { ListWrapper } from '../styles';
import { Heading5, BodySmallText } from '../../typography';

const DeathAndEstateGap = ({
  estateReadinessGap
}: {
  estateReadinessGap: Record<string, unknown>;
}) => {
  const { deathAndEstate } = categories;
  const missingDocuments =
    deathAndEstate.documents?.filter((d) => Boolean(estateReadinessGap[d.typeId])) || [];
  const hasGap = missingDocuments.length > 0;
  if (hasGap) {
    return (
      <Box mb='20px'>
        <Heading5>Death &amp; estate</Heading5>
        <ListWrapper>
          {missingDocuments.map((d) => (
            <li key={d.name}>
              <BodySmallText>{d.name}</BodySmallText>
            </li>
          ))}
        </ListWrapper>
      </Box>
    );
  }
  return null;
};

export default React.memo(DeathAndEstateGap);
