import * as React from 'react';
// @ld
import { TProfileCategory, TVaultConfig } from '@ld/utils';
// @custom
import CategoryIcon from '../category-icon';
// @local
import { Text, Title, IconWrapper, StyledFolder, LinearProgress } from './styles';

type CategoryFolderProps = {
  to: string;
  category: TProfileCategory;
  vaultConfig?: TVaultConfig;
};

const CategoryFolderFooter = ({ category, vaultConfig }: CategoryFolderProps) => {
  if (category?.entity) {
    const userEntitiesCount = category.entitiesAddedCount || 0;
    return (
      <Text>
        <span>{userEntitiesCount}</span>{' '}
        {userEntitiesCount === 1
          ? category.entity.displayName
          : category.entity.pluralDisplayName}{' '}
        added
      </Text>
    );
  }
  const userDocsCount = category.uploadedDocumentsCount || 0;
  const goal =
    category?.documents?.filter((file) => vaultConfig?.[file.typeId] !== false).length ||
    0;
  const progress = {
    documentsText:
      userDocsCount <= goal ? `${userDocsCount} / ${goal}` : `${goal} / ${goal}`,
    percentage: userDocsCount <= goal ? (userDocsCount / goal) * 100 : 100
  };

  if (goal !== 0) {
    return (
      <div>
        <Text>
          <span>{progress.documentsText}</span> documents uploaded
        </Text>
        {goal !== 0 && (
          <LinearProgress variant='determinate' value={progress.percentage} />
        )}
      </div>
    );
  }

  return null;
};

const CategoryFolder = (props: CategoryFolderProps) => {
  const { to, category } = props;
  return (
    <StyledFolder to={to}>
      {category?.id && (
        <IconWrapper>
          <CategoryIcon categoryId={category?.id} />
        </IconWrapper>
      )}
      <Title>{category?.name}</Title>
      <CategoryFolderFooter {...props} />
    </StyledFolder>
  );
};

export default React.memo(CategoryFolder);
