import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

// @local
import { ringProps } from './utils';
import { RingBackground } from './styles';

const ERSRingSkeleton = () => {
  return (
    <Box sx={{ position: 'relative', margin: 'auto' }}>
      <RingBackground {...ringProps} value={100} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Skeleton variant='text' height={30} width={45} />
      </Box>
    </Box>
  );
};

export default ERSRingSkeleton;
