import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from '@tanstack/react-query';
import {
  getAuth,
  setPersistence,
  signInWithPopup,
  checkActionCode,
  applyActionCode,
  EmailAuthProvider,
  GoogleAuthProvider,
  confirmPasswordReset,
  sendPasswordResetEmail,
  reauthenticateWithPopup,
  verifyPasswordResetCode,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  reauthenticateWithCredential
} from 'firebase/auth';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
// @custom
import {
  removeTwoFactorVerified,
  removeSigninEmailNotificationSentFlag
} from 'services/session-storage';

const firebaseApp = getApp();
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, 'europe-west6');

export const useCreateUser = (options?: object) => {
  const { mutate: handleCreateUser, ...rest } = useMutation(
    (data: { fullName: string; email: string; password: string }) => {
      const createUser = httpsCallable(functions, 'users-createUser');
      return fetch(`https://disposable.debounce.io/?email=${data.email}`)
        .then((res) => res.json())
        .then((data) => {
          if (data?.disposable === 'true') {
            throw Error("We don't have time for disposable emails");
          }
        })
        .then(() => createUser(data));
    },
    options
  );
  return { handleCreateUser, ...rest };
};

export const useSignInWithEmailAndPassword = (options?: any) => {
  const { mutate: handleSignInWithEmailAndPassword, ...rest } = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      await setPersistence(auth, browserSessionPersistence);
      return signInWithEmailAndPassword(auth, email, password);
    },
    options
  );
  return { handleSignInWithEmailAndPassword, ...rest };
};

export const useSendSignInNotificationEmail = (options?: object) => {
  const { mutate: handleSendSignInNotificationEmail, ...rest } = useMutation(() => {
    const sendSignInNotificationEmail = httpsCallable(
      functions,
      'users-sendsigninnotificationemail'
    );
    return sendSignInNotificationEmail();
  }, options);
  return { handleSendSignInNotificationEmail, ...rest };
};

export const useSendVerificationEmail = (options?: object) => {
  const { mutate: handleSendVerificationEmail, ...rest } = useMutation(
    (email: string) => {
      const sendVerificationEmail = httpsCallable(
        functions,
        'users-sendVerificationEmail'
      );
      return sendVerificationEmail({ email });
    },
    options
  );
  return { handleSendVerificationEmail, ...rest };
};

export const useSendPasswordResetEmail = (options?: object) => {
  const { mutate: handleSendPasswordResetEmail, ...rest } = useMutation(
    (email: string) => {
      return sendPasswordResetEmail(auth, email);
    },
    options
  );
  return { handleSendPasswordResetEmail, ...rest };
};

export const useConfirmPasswordReset = (options?: object) => {
  const { mutate: handleConfirmPasswordReset, ...rest } = useMutation(
    async ({ actionCode, password }: { actionCode: string; password: string }) => {
      // Verify the password reset code is valid.
      await verifyPasswordResetCode(auth, actionCode);
      return confirmPasswordReset(auth, actionCode, password);
      // You can sign them in here if you want
    },
    options
  );
  return { handleConfirmPasswordReset, ...rest };
};

export const useRecoverEmail = (actionCode: string, options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['recover-email'],
    queryFn: async () => {
      const info = await checkActionCode(auth, actionCode);
      // Get the restored email address.
      const restoredEmail = info['data']['email'] || '';
      // Revert to the old email.
      await applyActionCode(auth, actionCode);
      // You might also want to give the user the option to reset their password
      // in case the account was compromised:
      return sendPasswordResetEmail(auth, restoredEmail);
    },
    ...options
  });
};

export const useVerifyEmail = (actionCode: string, options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['verify-email'],
    queryFn: () => applyActionCode(auth, actionCode).then(() => true),
    ...options
  });
};

export const useContinueWithGoogle = (options?: any) => {
  const { mutate: handleContinueWithGoogle, ...rest } = useMutation(async () => {
    await setPersistence(auth, browserSessionPersistence);
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }, options);
  return { handleContinueWithGoogle, ...rest };
};

export const useReauthenticateWithCredential = (options?: any) => {
  const { mutate: handleReauthenticateWithCredential, ...rest } = useMutation(
    (password: string) => {
      const user = auth.currentUser;
      if (password && user?.email) {
        const credential = EmailAuthProvider.credential(user.email, password);
        return reauthenticateWithCredential(user, credential);
      } else {
        throw Error('Failed to reauthenticate');
      }
    },
    options
  );
  return { handleReauthenticateWithCredential, ...rest };
};

export const useReauthenticateWithGoogle = (options?: any) => {
  const { mutate: handleReauthenticateWithGoogle, ...rest } = useMutation(() => {
    const user = auth.currentUser;
    if (user) {
      const provider = new GoogleAuthProvider();
      return reauthenticateWithPopup(user, provider);
    } else {
      throw Error('Failed to reauthenticate');
    }
  }, options);
  return { handleReauthenticateWithGoogle, ...rest };
};

export const useLockUserAccount = (options?: object) => {
  const { mutate: handleLockUserAccount, ...rest } = useMutation(
    (data: { token: string }) => {
      const lockUserAccount = httpsCallable(functions, 'users-disableUserAccount');
      return lockUserAccount(data);
    },
    options
  );
  return { handleLockUserAccount, ...rest };
};

export const useSignOut = (options?: UseMutationOptions) => {
  const { mutate: handleSignOut, ...rest } = useMutation(
    () =>
      auth.signOut().then(() => {
        removeTwoFactorVerified();
        removeSigninEmailNotificationSentFlag();
      }),
    options
  );
  return { handleSignOut, ...rest };
};
