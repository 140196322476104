import * as React from 'react';
import { categories } from '@ld/utils';
import Box from '@mui/material/Box';

// @local
import { ListWrapper } from '../styles';
import { Heading5, BodySmallText } from '../../typography';

const PersonalInfoAndDocumentsGap = ({
  estateReadinessGap
}: {
  estateReadinessGap: Record<string, unknown>;
}) => {
  const { personal } = categories;
  const requiredInfo = [
    { id: 'idNumber', label: 'ID number' },
    { id: 'taxNumber', label: 'Tax number' },
    { id: 'maritalStatus', label: 'Marital status' }
  ];
  const missingInfo = requiredInfo.filter((info) => Boolean(estateReadinessGap[info.id]));
  const missingDocuments =
    personal.documents?.filter((d) => Boolean(estateReadinessGap[d.typeId])) || [];
  const hasGap = missingInfo.length > 0 || missingDocuments.length > 0;
  if (hasGap) {
    return (
      <Box mb='20px'>
        <Heading5>Personal</Heading5>
        <ListWrapper>
          {missingInfo.map((info) => (
            <li key={info.id}>
              <BodySmallText>{info.label}</BodySmallText>
            </li>
          ))}
          {missingDocuments.map((d) => (
            <li key={d.name}>
              <BodySmallText>{d.name}</BodySmallText>
            </li>
          ))}
        </ListWrapper>
      </Box>
    );
  }
  return null;
};

export default React.memo(PersonalInfoAndDocumentsGap);
