// @mui
import { Stack, Box, Button } from '@mui/material';
// @ld
import { colors } from '@ld/utils';
import { Heading5, SubText, UnstyledLink } from '@ld/core';
// @custom
import { myProfilesUrl } from 'constants/urls';
import { ReactComponent as VisualDataIllustration } from 'assets/visual-data.svg';

const AddProfile = () => {
  return (
    <Stack
      direction='column'
      alignItems='center'
      sx={{
        p: '10px',
        m: '10px',
        borderRadius: '10px',
        background: colors.grey5
      }}
    >
      <VisualDataIllustration height={150} width={150} />
      <Heading5 align='center'>Add your profile</Heading5>
      <Box component={SubText} align='center' mt='10px'>
        Create your own Lifedocs profile and start organising your personal affairs.
      </Box>
      <UnstyledLink to={myProfilesUrl}>
        <Button variant='text' color='secondary'>
          Get started
        </Button>
      </UnstyledLink>
    </Stack>
  );
};

export default AddProfile;
