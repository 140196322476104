import { v4 as uuidv4 } from 'uuid';
// @local
import getFileExtension from './get-file-extension';

export const getRenamedFile = (file: File, name: string) => {
  return new File([file], name, {
    type: file.type,
    lastModified: file.lastModified
  });
};

export const getAutoRenamedFile = (file: File) => {
  const newName = `${uuidv4()}.${getFileExtension(file.name)}`;
  return getRenamedFile(file, newName);
};
