import Box from '@mui/material/Box';

//@local
import { Heading2 } from '../typography';
import { getErsConfig, ringProps } from './utils';
import { RingBackground, ValueRing, Chip } from './styles';

const ERSRing = ({ value }: { value?: number }) => {
  const ersConfig = getErsConfig(value);
  return (
    <Box sx={{ position: 'relative', width: 'fit-content' }}>
      <RingBackground {...ringProps} value={100} />
      <ValueRing {...ringProps} value={value} stroke={ersConfig.color} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Heading2 color={ersConfig.color}>{value}</Heading2>
        <Chip
          style={{
            color: ersConfig.color,
            background: ersConfig.backgroundColor
          }}
          label={ersConfig.category}
        />
      </Box>
    </Box>
  );
};

export default ERSRing;
