const config = {
  isLocalEnv: process.env.REACT_APP_ENV === 'local',
  isProdEnv: process.env.REACT_APP_ENV === 'production',
  isStagingEnv: process.env.REACT_APP_ENV === 'staging',
  isDebugEnv: process.env.REACT_APP_ENV === 'debug',
  appCheckDebugToken: process.env.REACT_APP_APPCHECK_DEBUG_TOKEN,
  paystackPublicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  segmentApiKey: process.env.REACT_APP_SEGMENT_API_KEY,
  delightedApiKey: process.env.REACT_APP_DELIGHTED_API_KEY,
  clientPortalUrl: process.env.REACT_APP_CLIENT_PORTAL_URL,
  advisorPortalUrl: process.env.REACT_APP_ADVISOR_PORTAL_URL,
  websiteUrl: process.env.REACT_APP_WEBSITE_URL,
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  firebaseAppConfig: {
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
  },
  awsConfig: {
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_S3_REGION
  }
};

export default config;
