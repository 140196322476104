import * as React from 'react';
import { FirestoreError } from 'firebase/firestore/lite';
// @ld
import { IProfileRead } from '@ld/utils';
// @custom
import useUser from 'hooks/use-user';
import { PROFILES } from 'api/profiles';
import useGetItem from 'api/utils/use-get-item';

const ProfileContext = React.createContext<{
  isLoading?: boolean;
  refetch: () => void;
  profile?: IProfileRead;
  error: FirestoreError | null;
  setProfileId: (profileId?: string) => void;
}>({ error: null, setProfileId: () => null, refetch: () => null });

export const ProfileProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const [profileId, setProfileId] = React.useState<string>();

  const {
    error,
    refetch,
    isLoading,
    data: profile
  } = useGetItem<IProfileRead>({
    id: profileId,
    enabled: !!profileId,
    collection: PROFILES,
    keepPreviousData: true
  });

  React.useEffect(() => {
    if (!profileId && user?.primaryProfileId) {
      setProfileId(user.primaryProfileId);
    }
  }, []);

  return (
    <ProfileContext.Provider value={{ profile, error, isLoading, setProfileId, refetch }}>
      {children}
    </ProfileContext.Provider>
  );
};

ProfileContext.displayName = 'ProfileContext';

export default ProfileContext;
