import { Category } from './types';

// Documents
const irp5 = {
  typeId: '423f154b',
  name: 'IRP5'
};

const payslip = {
  typeId: 'e43de07b',
  name: 'Payslips',
  multipleDocuments: true
};

const employmentContract = {
  typeId: '07c99f9e',
  name: 'Employment contract'
};

// Category
const employmentInformation: Category = {
  id: 'kGUl1oHhaqkWV0ExvY3J',
  name: 'Employment',
  shortDescription: 'Basic employer information and employer tax related forms',
  description:
    'Knowing where you worked is vital for the winding up of your estate, especially for tax purposes. Also, you may have certain employment benefits that need to be accumulated as part of the estate winding-up process.',
  entity: {
    type: 'employer',
    displayName: 'Employer',
    pluralDisplayName: 'Employers',
    documents: [irp5, payslip, employmentContract]
  }
};

export default employmentInformation;

export const employmentDocuments = {
  irp5,
  payslip,
  employmentContract
};
