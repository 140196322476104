type PluralizeOptions = {
  singular: string;
  plural?: string;
  count: number;
};

const pluralize = ({ singular, plural, count }: PluralizeOptions) => {
  let output = singular;
  if (count !== 1) {
    output = plural || `${singular}s`;
  }

  return output;
};

export const docPluralize = (count: number) =>
  pluralize({
    singular: 'document',
    count
  });

export default pluralize;
