import * as React from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// @local
import toFormField from '../to-form-field';

type CheckboxProps = {
  name: string;
  label: string;
  value?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
};

export const Checkbox = ({ label, name, value, disabled, onChange }: CheckboxProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox
          name={name}
          checked={value}
          disabled={disabled}
          onChange={handleChange}
        />
      }
    />
  );
};

export default toFormField(Checkbox);
