import styled from '@emotion/styled';
// @mui
import ButtonBase from '@mui/material/ButtonBase';
// @ld
import { colors } from '@ld/utils';

export const SwitchButton = styled(ButtonBase)`
  && {
    width: 100%;
    display: flex;
    padding: 10px 15px;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.grey5};

    &:hover {
      background-color: ${colors.grey4};
    }
  }
`;

export const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  min-width: 25px;
  border-radius: 50%;
  align-items: center;
  background: #ffffff;
  justify-content: center;
`;
