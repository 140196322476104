import styled from '@emotion/styled';
import { css } from '@emotion/react';
// @ld
import { colors } from '@ld/utils';
// @custom
import { BodyLargeText } from '../typography';

export const FormWrapper = styled.div`
  width: 716px;
`;

export const Fieldset = styled.fieldset`
  && {
    width: 100%;
    margin-top: 16px;
  }
`;

export const Legend = styled.legend`
  && {
    margin-bottom: 12px;
    font-weight: 600;
    color: ${colors.black1};
  }
`;

export const FieldLabel = styled(BodyLargeText)`
  font-size: 16px;
  margin-bottom: 4px;
`;

type FormFieldProps = {
  hidden?: boolean;
};

export const FormField = styled.div<FormFieldProps>`
  margin-bottom: 24px;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
      margin-bottom: 0px;
    `}
`;

export const FormActions = styled.div`
  margin-top: 20px;
`;

export const FieldWarning = styled.p`
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  margin: 4px 0px 0px;
  color: ${colors.orange1};
  font-family: 'Montserrat', sans-serif;
`;
