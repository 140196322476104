import { Category } from './types';

// Documents
const rabiesCertificate = {
  typeId: '4321c4d0',
  name: 'Rabies certificate'
};

const medicalRecords = {
  typeId: 'b94c7936',
  name: 'Medical records'
};

const vaccineRecords = {
  typeId: '141e65f1',
  name: 'Vaccine records'
};

const pedigreeCertificate = {
  typeId: '645c1191',
  name: 'Pedigree certificate'
};

const microchipCertificate = {
  typeId: '60957199',
  name: 'Microchip certificate'
};

const petInsurance = {
  typeId: '8d7653ef',
  name: 'Pet insurance'
};

// Category
const pets: Category = {
  id: 'oiu0kGoeM7NFiDUJGEfl',
  name: 'Pets',
  shortDescription: 'Your pet(s) details',
  description:
    'Our four-legged friends are part of the family. Keep all their documents in one place to make sure they’re taken care of when you’re gone.',
  entity: {
    type: 'pet',
    displayName: 'Pet',
    multipleOwners: true,
    pluralDisplayName: 'Pets',
    documents: [
      petInsurance,
      medicalRecords,
      vaccineRecords,
      rabiesCertificate,
      pedigreeCertificate,
      microchipCertificate
    ]
  }
};

export default pets;

export const petsDocuments = {
  petInsurance,
  medicalRecords,
  vaccineRecords,
  rabiesCertificate,
  pedigreeCertificate,
  microchipCertificate
};
