import { Category } from './types';

// Documents
const statements = {
  multipleDocuments: true,
  typeId: '189ef294',
  name: 'Statements'
};

// Category
const debtAndLiabilities: Category = {
  id: 'dGgUobel4lGW73j2APk7',
  name: 'Liabilities',
  shortDescription: 'Add your debt and liabilities (personal loans, student loans, etc.)',
  description:
    'All your other liabilities and debt. If you have any loans such as personal loans, student loans, etc. then drop them in here. Also, this forms part of the assets & liabilities of your estate and is used to calculate the various costs associated with winding up your estate.',
  entity: {
    type: 'debt',
    displayName: 'Debt',
    pluralDisplayName: 'Debt',
    documents: [statements]
  }
};

export default debtAndLiabilities;
