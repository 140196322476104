import * as React from 'react';
import { snakeToNormalCase } from '@ld/utils';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const BankAccountDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={entity.institutionName} label='Institution name' />
      <FieldValue value={snakeToNormalCase(entity.accountType)} label='Account type' />
      <FieldValue value={entity.accountNumber} label='Account number' />
    </>
  );
};

export default React.memo(BankAccountDetails);
