import * as React from 'react';

// @custom
import { isStagingEnv } from 'config';
import { BannerWrapper } from './styles';

const StagingEnvBanner = () => {
  if (isStagingEnv) {
    return (
      <BannerWrapper>
        You are on the staging environment. If you want to go to our production
        environment got to&nbsp;
        <a href='https://app.lifedocs.co.za/'> https://app.lifedocs.co.za</a>.
      </BannerWrapper>
    );
  }

  return null;
};

export default React.memo(StagingEnvBanner);
