import { Category } from './types';

// Documents
const collectibleDocuments = {
  multipleDocuments: true,
  typeId: 'cba1250b',
  name: 'Collectible documents'
};

// Category
const collectibles: Category = {
  id: 'h5hO97onT0W3h6P9GIvvAw',
  name: 'Collectibles',
  shortDescription:
    'Other valuable collectibles such as jewelry, art, electronics and other heirlooms',
  description:
    'All your other important and valuable collectibles such as jewelry, art, electronics and other heirlooms . If you feel it has value, then list them here.',
  entity: {
    type: 'collectible',
    displayName: 'Collectible',
    pluralDisplayName: 'Collectibles',
    documents: [collectibleDocuments]
  }
};

export default collectibles;
