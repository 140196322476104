import { Category } from './types';

// Documents
const lastWillAndTestament = {
  typeId: '80d2d2a0',
  name: 'Last will & testament',
  isRequired: true,
  metadata: [
    {
      type: 'date',
      disableFuture: true,
      name: 'lastUpdatedAt',
      label: 'Last updated date'
    },
    {
      type: 'text',
      multiline: true,
      name: 'physicalStorageLocation',
      label: 'Physical storage location'
    }
  ]
};

const powerOfAttorney = {
  typeId: '2c0a8477',
  name: 'Power of attorney'
};

const livingWill = {
  typeId: '39cc76c8',
  name: 'Living will'
};

const estatePlan = {
  typeId: '6ebdc868',
  name: 'Estate plan',
  singularName: 'estate plan',
  multipleDocuments: true,
  metadata: [
    {
      type: 'date',
      disableFuture: true,
      name: 'lastUpdatedAt',
      label: 'Last updated date'
    }
  ]
};

const letterOfWishes = {
  typeId: '94d5c9be',
  name: 'Letter of wishes'
};

const parentingPlan = {
  typeId: '264fcde3',
  name: 'Parenting plan'
};

const spousalDeathCertificate = {
  typeId: 'e292315b',
  name: 'Spousal death certificate'
};

const spousalLetterOfExecutorship = {
  typeId: 'c59c13d6',
  name: 'Spousal letter of executorship'
};

const adoptionPapers = {
  typeId: '4bfabd98',
  name: 'Adoption papers'
};

const organDonorCard = {
  typeId: '33263c5e',
  name: 'Organ donor card'
};

// Category
const deathAndEstate: Category = {
  id: 'rLK0mcWdVl6I6Y76GYy8',
  name: 'Estate',
  shortDescription:
    'Your will, and information relating to managing your estate when you pass on.',
  description:
    'Your most important estate planning documents. Here is where you keep a copy of your will, give its physical location and create your wishes for how your loved ones should continue your legacy.',
  documents: [
    lastWillAndTestament,
    livingWill,
    estatePlan,
    letterOfWishes,
    powerOfAttorney,
    spousalDeathCertificate,
    spousalLetterOfExecutorship,
    parentingPlan,
    adoptionPapers,
    organDonorCard
  ]
};

export default deathAndEstate;

export const deathAndEstateDocuments = {
  lastWillAndTestament,
  livingWill,
  estatePlan,
  letterOfWishes,
  powerOfAttorney,
  spousalDeathCertificate,
  spousalLetterOfExecutorship,
  parentingPlan,
  adoptionPapers,
  organDonorCard
};
