import React from 'react';
import styled from '@emotion/styled';
// @mui
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';
// @ld
import { colors, TNullableString } from '@ld/utils';

const getInitials = (name = '') => {
  let initials = '?';
  const res = name.split(' ');
  if (res.length >= 2) {
    initials = res[0].charAt(0) + res[1].charAt(0);
  } else if (res.length === 1) {
    initials = res[0].charAt(0);
  }
  return initials.replace(/[^\w\s]/gi, '').toUpperCase();
};

const StyledAvatar = styled(MuiAvatar)`
  && {
    color: ${colors.grey1};
    background: ${colors.grey4};
  }
`;

const Avatar = (props: AvatarProps & { src?: TNullableString }) => {
  return <StyledAvatar {...props}>{getInitials(props.alt)}</StyledAvatar>;
};

export default React.memo(Avatar);
