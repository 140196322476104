import { useLocation } from 'react-router-dom';
import { getFromApp, setFromApp } from 'services/session-storage';
import qs from 'query-string';
import * as React from 'react';

/**
 * Runs once when the site loads, we check if we have the `fromApp` query param and
 * set it in local storage, this ensures that the value persists throughout the entire lifetime
 * of the visit
 */
export function useSetFromApp() {
  const { search } = useLocation();
  const { fromApp } = qs.parse(search);

  React.useEffect(() => {
    setFromApp(`${fromApp}`);
  }, [setFromApp]);
}

export function useFromApp() {
  const fromApp = getFromApp() === 'true';

  return { fromApp };
}
