import { colors } from '@ld/utils';
import styled from '@emotion/styled';

export const BannerWrapper = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 4px;
  display: flex;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  background-color: ${colors.orange1};

  a,
  a:link,
  a:visited {
    color: #ffffff;
    font-weight: normal;
  }
`;
