import * as React from 'react';
import { snakeToNormalCase } from '@ld/utils';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const AssetDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={entity.institutionName} label='Institution name' />
      <FieldValue
        value={
          entity.assetType === 'other'
            ? entity.otherAssetTypeName
            : snakeToNormalCase(entity.assetType)
        }
        label='Investment/Asset type'
      />
    </>
  );
};

export default React.memo(AssetDetails);
