import { Timestamp, FieldValue } from 'firebase/firestore/lite';

import { TNullableString } from './base';

export enum ENotificationSourceType {
  PERSON = 'person',
  THIRD_PARTY = 'third_party',
  INTERNAL_SYSTEM = 'internal_system'
}

export enum ENotificationType {
  DOCUMENT_UPLOAD_REQUEST = 'document_upload_request',
  EXPIRING_DOCUMENT = 'expiring_document',
  EXPIRED_DOCUMENT = 'expired_document'
}

type TNotificationSource = {
  uid?: TNullableString;
  name?: TNullableString;
  photoUrl?: TNullableString;
  type: ENotificationSourceType;
};

type TNotificationDocument = {
  url: string;
  typeId: string;
  fileName: string;
  fileSize: number;
  categoryId: string;
  expiresAt?: Timestamp;
};

export interface INotificationWrite {
  read: boolean;
  profileId: string;
  type: ENotificationType;
  source: TNotificationSource;
  data: TNotificationDocument;
  createdAt: FieldValue;
  createdBy: string;
}
export interface INotificationRead extends INotificationWrite {
  id: string;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
}
