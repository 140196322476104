import * as Icons from 'react-icons/io5';
// @custom
import * as urls from 'constants/urls';

const navItems = [
  {
    to: urls.myAccountUrl,
    label: 'My account',
    icon: Icons.IoPersonCircleOutline
  },
  {
    to: urls.myProfilesUrl,
    label: 'My profiles',
    icon: Icons.IoPeopleCircleOutline
  },
  {
    to: urls.sharedProfilesUrl,
    label: 'Shared profiles',
    icon: Icons.IoShareSocialOutline
  },
  {
    to: urls.profileInvitesUrl,
    label: 'Profile invites',
    icon: Icons.IoMailOpenOutline
  },
  {
    to: urls.emergencySettingsUrl,
    label: 'Emergency settings',
    icon: Icons.IoWarningOutline
  },
  {
    to: urls.paymentMethodsUrl,
    label: 'Payment methods',
    icon: Icons.IoReceiptOutline
  },
  {
    to: urls.helpAndSupportUrl,
    label: 'Help & support',
    icon: Icons.IoHelpCircleOutline
  }
];

export default navItems;
