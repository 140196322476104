import { Category } from './types';

// Documents
const statements = {
  typeId: 'cf91ed81',
  name: 'Statements'
};

// Category
const payableAccounts: Category = {
  id: 'xpWYZmo4BTZjmv9cJ3yq',
  name: 'Payables',
  shortDescription: 'Expense accounts (Internet, Netflix, Spotify, DStv etc.)',
  description:
    "Your monthly or annually expense accounts. Make your executors' life a breeze by listing your Internet, Netflix, Spotify accounts, and other payable accounts so they can go ahead and close or transfer those as soon as possible in the event of your passing.",
  entity: {
    type: 'payable_account',
    displayName: 'Account',
    pluralDisplayName: 'Accounts',
    documents: [statements]
  }
};

export default payableAccounts;
