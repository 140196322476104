import { Category } from './types';

// Documents
const shareholdersAgreement = {
  typeId: 'd72c060c',
  name: 'Shareholders agreement'
};

const shareCertificate = {
  typeId: '9a0348d9',
  name: 'Share certificate'
};

const registrationCertificate = {
  typeId: 'e2ca0313',
  name: 'Registration certificate'
};

const financialStatements = {
  typeId: '94c451df',
  name: 'Financial statements',
  multipleDocuments: true
};

// Category
const businesses: Category = {
  id: 'ZMiWx37QQJ2BaWfCQpG8',
  name: 'Business',
  shortDescription: 'Your business details and documents',
  description:
    'If you own a company or multiple companies, the winding up of your estate will require your executor to handle your business interests according to your wishes as stated in your will. Also, this forms part of the assets & liabilities of your estate and is used to calculate the various costs associated with winding up your estate',
  entity: {
    type: 'business',
    multipleOwners: true,
    displayName: 'Business',
    pluralDisplayName: 'Businesses',
    documents: [
      shareholdersAgreement,
      shareCertificate,
      registrationCertificate,
      financialStatements
    ]
  }
};

export default businesses;

export const businessDocuments = {
  shareholdersAgreement,
  shareCertificate,
  registrationCertificate,
  financialStatements
};
