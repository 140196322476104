import * as React from 'react';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const BusinessDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={entity.position} label='Position at company' />
      <FieldValue value={entity.companyRegNumber} label='Registration number' />
      <FieldValue value={entity.incomeTaxNumber} label='Income tax number' />
      <FieldValue value={entity.vatNumber} label='VAT number' />
    </>
  );
};

export default React.memo(BusinessDetails);
