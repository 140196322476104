import styled from '@emotion/styled';
// @ld
import { colors } from '@ld/utils';

const FieldError = styled.p`
  padding: 0;
  font-size: 12px;
  margin-top: 4px;
  line-height: 14px;
  color: ${colors.orange1};
  font-family: inherit;
`;

const toFormField = (InputComponent: any) =>
  function FormField({
    input,
    meta: { touched, error },
    hideError = false,
    ...rest
  }: any) {
    return (
      <>
        <InputComponent {...input} error={touched && error} {...rest} />
        {!hideError && touched && error && <FieldError>{error}</FieldError>}
      </>
    );
  };

export default toFormField;
