import * as React from 'react';
// @mui
import { Box, Chip } from '@mui/material';
// @ld
import { snakeToNormalCase, formatDate } from '@ld/utils';
// @custom
import FieldValue from '../../field-value';
import { EntityDetailsProps } from './types';

const MedicalInformationDetails = ({ entity }: EntityDetailsProps) => {
  const expiresAt = formatDate(entity.expiresAt);
  return (
    <>
      <FieldValue
        value={snakeToNormalCase(entity.infoType)}
        label='Medical information type'
      />
      {entity.infoType === 'medical_cover' && (
        <>
          <FieldValue value={entity.policyNumber} label='Policy number' />
          <FieldValue
            label='This policy includes'
            ValueComponent={
              <Box mt='5px' display='flex' flexWrap='wrap'>
                {entity.policyCoverage?.map((item: string) => (
                  <Box key={item} mr='4px' mb='4px'>
                    <Chip label={snakeToNormalCase(item)} />
                  </Box>
                ))}
              </Box>
            }
          />
        </>
      )}
      {entity?.infoType === 'immunisation_or_vaccination_certificates' && (
        <>
          <FieldValue
            value={entity.immunisationName}
            label='Immunisation/vaccination name'
          />
          <FieldValue value={expiresAt} label='Expiry date' />
        </>
      )}
    </>
  );
};

export default React.memo(MedicalInformationDetails);
