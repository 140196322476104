import { serverTimestamp, Timestamp } from 'firebase/firestore/lite';

import { IEventAtRead, IEventAtWrite } from '@ld/utils';

type TActor = {
  uid: string;
  name: string;
};

const getEventAt = (actor: TActor): { write: IEventAtWrite; read: IEventAtRead } => {
  const base = {
    uid: actor.uid,
    name: actor.name
  };
  return {
    write: { ...base, timestamp: serverTimestamp() },
    read: { ...base, timestamp: Timestamp.fromDate(new Date()) }
  };
};

export default getEventAt;
