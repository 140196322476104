import * as React from 'react';
// @custom
import FieldValue from '../../field-value';
import { Heading4 } from '../../typography';
import { EntityDetailsProps } from './types';

const PetDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={entity.petType} label='Pet type' />
      <Heading4>Vet Information</Heading4>
      <FieldValue value={entity.vetName} label='Vet name' />
      <FieldValue value={entity.vetContactNumber} label='Contact number' />
      <FieldValue value={entity.practiceAddress} label='Practice address' />
      <Heading4>Guardian Information</Heading4>
      <FieldValue value={entity.guardianName} label='Guardian name' />
      <FieldValue value={entity.guardianContactNumber} label='Contact number' />
      <FieldValue value={entity.guardianEmail} label='Email address' />
    </>
  );
};

export default React.memo(PetDetails);
