import * as React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as LifedocsBrandMark } from 'assets/ld-brand-mark.svg';

type BrandMarkProps = {
  size?: number;
};

const BrandMark = styled(LifedocsBrandMark)<BrandMarkProps>`
  height: ${(props) => props.height || 40}px;
  width: ${(props) => props.height || 40}px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 30px;
  }
`;

export default React.memo(BrandMark);
