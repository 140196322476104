import { Category } from './types';

const metadata = [
  {
    type: 'date',
    name: 'expiresAt',
    label: 'Expiry date',
    disablePast: true
  }
];

// Documents
const birthCertificate = {
  typeId: '1b683a5b',
  name: 'Birth certificate'
};

const idDocument = {
  typeId: '4372c8e1',
  name: 'ID document'
};

const vaccinationRecords = {
  typeId: '61b953f8',
  name: 'Vaccination records',
  multipleDocuments: true,
  metadata
};

const passport = {
  typeId: '70c287cf',
  name: 'Passports',
  multipleDocuments: true,
  metadata
};

const visa = {
  typeId: '41894ea4',
  name: 'Visas',
  multipleDocuments: true,
  metadata
};

const adoptionPapers = {
  typeId: 'c10bf1ae',
  name: 'Adoption papers',
  multipleDocuments: true
};

// Category
const dependents: Category = {
  id: 'P7dDy647s0qKGFQ8O70cwA',
  name: 'Dependents',
  shortDescription: 'Your dependents and their important documentation.',
  description:
    'Your dependents are your lifeblood.  Identity, medical, and other important information are critical for any executor and guardian when managing your estate.',
  entity: {
    type: 'dependent',
    multipleOwners: true,
    displayName: 'Dependent',
    pluralDisplayName: 'Dependents',
    documents: [
      birthCertificate,
      idDocument,
      vaccinationRecords,
      passport,
      visa,
      adoptionPapers
    ]
  }
};

export default dependents;

export const dependentsDocuments = {
  birthCertificate,
  idDocument,
  vaccinationRecords,
  passport,
  adoptionPapers,
  visa
};
