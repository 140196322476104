import * as React from 'react';
// @ld
import { snakeToNormalCase } from '@ld/utils';
// @custom
import FieldValue from '../../field-value';
import { Heading4 } from '../../typography';
import { EntityDetailsProps } from './types';

const PropertyDetails = ({ entity }: EntityDetailsProps) => {
  return (
    <>
      <FieldValue value={snakeToNormalCase(entity.propertyType)} label='Property type' />
      {entity.propertyType === 'rental_property' && (
        <>
          <FieldValue
            value={snakeToNormalCase(entity.rentalPropertyType)}
            label='Rental property type'
          />
          <Heading4>Tenant information</Heading4>
          <FieldValue value={entity.tenantContactName} label='Name' />
          <FieldValue value={entity.tenantContactNumber} label='Phone number' />
          <FieldValue value={entity.tenantContactEmail} label='Email address' />
        </>
      )}
    </>
  );
};

export default React.memo(PropertyDetails);
