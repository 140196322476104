export const isLocalEnv = process.env.REACT_APP_ENV === 'local';
export const isProdEnv = process.env.REACT_APP_ENV === 'production';
export const isStagingEnv = process.env.REACT_APP_ENV === 'staging';
export const isDebugEnv = process.env.REACT_APP_ENV === 'debug';
export const appCheckDebugToken = process.env.REACT_APP_APPCHECK_DEBUG_TOKEN;
export const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || '';
export const segmentApiKey = process.env.REACT_APP_SEGMENT_API_KEY;
export const delightedApiKey = process.env.REACT_APP_DELIGHTED_API_KEY;
export const clientPortalUrl = process.env.REACT_APP_CLIENT_PORTAL_URL;
export const advisorPortalUrl = process.env.REACT_APP_ADVISOR_PORTAL_URL;
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
export const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
export const monthlyRandPrice = 49;
export const yearlyRandPrice = 588;
