import { Category } from './types';

// Documents
const taxReturns = {
  multipleDocuments: true,
  typeId: '94dc3984',
  name: 'Tax Returns'
};

const taxCalculations = {
  multipleDocuments: true,
  typeId: '4e14537a',
  name: 'Tax Calculations'
};

const taxCertificates = {
  multipleDocuments: true,
  typeId: '43fc0cce',
  name: 'Tax Certificates'
};

// Category
const taxes: Category = {
  id: 'Fi38aFEjdEuAoST9ewsl',
  name: 'Taxes',
  shortDescription: 'Tax documents and tax returns',
  description:
    'Your important tax documents and yearly tax returns should be kept on file to ensure that any tax-related matters can easily be referenced to historical data in the event that there are disputes or queries on your tax status.',
  entity: {
    type: 'taxes',
    displayName: 'Taxes',
    pluralDisplayName: 'Taxes',
    documents: [taxReturns, taxCalculations, taxCertificates]
  }
};

export default taxes;

export const taxDocuments = {
  taxReturns,
  taxCalculations,
  taxCertificates
};
