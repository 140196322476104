import * as React from 'react';
// @mui
import { Theme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const useBreakpoints = () => {
  const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const xlDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const xsUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  return React.useMemo(
    () => ({ xlUp, lgUp, mdUp, smUp, xsUp, xlDown, lgDown, mdDown, smDown, xsDown }),
    [xlUp, lgUp, mdUp, smUp, xsUp, xlDown, lgDown, mdDown, smDown, xsDown]
  );
};

export default useBreakpoints;
