import { CircularProgressProps } from '@mui/material';
// @ld
import { colors } from '@ld/utils';

export const ringProps: CircularProgressProps = {
  variant: 'determinate',
  thickness: 5,
  size: 170
};

export type TErsConfig = {
  color: string;
  category: string;
  backgroundColor: string;
};

export const getErsConfig = (value = 0): TErsConfig => {
  if (value < 50) {
    return {
      category: 'Not good',
      color: colors.red1,
      backgroundColor: colors.red2
    };
  }
  if (value < 71) {
    return {
      category: 'Okay',
      color: colors.yellow1,
      backgroundColor: colors.yellow2
    };
  }

  if (value < 91) {
    return {
      category: 'Good',
      color: colors.brightGreen1,
      backgroundColor: colors.brightGreen2
    };
  }

  return {
    category: 'Excellent',
    color: colors.green1,
    backgroundColor: colors.green2
  };
};
