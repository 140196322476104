import { EContactRelationships } from '../types/contacts';
import { EMaritalStatus, ETypesOfMarriages } from '../types/profile-details';

const CONTACT_TYPES = [
  { value: 'person', label: 'Person' },
  { value: 'organisation', label: 'Organisation' }
];

const PERSON_CONTACT_RELATIONSHIPS = [
  { value: EContactRelationships.EXECUTOR, label: 'Executor' },
  { value: EContactRelationships.GUARDIAN_OF_DEPENDENT, label: 'Guardian of dependent' },
  { value: EContactRelationships.SPOUSE, label: 'Spouse' },
  { value: EContactRelationships.EX_SPOUSE, label: 'Ex-spouse' },
  { value: EContactRelationships.PARENT, label: 'Parent' },
  { value: EContactRelationships.SIBLING, label: 'Sibling' },
  { value: EContactRelationships.CHILD, label: 'Child' },
  { value: EContactRelationships.OTHER_FAMILY, label: 'Other Family' },
  { value: EContactRelationships.FRIEND, label: 'Friend' },
  { value: EContactRelationships.ADVISOR, label: 'Advisor' },
  { value: EContactRelationships.PET_GUARDIAN, label: 'Pet Guardian' }
];

const YES_OR_NO = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' }
];

const TYPES_OF_MARRIAGES = [
  { value: ETypesOfMarriages.IN_COMMUNITY, label: 'In community' },
  { value: ETypesOfMarriages.OUT_OF_COMMUNITY, label: 'Out of community' },
  { value: ETypesOfMarriages.CUSTOMARY_MARRIAGE, label: 'Customary marriage' },
  {
    value: ETypesOfMarriages.HINDU_OR_MUSLIM_RELIGIOUS_MARRIAGE,
    label: 'Hindu/Muslim religious marriage'
  }
];

const MARITAL_STATUS = [
  { value: EMaritalStatus.SINGLE, label: 'Single' },
  { value: EMaritalStatus.MARRIED, label: 'Married' },
  { value: EMaritalStatus.WIDOWED, label: 'Widowed' },
  { value: EMaritalStatus.DIVORCED, label: 'Divorced' },
  { value: EMaritalStatus.PARTNERSHIP, label: 'Registered partnership' }
];

const ASSET_TYPES = [
  { value: 'bonds', label: 'Bonds' },
  { value: 'retirement_annuity', label: 'Retirement annuity' },
  { value: 'provident_fund', label: 'Provident fund' },
  { value: 'money_market', label: 'Money market' },
  { value: 'commodities', label: 'Commodities (Gold, Silver etc)' },
  { value: 'equities_and_etfs', label: 'Equities and ETFs' },
  { value: 'unit_trusts', label: 'Unit Trusts' },
  { value: 'cryptocurrencies', label: 'Cryptocurrencies' },
  { value: 'forex', label: 'Forex' },
  { value: 'Stokvel', label: 'Stokvel' },
  { value: 'other', label: 'Other' }
];

const MEDICAL_INFO_TYPES = [
  { value: 'medical_cover', label: 'Medical cover' },
  {
    value: 'immunisation_or_vaccination_certificates',
    label: 'Immunisation/vaccination certificates'
  }
];

const MEDICAL_COVER_TYPES = [
  { value: 'medical_aid', label: 'Medical aid' },
  { value: 'gap_cover', label: 'Gap cover' },
  { value: 'hospital_cover', label: 'Hospital cover' },
  { value: 'health_insurance', label: 'Health insurance' }
];

const INSURANCE_TYPES = [
  { value: 'life_cover', label: 'Life cover' },
  { value: 'funeral_cover', label: 'Funeral cover' },
  { value: 'disability_cover', label: 'Disability cover' },
  { value: 'income_protection', label: 'Income protection' },
  {
    value: 'severe_illness_or_dread_disease',
    label: 'Severe illness / dread disease'
  },
  { value: 'estate_expense_cover', label: 'Estate expense cover' }
];

const ACCOUNT_TYPES = [
  { value: 'cheque', label: 'Cheque' },
  { value: 'savings', label: 'Savings' },
  { value: 'credit', label: 'Credit' }
];

const PROPERTY_TYPES = [
  { value: 'primary_residence', label: 'Primary residence' },
  { value: 'rental_property', label: 'Rental property' },
  { value: 'vacation_home', label: 'Vacation home' },
  { value: 'vacant_land_or_plot', label: 'Vacant land / Plot' }
];

const RENTAL_PROPERTY_TYPES = [
  { value: 'commercial', label: 'Commercial' },
  { value: 'residential', label: 'Residential' }
];

const ACCESS_TYPES = [
  { value: 'cellphone', label: 'Cellphone or tablet' },
  { value: 'social_media_platform', label: 'Social media platform' },
  { value: 'email', label: 'Email' },
  { value: 'computer', label: 'Computer or laptop' },
  { value: 'physical_storage', label: 'Physical storage' },
  { value: 'safe', label: 'Safe' },
  { value: 'other', label: 'Other' }
];

const ORGANISATION_CONTACT_RELATIONSHIPS = [
  { value: EContactRelationships.EXECUTOR, label: 'Executor' },
  { value: EContactRelationships.ADVISOR, label: 'Advisor' }
];

const ESSENTIAL_CONTACTS = [{ value: EContactRelationships.EXECUTOR, label: 'Executor' }];

const GUARDIAN_OF_DEPENDENT = {
  value: EContactRelationships.GUARDIAN_OF_DEPENDENT,
  label: 'Guardian of dependent'
};

const SPOUSE = { value: EContactRelationships.SPOUSE, label: 'Spouse' };

const options = {
  CONTACT_TYPES,
  PERSON_CONTACT_RELATIONSHIPS,
  YES_OR_NO,
  TYPES_OF_MARRIAGES,
  MARITAL_STATUS,
  ASSET_TYPES,
  MEDICAL_INFO_TYPES,
  MEDICAL_COVER_TYPES,
  INSURANCE_TYPES,
  ACCOUNT_TYPES,
  PROPERTY_TYPES,
  RENTAL_PROPERTY_TYPES,
  ACCESS_TYPES,
  ORGANISATION_CONTACT_RELATIONSHIPS,
  ESSENTIAL_CONTACTS,
  GUARDIAN_OF_DEPENDENT,
  SPOUSE
};

export default options;
