import styled from '@emotion/styled';

export const Main = styled.main`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
  align-items: center;
`;

export const ErrorText = styled.pre`
  width: 90%;
  color: white;
  padding: 20px;
  background: #000;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Consolas, Monaco, Courier, monospace;

  code {
    padding: 8px 12px;
    border-radius: 4px;
    word-wrap: break-word;
    box-decoration-break: clone;
  }
`;
