import * as React from 'react';
import Slide from '@mui/material/Slide';
import { IoClose } from 'react-icons/io5';
import MuiSnackbar, { SnackbarProps } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

// @custom
import { Container, Text } from './styles';

export interface TSnackbarOptions extends SnackbarProps {
  variant?: 'info' | 'success' | 'warning';
}

const Snackbar = ({ message, onClose, variant, ...rest }: TSnackbarOptions) => (
  <MuiSnackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    autoHideDuration={6000}
    TransitionComponent={Slide}
    onClose={onClose}
    {...rest}
  >
    <Container $variant={variant}>
      <Text>{message}</Text>
      {onClose && (
        <IconButton
          aria-label='close'
          size='small'
          onClick={(e) => onClose?.(e, 'clickaway')}
        >
          <IoClose color='#ffffff' />
        </IconButton>
      )}
    </Container>
  </MuiSnackbar>
);

export default React.memo(Snackbar);
