export const estateReadinessGapCount = (gap?: Record<string, unknown>): number => {
  if (gap) {
    return Object.keys(gap).reduce((prev, curr) => {
      const value = gap[curr];
      if (
        typeof value === 'object' &&
        Object.keys(value as Record<string, unknown>).length > 0
      ) {
        return prev + estateReadinessGapCount(value as Record<string, unknown>);
      }
      return prev + 1;
    }, 0);
  }
  return 0;
};
