import { Category } from './types';

// Category
const accessAndDigitalPlatforms: Category = {
  id: 'ktBUcDK5OTMTWR8dEHMl',
  name: 'Access',
  shortDescription: 'Social media accounts, physical storage locations, safes etc',
  description:
    'Got gold stashed in a secret safe? Have a large social media presence? Here you can tell your family how to access and how to deal with your social media accounts and any other physical storage locations that need some form of access requirement (Digital or physical). Lifedocs uses bank-level encryption to store all your information and will only be accessible via multifactor authentication methods',
  entity: {
    type: 'access',
    multipleOwners: true,
    displayName: 'Access',
    pluralDisplayName: 'Access'
  }
};

export default accessAndDigitalPlatforms;
