import {
  addDoc,
  Timestamp,
  FirestoreError,
  serverTimestamp
} from 'firebase/firestore/lite';
import { getAuth } from 'firebase/auth';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
// @local
import { getCollectionRef } from './refs';

const useCreateItem = <TDataWrite, TDataRead>(
  collectionName: string,
  options?: UseMutationOptions<TDataRead, FirestoreError, TDataWrite>
) => {
  const { mutate: handleCreate, ...rest } = useMutation((data) => {
    const user = getAuth().currentUser;
    if (!user) {
      return Promise.reject('Only authenticated users can perform this action');
    }

    const collectionRef = getCollectionRef(collectionName);
    return addDoc(collectionRef, {
      ...data,
      createdBy: user.uid,
      createdAt: serverTimestamp(),
      updatedBy: user.uid,
      updatedAt: serverTimestamp()
    }).then(
      (docRef) =>
        ({
          ...data,
          id: docRef.id,
          createdBy: user.uid,
          createdAt: Timestamp.fromDate(new Date()),
          updatedBy: user.uid,
          updatedAt: Timestamp.fromDate(new Date())
        } as TDataRead)
    );
  }, options);
  return { handleCreate, ...rest };
};

export default useCreateItem;
