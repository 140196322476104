import * as io5 from 'react-icons/io5';
import { getContactsUrl, getDashboardUrl, getVaultUrl } from 'utils/urls';

const profileNavItems = (profileId: string) => [
  {
    label: 'Dashboard',
    activeIcon: io5.IoSpeedometer,
    to: getDashboardUrl(profileId),
    inactiveIcon: io5.IoSpeedometerOutline
  },
  {
    label: 'Vault',
    activeIcon: io5.IoFolderOpen,
    to: getVaultUrl(profileId),
    inactiveIcon: io5.IoFolderOpenOutline
  },
  {
    label: 'Contacts',
    activeIcon: io5.IoPeople,
    to: getContactsUrl(profileId),
    inactiveIcon: io5.IoPeopleOutline
  }
];

export default profileNavItems;
