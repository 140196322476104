import styled from '@emotion/styled';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';

export const Dialog = styled(MuiDialog)`
  .MuiDialog-container {
    backdrop-filter: blur(10px);
  }

  .MuiDialog-paper {
    background: #ffffff;
    box-shadow: 0px 15px 30px rgba(38, 50, 56, 0.1);
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down('xs')} {
      border-radius: 0px;
    }
  }
`;

export const DialogHeader = styled.div`
  display: flex;
  padding: 30px 20px 0px;
  align-items: flex-start;
  justify-content: space-between;
`;

export const DialogContent = styled(MuiDialogContent)`
  && {
    padding: 0px 20px 20px;
  }
`;
