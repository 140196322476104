import { Category } from './types';

// Documents
const titleDeed = {
  typeId: '18b00229',
  name: 'Title deed'
};

const bondAgreement = {
  typeId: '43eb0dd1',
  name: 'Bond agreement'
};

const levyInvoice = {
  typeId: 'bbf71429',
  name: 'Latest levy invoice'
};

const ratesInvoice = {
  typeId: '649c99db',
  name: 'Latest rates invoice'
};

const gasComplianceCertificate = {
  typeId: '07f9e333',
  name: 'Gas compliance certificate'
};

const electricityComplianceCertificate = {
  typeId: 'b28c6742',
  name: 'Electricity compliance certificate'
};

const buildingPlans = {
  typeId: 'c25327c2',
  name: 'Building plans'
};

const bodyCorporateRules = {
  typeId: 'ed1118dc',
  name: 'Body corporate rules'
};

const householdInsurance = {
  typeId: '187edf1e',
  name: 'Household insurance'
};

const leaseAgreement = {
  typeId: '2c1b5c27',
  name: 'Lease agreement'
};

// Category
const properties: Category = {
  id: 'O7mSxb7EfvaHbqBPPzno',
  name: 'Properties',
  shortDescription: 'Details and documents relating to property you own',
  description:
    'Your house, holiday home(s) and investment properties go here. Property information is needed when transferring your property to an heir or when processed as a sale within your estate. Also, this forms part of the assets & liabilities of your estate and is used to calculate the various costs associated with winding up your estate.',
  entity: {
    type: 'property',
    multipleOwners: true,
    displayName: 'Property',
    pluralDisplayName: 'Properties',
    documents: [
      titleDeed,
      bondAgreement,
      levyInvoice,
      ratesInvoice,
      gasComplianceCertificate,
      electricityComplianceCertificate,
      buildingPlans,
      bodyCorporateRules,
      householdInsurance,
      leaseAgreement
    ]
  }
};

export default properties;

export const propertiesDocuments = {
  titleDeed,
  bondAgreement,
  levyInvoice,
  ratesInvoice,
  gasComplianceCertificate,
  electricityComplianceCertificate,
  buildingPlans,
  bodyCorporateRules,
  householdInsurance,
  leaseAgreement
};
