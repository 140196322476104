import { Category } from './types';
// categories
import insurancePolicies, { insurancePoliciesDocuments } from './insurance-policies';
import trusts from './trusts';
import medical from './medical';
import bankAccounts from './bank-accounts';
import pets, { petsDocuments } from './pets';
import qualifications from './qualifications';
import payableAccounts from './payable-accounts';
import financialAssets from './financial-assets';
import debtAndLiabilities from './debt-and-liabilities';
import personal, { personalDocuments } from './personal';
import vehicles, { vehiclesDocuments } from './vehicles';
import firearms, { firearmsDocuments } from './firearms';
import businesses, { businessDocuments } from './businesses';
import properties, { propertiesDocuments } from './properties';
import employment, { employmentDocuments } from './employment';
import accessAndDigitalPlatforms from './access-and-digital-platforms';
import deathAndEstate, { deathAndEstateDocuments } from './death-and-estate';
import dependents, { dependentsDocuments } from './dependents';
import collectibles from './collectibles';
import taxes, { taxDocuments } from './taxes';

// category ids generated using https://www.guidgenerator.com/online-guid-generator.aspx
// categories
const orderedCategories: Category[] = [
  personal,
  deathAndEstate,
  medical,
  employment,
  qualifications,
  insurancePolicies,
  accessAndDigitalPlatforms,
  dependents,
  pets,
  properties,
  vehicles,
  firearms,
  bankAccounts,
  financialAssets,
  collectibles,
  payableAccounts,
  debtAndLiabilities,
  taxes,
  businesses,
  trusts
];

const categoriesMap = {
  [personal.id]: personal,
  [insurancePolicies.id]: insurancePolicies,
  [deathAndEstate.id]: deathAndEstate,
  [medical.id]: medical,
  [bankAccounts.id]: bankAccounts,
  [accessAndDigitalPlatforms.id]: accessAndDigitalPlatforms,
  [financialAssets.id]: financialAssets,
  [employment.id]: employment,
  [properties.id]: properties,
  [vehicles.id]: vehicles,
  [pets.id]: pets,
  [payableAccounts.id]: payableAccounts,
  [debtAndLiabilities.id]: debtAndLiabilities,
  [businesses.id]: businesses,
  [trusts.id]: trusts,
  [qualifications.id]: qualifications,
  [firearms.id]: firearms,
  [dependents.id]: dependents,
  [collectibles.id]: collectibles,
  [taxes.id]: taxes
};

export {
  personal,
  personalDocuments,
  insurancePolicies,
  insurancePoliciesDocuments,
  deathAndEstate,
  deathAndEstateDocuments,
  medical,
  bankAccounts,
  accessAndDigitalPlatforms,
  financialAssets,
  collectibles,
  employment,
  employmentDocuments,
  properties,
  propertiesDocuments,
  vehicles,
  vehiclesDocuments,
  pets,
  petsDocuments,
  payableAccounts,
  debtAndLiabilities,
  taxes,
  taxDocuments,
  businesses,
  businessDocuments,
  trusts,
  qualifications,
  firearms,
  firearmsDocuments,
  dependents,
  dependentsDocuments,
  categoriesMap,
  orderedCategories
};
