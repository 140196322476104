import { TVaultConfig } from '../types/base';
import * as categories from '../constants/categories';
import { EMaritalStatus, ETypesOfMarriages } from '../types/profile-details';

export type TVaultSetupData = {
  hasPets?: string;
  hasWill?: string;
  hasTrusts?: string;
  hasProperty?: string;
  hasVehicles?: string;
  hasFirearms?: string;
  hasBusinesses?: string;
  hasDependents?: string;
  maritalStatus?: string;
  typeOfMarriage?: string;
};

const {
  pets,
  properties,
  vehicles,
  businesses,
  firearms,
  trusts,
  dependents,
  personalDocuments,
  deathAndEstateDocuments
} = categories;

const {
  decreeOfDivorce,
  antenuptialContract,
  marriageCertificate,
  cohabitationAgreement,
  divorceSettlementAgreement
} = personalDocuments;

const { spousalDeathCertificate, spousalLetterOfExecutorship } = deathAndEstateDocuments;

const getVaultConfig = ({
  hasBusinesses,
  hasDependents,
  hasFirearms,
  hasPets,
  hasProperty,
  hasTrusts,
  hasVehicles,
  maritalStatus,
  typeOfMarriage
}: TVaultSetupData) => {
  const config: TVaultConfig = {
    [antenuptialContract.typeId]: typeOfMarriage === ETypesOfMarriages.OUT_OF_COMMUNITY,
    [decreeOfDivorce.typeId]: maritalStatus === EMaritalStatus.DIVORCED,
    [marriageCertificate.typeId]: maritalStatus === EMaritalStatus.MARRIED,
    [cohabitationAgreement.typeId]: maritalStatus === EMaritalStatus.PARTNERSHIP,
    [divorceSettlementAgreement.typeId]: maritalStatus === EMaritalStatus.DIVORCED,
    [spousalDeathCertificate.typeId]: maritalStatus === EMaritalStatus.WIDOWED,
    [spousalLetterOfExecutorship.typeId]: maritalStatus === EMaritalStatus.WIDOWED
  };

  if (hasProperty) {
    config[properties.id] = hasProperty === 'true';
  }
  if (hasVehicles) {
    config[vehicles.id] = hasVehicles === 'true';
  }
  if (hasFirearms) {
    config[firearms.id] = hasFirearms === 'true';
    config[personalDocuments.firearmCompetency.typeId] = hasFirearms === 'true';
  }
  if (hasBusinesses) {
    config[businesses.id] = hasBusinesses === 'true';
  }
  if (hasTrusts) {
    config[trusts.id] = hasTrusts === 'true';
  }
  if (hasDependents) {
    config[dependents.id] = hasDependents === 'true';
  }
  if (hasPets) {
    config[pets.id] = hasPets === 'true';
  }
  return config;
};

export default getVaultConfig;
