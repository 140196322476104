import * as React from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';
// @mui
import Box from '@mui/material/Box';
// @ld
import { colors, docPluralize, getEntityDocumentVaultConfigKey } from '@ld/utils';
// @custom
import { UnstyledLink } from '../../links';
import { Card, CardContent } from '../../card';
import { TCategory, TVaultConfig } from '@ld/utils';
import { Heading4, BodySmallText } from '../../typography';
// @local
import EntityCardSkeleton from './skeleton';
import getEntityData from './get-entity-data';
import { IconWrapper, SubContent, StyledAvatar } from './styles';

type EntityCardProps = {
  to: string;
  profileId: string;
  entity: any;
  shared?: boolean;
  category?: TCategory;
  config?: TVaultConfig;
};

const EntityCard = ({
  profileId,
  to,
  shared,
  category,
  entity,
  config
}: EntityCardProps) => {
  const documentsGoalCount =
    category?.entity?.documents?.filter((doc) => {
      const configKey = getEntityDocumentVaultConfigKey(entity.id, doc.typeId);
      return config?.[configKey] !== false;
    }).length || 0;
  const documentsSharedCount = entity?.sharedWithCount?.[profileId]?.documentsCount || 0;

  const { name, copy, Icon } = getEntityData({
    entity,
    documentsGoalCount
  });

  return (
    <UnstyledLink to={to} id={`entity-${profileId}-${category?.id}`}>
      <Card>
        <CardContent>
          <Box display='flex' alignItems='center'>
            {entity?.type === 'dependent' ? (
              <StyledAvatar src={entity?.photoURL || ''} alt={name || ''} />
            ) : (
              <IconWrapper>
                <Icon />
              </IconWrapper>
            )}
            <Box ml={1}>
              <Heading4>{name}</Heading4>
              {shared ? (
                <SubContent>
                  <IoDocumentTextOutline size={15} color={colors.grey1} />
                  <BodySmallText color={colors.grey1}>
                    <b>{documentsSharedCount}</b> {docPluralize(documentsSharedCount)}{' '}
                    shared
                  </BodySmallText>
                </SubContent>
              ) : (
                <BodySmallText>{copy}</BodySmallText>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </UnstyledLink>
  );
};

export default React.memo(EntityCard);

export { EntityCardSkeleton };
