import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function HeartBeatOutlinedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M20.679 4.69531C16.8751 4.69531 15.0001 8.4453 15.0001 8.4453C15.0001 8.4453 13.1251 4.69531 9.32119 4.69531C6.22979 4.69531 3.78175 7.28163 3.75011 10.3678C3.68566 16.7738 8.83193 21.3294 14.4727 25.1579C14.6282 25.2637 14.812 25.3203 15.0001 25.3203C15.1882 25.3203 15.3719 25.2637 15.5274 25.1579C21.1676 21.3294 26.3139 16.7738 26.25 10.3678C26.2184 7.28163 23.7704 4.69531 20.679 4.69531Z' />
      <path d='M2.8125 15H9.37498L12.1875 9.375L15 18.75L17.8125 13.125L19.6874 16.875H27.1874' />
    </SvgIcon>
  );
}

HeartBeatOutlinedIcon.defaultProps = {
  viewBox: '0 0 30 30'
};

export default React.memo(HeartBeatOutlinedIcon);
